import React from 'react';
import { Eye } from 'lucide-react';
// Custom components
import { Button } from "../components/ui/button";
import {
    Dialog, DialogFooter, DialogTrigger,
    DialogContent,
    DialogClose
} from "../components/ui/dialog";
import {
    TooltipProvider,
    Tooltip,
    TooltipTrigger,
    TooltipContent,
} from "../components/ui/tooltip";
import { Separator } from '../components/ui/separator';

export const DetailsDialog = (props) => {
    const { heading, title, description } = props;

    return (
        <Dialog >
            <TooltipProvider>
                <Tooltip>
                    <TooltipTrigger asChild>
                        <DialogTrigger asChild>
                            <Button
                                variant="icon"
                                size="sm"
                                className="flex items-center justify-center text-lg font-bold"
                            >
                                <Eye className="h-4 w-4" />
                            </Button>
                        </DialogTrigger>
                    </TooltipTrigger>
                    <TooltipContent side="right">View Details</TooltipContent>
                </Tooltip>
            </TooltipProvider>
            <DialogContent className="sm:max-w-[425px]">
                <h1 className='text-2xl font-bold text-primary'>{heading}</h1>
                <Separator />
                <div className='flex flex-col'>
                    <p className='text-sm font-bold text-primary'>Title</p>
                    <h1 className='text-xl'>{title}</h1>
                </div>
                <div className='flex flex-col'>
                    <p className='text-sm font-bold text-primary'>Description</p>
                    <p className='text-md'>{description}</p>
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default DetailsDialog

