import { useState } from 'react';
import Lottie from 'react-lottie';
// Custom components
import {
    Card,
    CardContent,
} from "../components/ui/card";
import LoginForm from "./LoginForm";
import loginLottie from '../lotties/login_lottie.json';
import BrandLogo from '../components/BrandLogo';
// Services
import { deleteProduct } from '../services/api';

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loginLottie,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
    },
};


export const GuestUserLoginDialog = () => {

    return (
        <Card className='flex justify-center items-center h-full'>

            <CardContent className='h-full  flex flex-col py-8'>
                <div className='flex flex-col  items-center justify-around  h-full '>
                    <div className=''><BrandLogo /></div>
                    <div>
                        <div className='my-8 flex flex-col'>
                            <Lottie
                                options={defaultOptions}
                                height={320}
                                width={520}
                            />
                        </div>
                        <LoginForm />
                    </div>
                </div>
            </CardContent>
        </Card>
    )
}
