import lodash from 'lodash';
import { generateReport, generateProductReport, getReport } from '../../services/api';
import { logError, logDebug } from '../../services/logging_utils';

/**
 * 
 * @param {*} param0 
 * Starts the generation of report with the API, alternative of 
 * websocket based reports generation flow
 */
export const startReportGeneration = async ({ setters, data }) => {
    try {
        const response = await generateReport(data);

        const report = lodash.get(response, ['data', 'report']);
        const reports = lodash.get(response, ['data', 'reports']);

        if (setters.setProductReports) setters.setProductReports(reports);
        if (report && setters.setSelectedReport) setters.setSelectedReport(report);
        return response;
    } catch (error) {
        logError(error);
    } finally {
        setters.setStartResearching(null);
    }
}

/**
 * Generates the reports for the product based on the answered product questions from the user
 * @param {*} answeredQuestions 
 * @param {*} product 
 */
const handleGenerateReportsFromQuestions = async ({ setters, answeredQuestions, product }) => {
    try {
        setters.setCreatingProductReports(true);
        const payload = { ...product, questions: answeredQuestions };
        const response = await generateProductReport({ payload, productId: product.id });

        const updatedProduct = lodash.get(response, ['data']);
        setters.setActiveProduct(updatedProduct);
        setters.setSelectedReport(lodash.get(updatedProduct, ['reports', [0]]));
        setters.setProductsView('REPORT_VIEW');
    } catch (error) {
        logError(error);
    } finally {
        setters.setCreatingProductReports(false);
    }
};

/**
 * 
 * @param {*} param0 
 */
const handleGenerateReport = async ({ setters, report, activeProduct }) => {
    setters.setStartResearching('AGENTS_INPROGRESS');

    const requestData = {
        // This query construction will change over time
        task: `For a product, ${activeProduct.name}, with a description of ${activeProduct.description}, generate a report with ${report.title} and description of ${report.description}`,
        report_type: 'detailed_report',
        report_source: 'web',
        agent: 'Auto Agent',
        product_id: activeProduct.id,
        report_id: report.id,
    };

    try {
        // sendMessage(`start ${JSON.stringify(requestData)}`);
        await startReportGeneration({ setters, data: requestData });
    } catch (error) {
        logError('Error generating report:', error);
    }
};

/**
 * 
 * @param {*} param0 
 */
const handleSelectReport = async ({ setters, payload }) => {
    try {
        // Fetching the latest report for the selected report
        setters.setLoadingReport(payload);
        const response = await getReport(payload.id);
        const report = lodash.get(response, ['data', 'data']);
        setters.setSelectedReport(report);
    } catch (error) {
        logError(error);
    } finally {
        setters.setStartResearching('');
        setters.setLoadingReport(null);
    }
};


/**
 * Used for product, checks if there are no reports generated for the product.
 * Used in case when there are no reports generate for a product and the user is redirected to the product questions preview.
 * @param {*} activeProduct 
 * @returns 
 */
export const checkIfNoReportsAreGeneratedForProduct = (activeProduct) => {
    return lodash.get(activeProduct, ['status'], []) !== 'reports_created';
};

/**
 * Used for a single report, checks if the content of the report is not generated.
 * @param {*} selectedReport 
 * @param {*} logs 
 * @returns 
 */
export const checkIfReportIsNotGenerated = (selectedReport, version) => {
    if (selectedReport) {
        return ['pending', 'in_progress', 'queued'].includes(lodash.get(selectedReport, ['content', version, 'status'])) || lodash.get(selectedReport, ['content'], []).length === 0;
    }
    return false;
};