import { useEffect, useState } from 'react';
import lodash from 'lodash';
import { Edit } from 'lucide-react';
import { Button } from "../../../components/ui/button";
import { Input } from "../../../components/ui/input";
import {
    TooltipProvider,
    Tooltip,
    TooltipTrigger,
    TooltipContent,
} from "../../../components/ui/tooltip";
import { Dialog, DialogHeader, DialogTitle, DialogContent, DialogFooter } from "../../../components/ui/dialog";
import { updateKnowledgeBase } from '../../../services/api'; // Import the update API

export const EditKnowledgeBaseDialog = ({ knowledgeBase, isOpen, setDialogStatus, onUpdateSuccess }) => {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');

    useEffect(() => {
        setTitle(lodash.get(knowledgeBase, ['title'], ''));
        setDescription(lodash.get(knowledgeBase, ['description'], ''));
    }, [knowledgeBase]);

    if (!isOpen) {
        return (
            <TooltipProvider>
                <Tooltip>
                    <TooltipTrigger asChild>
                        <Button
                            size="icon" variant="outline"
                            className="flex items-center justify-center text-4xl font-bold m-1"
                            onClick={(e) => { e.stopPropagation(); setDialogStatus(true); }}
                        >
                            <Edit className="h-4 w-4" />
                        </Button>
                    </TooltipTrigger>
                    <TooltipContent side="right">Edit</TooltipContent>
                </Tooltip>
            </TooltipProvider>
        )
    }

    const handleUpdate = async () => {
        const updatedData = { title, description };
        await updateKnowledgeBase(knowledgeBase.id, updatedData); // Call the update API
        onUpdateSuccess(); // Callback to refresh the list or update state
        setDialogStatus(false);
    };

    return (
        <Dialog open={isOpen} onOpenChange={() => setDialogStatus(false)}>
            <DialogHeader>
                <DialogTitle>
                    <Button
                        size="icon" variant="outline"
                        className="flex items-center justify-center text-4xl font-bold m-1"
                        onClick={(e) => { e.stopPropagation(); setDialogStatus(true); }}
                    >
                        <Edit className="h-4 w-4" />
                    </Button>
                </DialogTitle>
            </DialogHeader>
            <DialogContent>
                <Input value={title} onChange={(e) => setTitle(e.target.value)} placeholder="Title" />
                <Input value={description} onChange={(e) => setDescription(e.target.value)} placeholder="Description" />
            </DialogContent>
            <DialogFooter>
                <Button variant="secondary" onClick={() => setDialogStatus(false)}>Cancel</Button>
                <Button onClick={handleUpdate}>Save</Button>
            </DialogFooter>
        </Dialog>
    );
};