import React, { useEffect, useState } from 'react';
import lodash from 'lodash';
import Lottie from 'react-lottie'; // Import Lottie
import { useParams, useHistory } from 'react-router-dom';
import { getUserReports, getUserProducts, getUserDetails } from '../../services/api'; // Ensure these API functions are implemented
import { Card, CardContent, CardHeader, CardTitle } from "../../components/ui/card";
import { Separator } from "../../components/ui/separator";
import { Accordion, AccordionItem, AccordionTrigger, AccordionContent } from '../../components/ui/accordion'; // Import only the necessary exports from accordion.js
import { Avatar, AvatarImage, AvatarFallback } from '../../components/ui/avatar'; // Import Avatar components
import { Button } from '../../components/ui/button'; // Import Button component
import { ArrowLeft } from 'lucide-react'; // Import the left arrow icon
import EmptyAnimation from '../../lotties/empty_analytics.json'; // Path to your Lottie animation
import ReportChatView from '../../views/dashboard/reports/components/ReportChatView';
import ProductView from '../../views/dashboard/products/components/ProductView';

function UserReportsView({ reports, products, handleViewReport, handleViewProduct }) {

    // Separate reports into those with and without product_id
    const reportsWithProduct = reports.filter(report => report.product_id);

    const reportsWithoutProduct = reports.filter(report => !report.product_id);

    // Group reports by product_id
    const groupedReports = lodash.groupBy(reportsWithProduct, 'product_id');


    return (
        <>
            {reports.length === 0 && products.length === 0 ? (
                <Card className="p-4 mt-2 text-center">
                    <CardContent>
                        <Lottie options={{ loop: true, autoplay: true, animationData: EmptyAnimation }} height={200} width={200} />
                        <p className="text-lg font-semibold">No Products or Reports Available</p>
                        <p className="text-sm text-muted-foreground">Please add some products or reports to get started.</p>
                    </CardContent>
                </Card>
            ) : (
                <>
                    {/* Reports with Products Card */}
                    <Card className="mt-2 text-left">
                        <CardHeader>
                            <CardTitle className="font-bold">Products</CardTitle>
                        </CardHeader>
                        <CardContent className='px-4'>
                            <Accordion type="single" collapsible>
                                {Object.entries(groupedReports).map(([productId, productReports]) => {
                                    const product = products.find(p => p.id === productId); // Find the product by ID
                                    return (
                                        <AccordionItem key={productId} value={productId} className='text-left bg-gray-100 rounded-md mb-2 p-2 px-4'>
                                            <div className='flex justify-between w-full items-center py-2'>
                                                <div className='flex flex-col py-2'>
                                                    <p className='text-left font-bold text-lg'> {product ? product.name : 'Unknown Product'} {/* Display product name */}</p>
                                                    <p className='text-left text-sm'> {product ? product.description : 'Unknown Product'} {/* Display product description */}</p>
                                                </div>
                                                <Button variant='outline' onClick={() => handleViewProduct(product)} className="ml-2">View</Button>
                                            </div>
                                            <Separator />
                                            <AccordionTrigger className='text-left text-sm py-2'>
                                                Reports
                                            </AccordionTrigger>
                                            <AccordionContent>
                                                <ul>
                                                    {productReports.map(report => (
                                                        <li className='text-left font-sm p-2 bg-gray-200 rounded-md mt-2 flex justify-between items-center' key={report.id}>
                                                            <span>{report.title}</span>
                                                            {/* <Button variant='link' onClick={() => handleViewReport(report)} className="ml-2">View</Button> View button */}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </AccordionContent>
                                        </AccordionItem>
                                    );
                                })}
                            </Accordion>
                        </CardContent>
                    </Card>

                    {/* Reports without Products Card */}
                    <Card className="mt-2 text-left">
                        <CardHeader>
                            <CardTitle className="font-bold ">Reports</CardTitle>
                        </CardHeader>
                        <CardContent className='px-4'>
                            {reportsWithoutProduct.map(report => (
                                <Card key={report.id} className="p-2 mt-2 flex justify-between items-center">
                                    <CardContent>
                                        <p className="text-left">{report.title}</p> {/* Display report title */}
                                    </CardContent>
                                    <Button variant='link' onClick={() => handleViewReport(report)} className="ml-2">View</Button> {/* View button */}
                                </Card>
                            ))}
                        </CardContent>
                    </Card>
                </>
            )}
        </>
    )
}

const UserDetailsView = () => {
    const { userId } = useParams(); // Get user ID from URL parameters
    const history = useHistory(); // Initialize history for navigation
    const [user, setUser] = useState(null); // State to hold user details
    const [reports, setReports] = useState([]); // State to hold user reports
    const [products, setProducts] = useState([]); // State to hold user products
    const [currentView, setCurrentView] = useState('REPORTS_LIST'); // REPORTS_LIST or REPORT_VIEW or PRODUCT_VIEW

    const [activeProduct, setActiveProduct] = useState(null); // State to hold user products
    const [activeReport, setActiveReport] = useState(null); // State to hold user products

    const [loading, setLoading] = useState(true); // State to manage loading status

    useEffect(() => {
        const fetchUserDetails = async () => {
            setLoading(true); // Set loading to true before fetching
            // Fetch user details, reports, and products
            const userResponse = await getUserDetails(userId); // Fetch user details
            const reportsResponse = await getUserReports(userId); // Fetch user reports
            const productsResponse = await getUserProducts(userId); // Fetch user products
            setUser(lodash.get(userResponse, ['data', 'data'], [])); // Set user details
            setReports(lodash.get(reportsResponse, ['data', 'data'], [])); // Set reports
            setProducts(lodash.get(productsResponse, ['data', 'data'], [])); // Set products
            setLoading(false); // Set loading to false after fetching
        };
        fetchUserDetails(); // Call the fetch function
    }, [userId]);

    const handleViewReport = (report) => {
        setCurrentView('REPORT_VIEW'); // Navigate to the report details page
        setActiveReport(report);
    };

    const handleViewProduct = (product) => {
        setCurrentView('PRODUCT_VIEW'); // Navigate to the report details page
        setActiveProduct(product);
    };

    const handleBack = () => {
        history.push('/admin/users'); // Navigate back to the Admin Users list
    };

    const handleBackFromReport = () => {
        setCurrentView('REPORTS_LIST');
        setActiveProduct(null);
        setActiveReport(null);
    }

    return (
        <div className="space-y-4">
            {/* Loader while fetching data */}
            {loading ? (
                <div className="flex justify-center items-center h-64">
                    <p>Loading...</p> {/* Replace with a spinner or loading animation if desired */}
                </div>
            ) : (
                <>
                    {/* Back Button with Left Arrow */}
                    <Button onClick={handleBack} variant='ghost' className="mb-4 flex items-center">
                        <ArrowLeft className="mr-2 w-4 h-4" /> {/* Left arrow icon */}
                        Back to Admin Users
                    </Button>

                    {/* User Details Card */}
                    <Card className="p-4 text-left flex items-center">
                        <Avatar className="h-12 w-12 mr-4">
                            <AvatarImage src={lodash.get(user, ['profile_image']) ? lodash.get(user, ['profile_image']) : "https://github.com/shadcn.png"} alt="@shadcn" />
                            <AvatarFallback />
                        </Avatar>
                        <CardContent>
                            <p className="text-lg font-semibold">{user?.fullname}</p>
                            <p className="text-sm text-muted-foreground">{user?.email}</p>
                        </CardContent>
                    </Card>

                    <div className="flex flex-col px-2 sm:py-2 main-area-section-h main-area-section-w">
                        {currentView === 'REPORTS_LIST' ? <UserReportsView reports={reports} products={products} handleViewReport={handleViewReport} handleViewProduct={handleViewProduct} /> : null}
                        {currentView === 'REPORT_VIEW' ? <ReportChatView activeReport={activeReport} setReportsView={() => { }} setActiveReport={setActiveReport} handleRedirectToAllReport={handleBackFromReport} /> : null}
                        {currentView === 'PRODUCT_VIEW' ? <ProductView activeProduct={activeProduct} setProductsView={() => { }} setActiveProduct={setActiveProduct} handleRedirectToAllProduct={handleBackFromReport} /> : null}
                    </div>
                </>
            )}
        </div>
    );
};

export default UserDetailsView;