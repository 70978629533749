import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import lodash from 'lodash';


// Custom components
import { ResizableHandle, ResizablePanel, ResizablePanelGroup } from '../../../../components/ui/resizable';
import { ProductReportDisplay } from './ProductReportDisplay';
import { ProductChatViewReportsList } from './ProductChatViewReportsList';
import { ReportHeader } from './ReportHeader';
import { GenerateReportView } from './GenerateReportView';

// Services
import { getReport } from '../../../../services/api';
import { logError } from '../../../../services/logging_utils';
import { startReportGeneration, checkIfReportIsNotGenerated } from '../../reports_utils';
// State
import { productReportsState, systemConstantsState } from '../../../../store/store';


export default function ProductReportsView({
    product,
    loadingProduct,
    handleUpdateProduct
}) {
    const defaultLayout = [320, 320, 655, 975];

    const [systemConstants] = useRecoilState(systemConstantsState);

    const [reports, setProductReports] = useRecoilState(productReportsState);

    const [selectedReport, setSelectedReport] = useState(null);
    const [selectedReportVersion, setSelectedReportVersion] = useState(0);
    const [inputValue, setInputValue] = useState('');
    const [startResearching, setStartResearching] = useState(null);
    const [loadingReport, setLoadingReport] = useState(null);

    const handleTextareaEnter = (e) => {
        // if (e.key === "Enter") {
        //     onStartSetupClick(inputValue);
        // }
    };

    const handleSelectReport = async (payload, version) => {
        try {
            // Fetching the latest report for the selected report
            if (lodash.get(selectedReport, ['id']) !== lodash.get(payload, ['id'])) {
                setLoadingReport(payload);
            }
            const response = await getReport(payload.id);
            const report = lodash.get(response, ['data', 'data']);
            setSelectedReport(report);


            const reportVersion = version !== null ? version : (selectedReportVersion !== 0 ? selectedReportVersion : 0);
            setSelectedReportVersion(reportVersion);
        } catch (error) {
            logError(error);
        } finally {
            setStartResearching(null);
            setLoadingReport(null);
        }
    };

    useEffect(() => {
        (async () => {
            if (product !== null) {
                // Setting the selected report to the first report when the user comes to the product reports view.
                // This will fetch more details about the first report
                const reportIdx = selectedReport === null ? 0 : lodash.get(product, ['reports'], []).findIndex((report) => report.id === selectedReport.id);
                await handleSelectReport(lodash.get(product, ['reports', [reportIdx]]), null);
            }
        })();

    }, [product]);


    const handleGenerateReport = async (report) => {
        setStartResearching(report);

        const configSettings = lodash.get(systemConstants, ['CONFIG_SETTINGS', 'value'], {});

        const requestData = {
            // This query construction will change over time
            task: `For a product, ${product.name}, with a description of ${product.description}, generate a report with ${report.title} and description of ${report.description}`,
            report_type: 'product_report',
            report_source: 'web',
            agent: 'Auto Agent',
            product_id: product.id,
            report_id: report.id,
            ...configSettings
        };

        try {
            await startReportGeneration({ setters: { setStartResearching, setProductReports, setSelectedReport }, data: requestData });
        } catch (error) {
            logError('Error generating report:', error);
        }
    };

    const handleReportDeleted = async (report, action) => {
        const firstReport = lodash.get(product, ['reports', 0], [])

        // We set the first report as the active report now because the report user was on just got deleted
        setSelectedReport(firstReport);
        setSelectedReportVersion(0);

        await handleUpdateProduct(report, action);

    }

    const handleReportAdded = async (report, action) => {
        await handleUpdateProduct(report, action);
        // we are setting this report as the current report since now this is the latest report that is created we should redirect the user to this report
        setSelectedReport(report);
        setSelectedReportVersion(0);
    }


    // Just to make sure that the selected report is not null
    const sanitizedLogs = lodash.get(selectedReport, ['content', selectedReportVersion, 'logs'], []).filter((log) => typeof log.output === 'string');

    return (
        <>
            <ResizablePanelGroup
                direction="horizontal"
                className="h-full max-h-[800px] items-stretch"
            >
                <ResizablePanel
                    defaultSize={defaultLayout[1]}
                    maxSize={25}
                    minSize={25}
                    className="overflow-auto"
                >
                    <ProductChatViewReportsList
                        product={product}
                        isCollapsed={false}
                        links={Object.values(reports).map((report) => ({ title: report.title, variant: 'default', ...report }))}
                        selectedReport={selectedReport}
                        loadingReport={loadingReport}
                        handleSelectReport={handleSelectReport}
                        loadingProduct={loadingProduct && product === null}
                        handleReportAdded={handleReportAdded}
                    />
                </ResizablePanel>

                <ResizableHandle withHandle />

                <ResizablePanel defaultSize={defaultLayout[3]}
                >
                    {/* <div class="handle" data-swapy-handle></div> */}
                    <ReportHeader loading={(loadingProduct && product === null) || loadingReport}
                        selectedReportVersion={selectedReportVersion}
                        setSelectedReportVersion={setSelectedReportVersion}
                        report={selectedReport}
                        saitizedLogs={sanitizedLogs}
                        showLogsDrawer={lodash.get(selectedReport, ['content', selectedReportVersion, 'content'], '') !== ''}
                        handleRefreshReport={() => handleGenerateReport(selectedReport)}
                        startResearching={startResearching}
                        handleReportDeleted={handleReportDeleted}
                    />
                    {checkIfReportIsNotGenerated(selectedReport, selectedReportVersion) ? (
                        <GenerateReportView
                            key={lodash.get(selectedReport, ['id'])}
                            selectedReport={selectedReport}
                            selectedReportVersion={selectedReportVersion}
                            sanitizedLogs={sanitizedLogs}
                            startResearching={startResearching}
                            handleGenerateReport={handleGenerateReport}
                        />
                    ) : <>
                        <>
                            <ProductReportDisplay
                                report={selectedReport}
                                setInputValue={setInputValue}
                                handleTextareaEnter={handleTextareaEnter}
                                startResearching={startResearching}
                                inputValue={inputValue}
                                defaultLayout={defaultLayout}
                                selectedReport={selectedReport}
                                loading={(loadingProduct && product === null) || loadingReport}
                                selectedReportVersion={selectedReportVersion}
                            />
                        </>
                    </>}
                </ResizablePanel>

            </ResizablePanelGroup>
        </>
    );
}
