import { useState, useEffect } from "react";
import { useRecoilState } from 'recoil';
import lodash from 'lodash';
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";

// Custom components
import { Button } from "../../components/ui/button";
import { Card, CardHeader, CardTitle, CardDescription, CardContent } from "../../components/ui/card";
import { Table, TableHeader, TableRow, TableHead, TableBody, TableCell } from "../../components/ui/table";
import { Badge } from "../../components/ui/badge";
import { AdminSidebar } from "./AdminSidebar";
import { AdminHeader } from "./AdminHeader";
import { TopNav } from "../../components/Topnav";
import { Sidebar } from '../../components/explore/sidebar';
import ProtectedRoute from '../../components/auth/ProtectedRoute';
import UserDetailsView from "./UserDetailsView"; // Add this import

// Services
import { logError } from '../../services/utils';
import { getReportTasks } from '../../services/api';
import { userCharacterChats, charactersState } from '../../store/store';
import { AdminDashboarSelectedCharacterView } from "./AdminDashboarSelectedCharacterView";
import AdminUsersView from "./AdminUsersView";
import AdminTasksList from "./AdminTasksList";
import AdminReportsList from "./AdminReportsList";


export const AdminDashboard = () => {

    return (
        <>
            <div className="flex min-h-screen w-full flex-col bg-muted/40">
                <div className="flex flex-col sm:gap-4  ">
                    <div className="col-span-3 lg:col-span-4 lg:border-l main-area-gradient">
                        <div className="h-full">
                            <div className="flex-col md:flex">
                                <TopNav />
                                <div className='flex'>
                                    <Sidebar className="hidden lg:block" />
                                    <div className="flex flex-col px-2 sm:py-2 main-area-section-h main-area-section-w">
                                        <Switch>
                                            <Route exact path="/admin/users">
                                                <AdminUsersView />
                                            </Route>
                                            <Route path="/admin/users/:userId"> {/* Add this route */}
                                                <UserDetailsView />
                                            </Route>
                                            <ProtectedRoute path="/admin/tasks">
                                                <AdminTasksList />
                                            </ProtectedRoute>

                                            <ProtectedRoute path="/admin/reports">
                                                <AdminReportsList />
                                            </ProtectedRoute>
                                        </Switch>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
