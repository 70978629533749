import { useEffect, useState } from 'react';
import lodash from 'lodash';
import { ChevronLeft } from "lucide-react";
import { useHistory } from 'react-router-dom';
import { useRecoilState } from 'recoil';

// Custom components
import { Button } from "../../../../components/ui/button";
import { Checkbox } from "../../../../components/ui/checkbox"
import {
    Card,
    CardDescription,
    CardFooter,
    CardHeader,
    CardContent,
    CardTitle,
} from "../../../../components/ui/card";
import { Separator } from "../../../../components/ui/separator";
import { Badge } from "../../../../components/ui/badge";
import { productReportsState } from "../../../../store/store";

const ProductHeader = ({ product, handleRedirectToAllProduct }) => {
    return (
        <div className=" flex items-start justify-between flex-row w-full">
            <div className='flex items-center'>
                <Button variant='outline' className='mr-4' onClick={handleRedirectToAllProduct}><ChevronLeft className="h-4 w-4" /></Button>
                <div className='flex flex-col w-full items-start'>
                    <h2 className="text-xl font-semibold">{lodash.get(product, ['name'])}</h2>
                    <p className='text-xs'>{lodash.get(product, ['description'])}</p>
                </div>
            </div>

        </div>
    )
}

export default function ProductReportsPreview({
    activeProduct,
    reports,
    setProductsView,
    setActiveProduct,
    handleGenerateReport
}) {
    const history = useHistory();

    const [productReports, setProductReports] = useRecoilState(productReportsState);

    const handleRedirectToAllProduct = () => { setProductsView('REPORTS_LIST'); history.push(`/dashboard/products`); setActiveProduct(null); }

    return (
        <>
            <Card className=''>
                <CardHeader className='text-left flex flex-row justify-between w-full p-4'>
                    <ProductHeader product={activeProduct} handleRedirectToAllProduct={handleRedirectToAllProduct} />
                </CardHeader>
                <Separator />
                <CardContent className="main-area-section-content overflow-y-auto">
                    <section className="flex flex-col overflow-y-auto pb-24">
                        <div className='flex flex-col lg:flex-row justify-between items-start p-4 bg-muted m-2 rounded'>
                            <div className='flex flex-col items-start text-left'>
                                <h2 className="text-xl font-semibold">Recommended Reports</h2>
                                <p className='text-sm'>Based on the Product description provided by you, these are the reports we should generate for Product research.</p>
                            </div>
                            <Button className='my-1' onClick={() => handleGenerateReport()}>Generate selected report</Button>
                        </div>

                        <div className="grid gap-8 p-2 md:grid-cols-2 lg:grid-cols-3  lg:gap-2 cursor-pointer">
                            {Object.values(reports).map((report, index) => (
                                <Card className="w-full text-left">
                                    <CardHeader>
                                        <CardTitle>{lodash.get(report, ['title'])}</CardTitle>
                                        <CardDescription className='text-ellipsis'> {lodash.get(report, ['description'])}</CardDescription>
                                    </CardHeader>
                                    <CardFooter className="flex justify-between">
                                        {lodash.get(report, ['category'], '') ? <Badge>{lodash.get(report, ['category'], '')}</Badge> : null}
                                        <Checkbox checked={report.checkbox} onCheckedChange={(checked) => {
                                            setProductReports(prevState => ({
                                                ...prevState,
                                                [report['id']]: {
                                                    ...reports[report['id']],
                                                    checkbox: checked
                                                }
                                            }));
                                        }} />
                                    </CardFooter>
                                </Card>
                            ))}
                        </div>
                    </section>
                </CardContent>
            </Card>
        </>
    );
}
