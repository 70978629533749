import React, { useState } from 'react';
import lodash from 'lodash';
import { useRecoilState } from 'recoil';
import { systemConstantsState } from '../../store/store';

import { Button } from "../../components/ui/button"
import { Textarea } from "../../components/ui/textarea"
import { Label } from "../../components/ui/label"
import { postFeedback } from '../../services/api'
import { logError } from '../../services/logging_utils';

import { Loader2, Smile } from "lucide-react"

import ErrorBoundary from '../ui/ErrorBoundary'

export const FeedbackForm = ({ character }) => {

    const [systemConstants] = useRecoilState(systemConstantsState);
    const FEEDBACK_FORM = lodash.get(systemConstants, ['STATIC_STRINGS', 'value', 'FEEDBACK_FORM'])

    const [feedback, setFeedback] = useState('');
    const [loading, setLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    const handleSubmit = async () => {
        try {
            setLoading(true);

            const payload = { description: feedback }

            await postFeedback({ payload });

            // Showing the submitted message for the feedback, the user has to manually close the popup
            setSubmitted(true);

        } catch (error) {
            logError(error)
        } finally {
            setLoading(false);
        }
    }

    // Show the feedback submitted UI
    if (submitted) {
        return (
            <>
                <div className="flex flex-col">
                    <p className="text-xl font-bold text-muted-foreground mb-2 flex items-center">
                        <Smile className='mr-2' />  {`Thank you for sharing your feedback. `}
                    </p>
                    <p className="text-sm text-muted-foreground ">
                        {lodash.get(FEEDBACK_FORM, ['submission_success_message'])}
                    </p>
                </div>
            </>
        )
    }

    return (
        <>
            <ErrorBoundary>
                <div className="grid w-full gap-1.5">
                    <Label className=''>Feedback</Label>
                    <Textarea className='mt-4' placeholder="Feedback" name="fullname" disabled={loading} value={feedback} onChange={(e) => setFeedback(e.target.value)} />
                    <p className="text-sm text-muted-foreground">
                        {lodash.get(FEEDBACK_FORM, ['description'])}
                    </p>
                    <Button className='mt-4' onClick={handleSubmit} disabled={loading}>
                        {loading ? <Loader2 className="mr-2 h-4 w-4 animate-spin" /> : null}
                        Submit
                    </Button>
                </div>
            </ErrorBoundary>
        </>
    )
}

