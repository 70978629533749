import React, { useState, useEffect } from 'react';
import { Search, ClipboardCheck, Plus } from 'lucide-react';
import lodash from 'lodash';
// Custom components
import {
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    CardTitle,
} from "../../components/ui/card";
import {
    Tabs,
    TabsContent,
    TabsList,
    TabsTrigger,
} from "../../components/ui/tabs"
import { Avatar, AvatarImage } from "../../components/ui/avatar";

import {
    Table,
    TableBody,
    TableCaption,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "../../components/ui/table"
import { Separator } from "../../components/ui/separator";
import { Button } from "../../components/ui/button";
import { Input } from "../../components/ui/input";
// Services
import { logError } from '../../services/logging_utils';
import { getReportTasks } from '../../services/api';

function CommonCardHeader() {
    return (
        <CardHeader className='text-left bg-muted flex flex-row items-center'>
            <ClipboardCheck className="h-8 w-8 transition-all group-hover:scale-110 mr-4" />
            <div className='flex flex-col'>
                <CardTitle>Tasks</CardTitle>
                <CardDescription>List of tasks that are active, scheduled or researved.</CardDescription>
            </div>
        </CardHeader>
    )
}


function TasksTable(props) {
    const { tasks, taskStatusTab } = props;


    return (
        <>
            <Tabs defaultValue="active" className="">
                <div className='flex w-full'>
                    <form className='w-full mr-2'>
                        <div className='relative'>
                            <Search className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
                            <Input
                                type="search"
                                placeholder="Search Reports..."
                                className="md:w-[100px] lg:w-[300px] mb-2 pl-8"
                            // onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </div>
                    </form>
                    <TabsList className="grid w-full grid-cols-3">
                        <TabsTrigger value="active">Active</TabsTrigger>
                        <TabsTrigger disabled={false} value="reserved">Reserved</TabsTrigger>
                        <TabsTrigger disabled={false} value="scheduled">Scheduled</TabsTrigger>
                    </TabsList>
                </div>
                <div className="bg-white rounded-xl border border-gray-200 dark:bg-gray-950 dark:border-gray-800 flex items-center justify-center w-full h-full">
                    <TabsContent value="active">

                        <>
                            {lodash.get(tasks, [taskStatusTab], []).length === 0 ? (
                                <div className='w-full h-full flex justify-center items-center'>
                                    <p>Currently, no tasks are active.</p>
                                </div>
                            ) : <Table>
                                <TableHeader>
                                    <TableRow>
                                        <TableHead>Task/ Task Id</TableHead>
                                        <TableHead>Report Id/ Product Id</TableHead>
                                        <TableHead className="text-right">Report Type</TableHead>
                                    </TableRow>
                                </TableHeader>
                                <TableBody>
                                    {lodash.get(tasks, [taskStatusTab], []).map(report => {
                                        return (
                                            <TableRow key={lodash.get(report, ['args', 0, 'task_id'])}>
                                                <TableCell className='text-left'>
                                                    <div className='flex flex-col ml-2'>
                                                        <p className='font-bold'>{lodash.get(report, ['args', 0, 'task'])}</p>
                                                        <p className=''>Task Id: {lodash.get(report, ['args', 0, 'task_id'])}</p>
                                                    </div>
                                                </TableCell>
                                                <TableCell className='text-left capitalize'>
                                                    <div className='flex flex-col ml-2'>
                                                        <p className=''>{lodash.get(report, ['args', 0, 'report_id'])}</p>
                                                        <p className=''>{lodash.get(report, ['args', 0, 'product_id'])}</p>
                                                    </div>
                                                </TableCell>
                                                <TableCell className="text-right capitalize">{lodash.get(report, ['args', 0, 'report_type'], '').replace('_', " ")}</TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>}
                        </>

                    </TabsContent>

                    <TabsContent value="reserved">
                        <div className='w-full h-full flex justify-center items-center'>
                            <p>Currently, no tasks are active.</p>
                        </div>
                    </TabsContent>

                    <TabsContent value="scheduled">
                        <div className='w-full h-full flex justify-center items-center'>
                            <p>Currently, no tasks are active.</p>
                        </div>
                    </TabsContent>
                </div>
            </Tabs>
        </>
    )
}


function AdminTasksList() {

    const [tasks, setTasks] = useState([]);
    const [loadingTasks, setLoadingTasks] = useState(false);

    const [taskStatusTab, setTaskStatusTab] = useState('active');

    useEffect(() => {
        (async () => {
            setLoadingTasks(true);
            try {
                const response = await getReportTasks();
                const users = response.data;
                setTasks(users);
            } catch (error) {
                logError(error);
            }
            setLoadingTasks(false);
        })()
    }, []);

    return (
        <>
            <Card className='h-full'>
                <CommonCardHeader />
                <Separator />
                <CardContent className='p-4 w-full h-full'>
                    <div className='flex flex-col h-full'>
                        {loadingTasks ? <div className='p-2 rounded my-1 bg-accent text-left'><p className='text-md font-semibold'>Loading Tasks...</p></div> : null}

                        <section className=" grid gap-8 grid-cols-1  lg:gap-2 cursor-pointer h-full" style={{ maxHeight: 'calc(100vh - 284px)', overflowY: 'auto' }}>
                            <TasksTable tasks={tasks} taskStatusTab={taskStatusTab} loadingTasks={loadingTasks} />
                        </section>
                    </div>
                </CardContent >
            </Card >
        </>
    )
}

export default AdminTasksList;