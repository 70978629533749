import { useHistory } from 'react-router-dom';
import lodash from 'lodash';
// Components
import { UserNav } from "../components/navbar/user-nav";
import { ModeToggle } from "./ui/mode-toggler.js";
import { NotificationDropdown } from "./ui/notification-dropdown"; // Import the new component
import { Button } from "../components/ui/button"
import { Image } from "../components/ui/image"
// Images
import logo192 from '../images/logo192.png';
import { createSubscriptionSession } from '../services/api'

export const TopNav = () => {
  const history = useHistory();

  const handleUpgrade = async () => {
    const response = await createSubscriptionSession({ customer_id: '', plan_id: '' });
    const subscriptionId = lodash.get(response, ['data', 'subscription_id']);
    console.log('subscriptionId', subscriptionId, response)

    const options = {
      "key": "rzp_test_FooEhvN792Bd6H", // Enter the Key ID generated from the Razorpay Dashboard
      "subscription_id": subscriptionId,
      "name": "Product Agent",
      "description": "Subscription to Your Product",
      "handler": function (response) {
        // Handle payment success
        console.log(response.razorpay_payment_id);
        console.log(response.razorpay_subscription_id);
        console.log(response.razorpay_signature);
      },
      "theme": {
        "color": "#3399cc"
      },
      "modal": {
        "ondismiss": function () {
          // Handle checkout dismissal
          console.log("Checkout dismissed");
        }
      }
    };

    const rzp1 = new window.Razorpay(options);
    rzp1.open();

    rzp1.on('payment.failed', function (response) {
      // Handle payment failure
      console.log(response.error.code);
      console.log(response.error.description);
      console.log(response.error.source);
      console.log(response.error.step);
      console.log(response.error.reason);
      console.log(response.error.metadata);
    });
  }

  return (
    <>
      <div className="flex-col md:flex bg-background">
        <div className="border-b">
          <div className="flex h-16 items-center px-4">
            <div className="flex justify-between items-center space-x-4 w-full">
              <Button variant='ghost' className='flex items-center' onClick={() => history.push('/')}>
                <Image className='w-8 h-8' src={logo192} />
                <p className='text-lg font-bold ml-2'>ProductAgent</p>
              </Button>
              <div className='flex items-center'>
                <NotificationDropdown /> {/* Add the notification dropdown here */}
                <ModeToggle />
                <UserNav />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
