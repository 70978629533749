import React, { useRef, useEffect, useState } from 'react';
import ChatInterface from './ChatInterface'; // Import ChatInterface
import { Card, CardContent } from "../../../../components/ui/card"; // Import Card components
import CommonCardHeader from '../../../../components/CommonCardHeader'; // Import CommonCardHeader
import { MessageCircle } from 'lucide-react'; // Import an appropriate icon for the chat
import { Separator } from "../../../../components/ui/separator"; // Import Separator
import useParentDimensions from '../../../../hooks/useParentDimensions'; // Import the custom hook

const ChatView = (props) => {
    const cardRef = useRef(null); // Reference for the Card
    const { height: cardHeight } = useParentDimensions(cardRef); // Get the height of the Card
    const [contentHeight, setContentHeight] = useState(0); // State for CardContent height

    // Calculate the height of CardContent based on Card height
    useEffect(() => {
        if (cardHeight) {
            const newHeight = cardHeight - 98; // Deduct 98px for the CommonCardHeader
            setContentHeight(newHeight); // Set the new height for CardContent
        }
    }, [cardHeight]);

    return (
        <Card ref={cardRef} className='h-full w-full m-2 main-area-section-h bg-background'>
            <CommonCardHeader
                icon={MessageCircle} // Use the MessageCircle icon
                title="Chat Interface"
                description="Engage in real-time conversations."
            />
            <Separator className="w-full bg-background" />
            <CardContent className="bg-accent p-2 main-area-section-content" style={{ height: contentHeight }}>
                <ChatInterface {...props} scope="user_chat" contentHeight={contentHeight} /> {/* Pass contentHeight as a prop */}
            </CardContent>
        </Card>
    );
};

export default ChatView;