import React, { useState } from 'react';
import PropTypes from 'prop-types';
import lodash from 'lodash';
import { KnowledgeBaseHeader } from './KnowledgeBaseHeader';
import { Card, CardContent } from '../../../components/ui/card'; // Import Card components
import { Input } from '../../../components/ui/input'; // Import Input component
import { Textarea } from '../../../components/ui/textarea'; // Assuming Textarea is defined similarly

const KnowledgeBaseDetails = ({ knowledgeBase, handleOnEditorUpdate }) => {
    const defaultDescription = 'No description was added for this knowledge base.';

    return (
        <>
            <KnowledgeBaseHeader knowledgeBase={knowledgeBase} handleOnEditorUpdate={handleOnEditorUpdate} />
            <Card className='m-4'>
                <CardContent className='p-4'>
                    <div className="grid gap-6">
                        <div className="grid gap-3">
                            <Input
                                label="Title"
                                id="name"
                                type="text"
                                className="w-full"
                                disabled={true}
                                defaultValue="Gamer Gear Pro Controller"
                                value={lodash.get(knowledgeBase, ['title'])}
                            />
                        </div>
                        <div className="grid gap-3">
                            <Textarea
                                label="Description"
                                id="description"
                                disabled={true}
                                defaultValue={defaultDescription}
                                className="min-h-32"
                                value={lodash.get(knowledgeBase, ['description']) === '' ? defaultDescription : lodash.get(knowledgeBase, ['description'])}
                            />
                        </div>
                    </div>
                </CardContent>
            </Card>
        </>
    );
};

KnowledgeBaseDetails.propTypes = {
    knowledgeBase: PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
        type: PropTypes.string,
        content: PropTypes.string,
    }).isRequired,
};

export default KnowledgeBaseDetails;