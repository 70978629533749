import React from 'react';
import { useRecoilState } from 'recoil';
import { loadStripe } from '@stripe/stripe-js';
// Components
import { Button } from '../ui/button';
// Services
import { logError } from '../../services/logging_utils';
import { subscriptionPlansState } from '../../store/store';
import { createCheckoutSession, manageBillingSession } from '../../services/api';

export const UpgradeCards = () => {

    const [subscriptionPlans] = useRecoilState(subscriptionPlansState);

    const handleSubscribe = async (plan, frequency) => {
        const domain = window.location.origin;
        const STIRPE_SUCCESS_URL = `${domain}/stripe/success` || "http://localhost:3000/stripe/success";
        const STIRPE_CANCEL_URL = `${domain}/stripe/cancel` || "http://localhost:3000/stripe/cancel";

        try {
            const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
            const body = {
                plan_id: plan.plan_id,
                plan_frequency: frequency,
                success_url: STIRPE_SUCCESS_URL,
                cancel_url: STIRPE_CANCEL_URL,
            }

            const response = await createCheckoutSession({ body });
            const session = response.data;

            const result = await stripe.redirectToCheckout({
                sessionId: session.id,
            });
            if (result.error) {
                console.log(result.error.message)
            }
        } catch (error) {
            logError(error)
        }
    }

    const handleManageBilling = async () => {
        try {

            const response = await manageBillingSession({});
            const session = response.data;
            const sessionUrl = session.url;
            console.log('sessionUrl', sessionUrl)
            window.location.replace(sessionUrl);
        } catch (error) {

        }
    }

    return (
        <section className='relative  bg-gray-900'>
            <div className='absolute inset-0 blur-[118px] h-[800px] mx-auto sm:max-w-3xl sm:h-[400px]' style={{ background: "linear-gradient(106.89deg, rgba(192, 132, 252, 0.11) 15.73%, rgba(14, 165, 233, 0.41) 15.74%, rgba(232, 121, 249, 0.26) 56.49%, rgba(79, 70, 229, 0.4) 115.91%)" }}></div>
            <div className="relative max-w-screen-xl mx-auto text-gray-300 sm:px-4 md:px-8">
                <div className='max-w-xl mx-auto space-y-3 px-4 sm:text-center sm:px-0'>
                    <h3 className="text-cyan-400 font-semibold">
                        Pricing
                    </h3>
                    <p className='text-white text-3xl font-semibold sm:text-4xl'>
                        Pay as you grow
                    </p>
                    <div className='max-w-xl'>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam efficitur consequat nunc.
                        </p>
                    </div>
                </div>
                <div className='mt-16 justify-center sm:flex'>
                    {
                        subscriptionPlans.map((plan, idx) => (
                            <div key={idx} className={`relative flex-1 flex plans-stretch flex-col mt-6 border-2 sm:mt-0 sm:rounded-xl sm:max-w-md ${plan.popular ? "bg-gray-900 border-cyan-400 border-x-0 sm:border-x-2" : "border-transparent"}`}>
                                <div className="p-4 py-8 space-y-4 border-b border-gray-700 md:p-8">
                                    <span className='text-gray-200 font-medium'>
                                        {plan.name}
                                    </span>
                                    <div className='text-cyan-400 text-3xl font-semibold'>
                                        ${plan.price} <span className="text-xl font-normal">/mo</span>
                                    </div>
                                    <p className="text-gray-400">
                                        {plan.desc}
                                    </p>
                                    <button className='px-3 py-3 rounded-lg w-full font-semibold text-sm duration-150 text-white bg-cyan-500 hover:bg-cyan-600 active:bg-cyan-700' onClick={() => handleSubscribe(plan, 'monthly')}>
                                        Get Started
                                    </button>
                                </div>
                                {/* <ul className='p-4 py-8 space-y-3 md:p-8'>
                                    {
                                        plan.features.map((featureplan, idx) => (
                                            <li key={idx} className='flex plans-center gap-5'>
                                                <svg
                                                    xmlns='http://www.w3.org/2000/svg'
                                                    className={`h-5 w-5 ${plan.popular ? "text-cyan-600" : ""}`}
                                                    viewBox='0 0 20 20'
                                                    fill='currentColor'>
                                                    <path
                                                        fill-rule='evenodd'
                                                        d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
                                                        clip-rule='evenodd'></path>
                                                </svg>
                                                {featureplan}
                                            </li>
                                        ))
                                    }
                                </ul> */}
                            </div>
                        ))
                    }
                    <Button onClick={handleManageBilling}>Manage Billing</Button>
                </div>
            </div>
        </section>
    )
}
