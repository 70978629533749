import React from 'react';
import logo192 from '../images/logo192.png';
import { Image } from '../components/ui/image';

function BrandLogo() {
    return (
        <div className="flex items-center justify-start w-full">
            <Image className="w-auto h-12 sm:h-12" src={logo192} alt="ProductAgent" />
            <p className='text-2xl font-bold ml-2'>ProductAgent</p>
        </div>
    )
}

export default BrandLogo