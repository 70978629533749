import { useState } from 'react';
import lodash from 'lodash';
import { Loader } from 'lucide-react';
import Lottie from 'react-lottie';
// Custom components
import { TooltipProvider } from '../../../../components/ui/tooltip';
import { Button } from '../../../../components/ui/button';
import { Skeleton } from '../../../../components/ui/skeleton';
import { Textarea } from '../../../../components/ui/textarea';
import BlocknoteEditor from '../../../../components/BlocknoteEditor';
import emptyReportLottie from '../../../../lotties/search_lottie_animation.json';
import researchingReportLottie from '../../../../lotties/researching_report.json';
// Services
import { updateReportContent } from '../../../../services/api';
import { logError } from "../../../../services/logging_utils";

function ReportGeneratingMessage() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: emptyReportLottie,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <div className="flex flex-col justify-center items-center h-full">
      <Lottie
        options={defaultOptions}
        height={200}
        width={400}
      />
      <p className="text-center text-gray-700 font-bold">Please check the below input, make any changes you want and click <span className='text-blue-900 font-bold'>Generate Report</span></p>
    </div>
  );
}

function ResearchingReportLottie() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: researchingReportLottie,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <div className="flex flex-col justify-center items-center h-full">
      <Lottie
        options={defaultOptions}
        height={320}
        width={400}
      />
      <p className="text-center my-2 font-bold text-blue-900">Working on the report, it might take few minutes...</p>
    </div>
  );
}

export function ReportDisplay({
  report, startResearching, inputValue, setInputValue, handleTextareaEnter, onStartSetupClick, loadingReport, selectedReportVersion
}) {

  const [updatingContent, setUpdatingContent] = useState(false);

  if (loadingReport && report === null) {
    return (
      <div className="flex h-full flex-col p-2 justify-start">
        <Skeleton className='w-[300px] h-[60px] my-1' />
        <Skeleton className='w-[400px] h-[40px] my-1' />
        <Skeleton className='w-full h-[40px] my-1' />
        <Skeleton className='w-full h-[20px] my-1' />
        <Skeleton className='w-full h-[20px] my-1' />
        <Skeleton className='w-full h-[20px] my-1 mb-8' />


        <Skeleton className='w-[300px] h-[60px] my-1' />
        <Skeleton className='w-[400px] h-[40px] my-1' />
        <Skeleton className='w-full h-[20px] my-1' />
        <Skeleton className='w-full h-[20px] my-1' />
        <Skeleton className='w-full h-[20px] my-1' />

      </div>
    )
  }


  // This needs to be updated, for an existing report coming from server the content is a string. Whereas while the streaming is going on the report content comes as an array.
  const reportContent = lodash.get(report, ['content', selectedReportVersion, 'content'], '');
  const selectedReport = lodash.get(report, ['content', selectedReportVersion], {});
  const sanitzedReportContent = typeof reportContent === 'string' ? reportContent : (reportContent || []).filter((r) => r !== '' && typeof r === 'string').join('');

  const reportResearchInProgress = (['in_progress', 'queued'].includes(lodash.get(report, ['status'])) && [null, ''].includes(sanitzedReportContent)) || startResearching;
  const noReportGenerated = [null, 'pending'].includes(lodash.get(report, ['status'])) && ['', null].includes(sanitzedReportContent);

  const handleOnEditorUpdate = async (markdown) => {
    // Call your API here with the markdown content
    try {
      setUpdatingContent(true);
      const payload = {
        report_id: selectedReport.report_id,
        content: markdown,
        task_id: selectedReport.task_id,
        task: selectedReport.task,
        logs: selectedReport.logs
      }
      await updateReportContent({ taskId: selectedReport.task_id, payload: payload });

    } catch (error) {
      logError('Error updating report', error);
    } finally {
      setUpdatingContent(false);
    }
  }

  return (
    <div className="flex h-full flex-col justify-end p-2 gap-2">
      <div
        className="w-full text-left h-full"
        style={{ maxHeight: 'calc(100vh - 342px)', overflowY: 'auto' }}
      >
        {(reportResearchInProgress) ? <ResearchingReportLottie /> : null}

        {noReportGenerated ? <ReportGeneratingMessage /> : null}

        {sanitzedReportContent ? (
          <BlocknoteEditor autoSave={true} content={sanitzedReportContent} updatingContent={updatingContent} handleOnEditorUpdate={handleOnEditorUpdate} />
        )
          : null}
      </div>
      <div
        className="relative overflow-hidden rounded-lg border bg-background focus-within:ring-1 focus-within:ring-ring"
      >
        <Textarea
          id="message"
          placeholder="Type your message here..."
          className="min-h-12 resize-none border-0 p-3 shadow-none focus-visible:ring-0 text-md"
          value={inputValue}
          disabled={reportResearchInProgress}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={handleTextareaEnter}
        />
        <div className="flex items-center p-3 pt-0">
          <TooltipProvider>
            {/* <Tooltip>
              <TooltipTrigger asChild>
                <Button variant="ghost" size="icon">
                  <Paperclip className="size-4" />
                  <span className="sr-only">Attach file</span>
                </Button>
              </TooltipTrigger>
              <TooltipContent side="top">Attach File</TooltipContent>
            </Tooltip>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button variant="ghost" size="icon">
                  <Mic className="size-4" />
                  <span className="sr-only">Use Microphone</span>
                </Button>
              </TooltipTrigger>
              <TooltipContent side="top">Use Microphone</TooltipContent>
            </Tooltip> */}
            <Button
              type="submit"
              size="sm"
              className="ml-auto gap-1.5"
              disabled={reportResearchInProgress}
              onClick={() => onStartSetupClick(inputValue)}
            >
              {startResearching ? <Loader /> : "Generate Report"}

            </Button>
          </TooltipProvider>
        </div>
      </div>
    </div>
  );
}
