import React, { useState } from 'react';
import { useRecoilState } from 'recoil';
import { useHistory } from 'react-router-dom';
// Custom components
import ProductView from './components/ProductView';
import ProductListGrid from './components/ProductListGrid';
import ProductReportsPreview from './components/ProductReportsPreview';
// State
import { activeProductState } from '../../../store/store';

export default function ProductsView() {
    const history = useHistory();


    const [reportsView, setProductsView] = useState('REPORTS_LIST');
    const [activeProduct, setActiveProduct] = useRecoilState(activeProductState);

    // For redirection to the products list, called when the back button is clicked
    const handleRedirectToAllProduct = () => { setProductsView('REPORTS_LIST'); history.push('/dashboard/products'); setActiveProduct(null); };

    return (
        <div className="flex flex-col sm:py-2 px-2 main-area-section-h main-area-section-w">
            {reportsView === 'REPORTS_LIST' ? <ProductListGrid setProductsView={setProductsView} setActiveProduct={setActiveProduct} /> : null}
            {reportsView === 'PRODUCT_REPORTS_PREVIEW' ? <ProductReportsPreview activeProduct={activeProduct} setProductsView={setProductsView} setActiveProduct={setActiveProduct} /> : null}
            {reportsView === 'REPORT_VIEW' ? <ProductView activeProduct={activeProduct} setProductsView={setProductsView} setActiveProduct={setActiveProduct} handleRedirectToAllProduct={handleRedirectToAllProduct} /> : null}
        </div>
    );
}
