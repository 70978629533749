import React from 'react';

export const Alert = ({ message, type }) => {
  const alertStyles = {
    error: "bg-red-100 text-red-700 border-red-500",
    success: "bg-green-100 text-green-700 border-green-500",
    info: "bg-blue-100 text-blue-700 border-blue-500",
  };

  return (
    <div className={`rounded-md py-2 px-4 ${alertStyles[type]}`}>
      <p>{message}</p>
    </div>
  );
};

