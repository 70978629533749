import { useState, useEffect } from 'react';
import PropTypes from 'prop-types'; // Import PropTypes
import lodash from 'lodash';
import { Trash2, LoaderCircle } from 'lucide-react';
// Custom components
import { Button } from "../../../../components/ui/button";
import {
    Dialog,
    DialogHeader, DialogTitle, DialogFooter,
    DialogContent,
} from "../../../../components/ui/dialog";
import {
    TooltipProvider,
    Tooltip,
    TooltipTrigger,
    TooltipContent,
} from "../../../../components/ui/tooltip";
// Services
import { deleteReport } from '../.././../../services/api';

export const DeleteReportDialog = ({ report, reportDeletedCb, isOpen, setDialogStatus }) => {
    const [deletingReport, setDeletingReport] = useState(false);

    const handleSubmit = async () => {
        try {
            setDeletingReport(true);

            const response = await deleteReport(lodash.get(report, ['id']));
            const code = lodash.get(response, ['data', 'code']);
            if (code === 200) {
                setDialogStatus(false);
                reportDeletedCb(true);
            }

        } catch (error) {
            console.log(error);
        } finally {
            setDeletingReport(false);
        }
    };

    if (!isOpen) {
        return (

            <TooltipProvider>
                <Tooltip>
                    <TooltipTrigger asChild>
                        <Button
                            size="icon" variant="outline"
                            className="flex items-center justify-center text-4xl font-bold m-1"
                            onClick={(e) => { e.stopPropagation(); setDialogStatus(true); }}
                        >
                            <Trash2 className="h-4 w-4" />
                        </Button>
                    </TooltipTrigger>
                    <TooltipContent side="right">Delete</TooltipContent>
                </Tooltip>
            </TooltipProvider>
        )
    }

    return (
        <Dialog open={isOpen} onOpenChange={() => setDialogStatus(false)}>
            <DialogHeader>
                <DialogTitle>
                    <Button
                        size="icon" variant="outline"
                        className="flex items-center justify-center text-4xl font-bold m-1"
                    >
                        <Trash2 className="h-4 w-4" />
                    </Button>
                </DialogTitle>
            </DialogHeader>
            <DialogContent className="sm:max-w-[425px]">
                <div className="grid gap-4 py-4">
                    <div className="flex flex-col gap-2">
                        <p className='text-lg '>You will be deleting - <span className="font-bold">{lodash.get(report, ['title'])}</span></p>
                        <p className='text-sm'>Are you sure? This will delete all the research and entries created for this report.</p>
                    </div>
                </div>
                <DialogFooter>
                    <Button disabled={deletingReport} type="submit" onClick={handleSubmit}>
                        {deletingReport ? <LoaderCircle /> : 'Delete Report'}
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}

// Add PropTypes for the component
DeleteReportDialog.propTypes = {
    report: PropTypes.object.isRequired,
    reportDeletedCb: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    setDialogStatus: PropTypes.func.isRequired,
};
