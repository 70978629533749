
import lodash from 'lodash';
import { logout } from "../../lib/utils/auth_utils"
import {
  useRecoilState
} from 'recoil';
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "../ui/avatar"
import { Button } from "../ui/button"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../ui/dropdown-menu"
import { LoginDialog } from "../LoginDialog";
import { userState, userSubscriptionState } from "../../store/store";

export function UserNav() {

  const [user, setUserState] = useRecoilState(userState);
  const [subscriptionState] = useRecoilState(userSubscriptionState);

  if (!user) {
    return <LoginDialog btnLabel="Sign In" />;
  }

  const remaingMessages = lodash.get(subscriptionState, ["features", "messages", "limit"]) - lodash.get(subscriptionState, ["features", "messages", "exhausted"]);
  const remainingCharacterChats = lodash.get(subscriptionState, ["features", "character_chats", "limit"]) - lodash.get(subscriptionState, ["features", "character_chats", "exhausted"]);

  const logoutUser = () => {
    logout();
    setUserState(null);
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" className="relative h-8 w-8 rounded-full">
          <Avatar className="h-8 w-8">
            <AvatarImage src={lodash.get(user, ['profile_image']) ? lodash.get(user, ['profile_image']) : "https://github.com/shadcn.png"} alt="@shadcn" />
            <AvatarFallback></AvatarFallback>
          </Avatar>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56" align="end" forceMount>
        {user ? <DropdownMenuLabel className="font-normal">
          <div className="flex flex-col space-y-1">
            <div className='mb-2'>
              <p className="text-sm font-medium leading-none mb-2">{lodash.get(user, 'username')}</p>
              <p className="text-xs leading-none text-muted-foreground">
                {lodash.get(user, 'email')}
              </p>
            </div>

            {/* <Separator className="" />

            <div className="flex flex-col">
              <p className="text-xs leading-none text-muted-foreground my-2">
                Remaining messages: {remaingMessages}
              </p>
              <p className="text-xs leading-none text-muted-foreground mb-2">
                Remaining chats: {remainingCharacterChats}
              </p>
            </div> */}
          </div>
        </DropdownMenuLabel> : null}
        <DropdownMenuSeparator />
        <DropdownMenuGroup>
          {/* <DropdownMenuItem>
            Profile
            <DropdownMenuShortcut>⇧⌘P</DropdownMenuShortcut>
          </DropdownMenuItem> */}
          {/* <DropdownMenuItem> */}
          {/* <SubscriptionDialog /> */}
          {/* <DropdownMenuShortcut>⌘B</DropdownMenuShortcut>
            </DropdownMenuItem> */}
          {/* <DropdownMenuItem>
              Settings
              <DropdownMenuShortcut>⌘S</DropdownMenuShortcut>
            </DropdownMenuItem>
            <DropdownMenuItem>New Team</DropdownMenuItem> */}
        </DropdownMenuGroup>
        <DropdownMenuSeparator />
        <DropdownMenuItem className='cursor-pointer' onClick={logoutUser}>
          Log out
          {/* <DropdownMenuShortcut>⇧⌘Q</DropdownMenuShortcut> */}
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}