import React from 'react';

export default function HomeView() {
    return (
        <div className="flex min-h-screen w-full flex-col items-center justify-center bg-blue-100">
            <h1 className="text-4xl font-bold text-blue-900">Welcome to the Dashboard!</h1>
            <p className="text-lg font-semibold">Your main hub for analytics and reports.</p>
            {/* Add more content or components as needed */}
        </div>
    );
}