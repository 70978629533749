import React, { useState } from 'react';
import lodash from 'lodash';
import { cn } from '../../../../src/lib/utils';
import { KnowledgeBaseStatusBadge } from './KnowledgeBaseStatusBadge';
import { Input } from '../../../components/ui/input'; // Import the Input component
import { Separator } from '../../../components/ui/separator'; // Import the Separator component
import { DropdownMenu, DropdownMenuTrigger, DropdownMenuContent, DropdownMenuItem } from '../../../components/ui/dropdown-menu'; // Import the DropdownMenu components
import { Filter } from 'lucide-react'; // Import an icon for the filter

const KnowledgeBaseList = ({ knowledgeBases, selectedKnowledgeBase, onSelectKnowledgeBase }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [filterType, setFilterType] = useState('All');
    const [isFilterActive, setIsFilterActive] = useState(false); // State to track if filter is active

    // Group knowledge bases by type
    const groupedKnowledgeBases = knowledgeBases.reduce((acc, kb) => {
        const type = kb.type || 'Other'; // Default to 'Other' if type is not defined
        if (!acc[type]) {
            acc[type] = [];
        }
        acc[type].push(kb);
        return acc;
    }, {});

    // Filter knowledge bases based on search term and selected filter type
    const filteredKnowledgeBases = Object.entries(groupedKnowledgeBases).map(([type, bases]) => {
        const filteredBases = bases.filter(kb =>
            (filterType === 'All' || kb.type === filterType) && // Filter by type
            (kb.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
                kb.description.toLowerCase().includes(searchTerm.toLowerCase()))
        );

        return { type, bases: filteredBases };
    }).filter(group => group.bases.length > 0); // Remove empty groups

    return (
        <div className="flex flex-col">
            <div className="sticky top-0 z-10 py-2 flex items-center justify-between gap-2">
                <Input
                    placeholder="Search Knowledge Bases..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className=""
                />
                <DropdownMenu>
                    <DropdownMenuTrigger className={`relative p-2 border rounded flex items-center ${isFilterActive ? '' : ''}`}>
                        <Filter className="h-4 w-4" />
                        {filterType !== 'All' && <span className="ml-auto h-2 w-2 bg-green-500 rounded-full absolute top-0 right-0" />} {/* Green dot if filter is active */}
                    </DropdownMenuTrigger>
                    <DropdownMenuContent>
                        <DropdownMenuItem onClick={() => { setFilterType('All'); setIsFilterActive(false); }}>All Types</DropdownMenuItem>
                        {Object.keys(groupedKnowledgeBases).map(type => (
                            <DropdownMenuItem key={type} onClick={() => { setFilterType(type); setIsFilterActive(true); }} className="capitalize">{type}</DropdownMenuItem>
                        ))}
                    </DropdownMenuContent>
                </DropdownMenu>
            </div>
            <Separator />
            {filteredKnowledgeBases.map(({ type, bases }) => (
                <div key={type} className="mt-2 text-left capitalize text-blue-500">
                    <h3 className="font-semibold text-sm">{type}</h3>
                    {bases.map((kb) => (
                        <button
                            key={kb.id}
                            className={cn(
                                "flex flex-col items-start rounded-lg border p-2 my-2 text-left text-sm transition-all w-full",
                                selectedKnowledgeBase?.id === kb.id ? "bg-primary text-white" : "bg-background text-foreground"
                            )}
                            onClick={() => onSelectKnowledgeBase(kb)}
                        >
                            <div className="flex w-full flex-col p-2">
                                <div className="flex items-center">
                                    <div className="font-bold">{lodash.get(kb, 'title')}</div>
                                </div>
                                <KnowledgeBaseStatusBadge status={lodash.get(kb, 'status')} />
                            </div>
                        </button>
                    ))}
                    <Separator />
                </div>
            ))}
        </div>
    );
};

export default KnowledgeBaseList;