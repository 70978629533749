import React, { useEffect, useState } from 'react';
import lodash from 'lodash';
import { useHistory } from 'react-router-dom';
import { Package, ClipboardCheck, Plus } from 'lucide-react';
import Lottie from 'react-lottie';

// Custom components
import { Button } from "../../components/ui/button"
import { Card, CardHeader, CardTitle, CardContent, CardDescription } from "../../components/ui/card"
import { Table, TableHeader, TableRow, TableHead, TableBody, TableCell } from "../../components/ui/table"
import ErrorBoundary from '../../components/ui/ErrorBoundary';
import { StatusBadge } from './products/components/StatusBadge';
import { Skeleton } from '../../components/ui/skeleton';
import WordFadeIn from '../../components/WordFadeIn';
// Services
import { getAnalytics } from '../../services/api';
import { logError } from '../../services/logging_utils';
import EmptyAnalytics from '../../lotties/empty_analytics.json';


const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: EmptyAnalytics,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

function AnalyticsCard({ title, description, value, icon, loading }) {

  if (loading) {
    return (
      <Skeleton className="h-[125px] w-full rounded-xl" />
    )
  }

  return (
    <Card className='p-4 h-[125px] w-full'>
      <CardHeader className="flex flex-row items-center justify-between p-0">
        <CardTitle className=" font-medium">{title}</CardTitle>
        {icon}
      </CardHeader>
      <CardContent className='text-left'>
        <div className="text-2xl font-bold">{value}</div>
        <p className="text-sm text-muted-foreground">{description}</p>
      </CardContent>
    </Card>
  )
}

function ReportsTable({ analytics }) {
  return (
    <>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Name</TableHead>
            <TableHead>Type</TableHead>
            <TableHead>Status</TableHead>
            {/* <TableHead>Date</TableHead> */}
          </TableRow>
        </TableHeader>
        <TableBody>
          {lodash.get(analytics, ['latest_reports'], []).map((report, index) => (
            <TableRow>
              <TableCell className='text-left'>
                <div className="font-medium text-ellipsis">{lodash.get(report, ['title'])}</div>
                {/* <div className="text-sm text-muted-foreground md:inline text-ellipsis">{lodash.get(report, ['description'])}</div> */}
              </TableCell>
              <TableCell className="text-left">{lodash.get(report, ['product_id']) ? "Product Report" : "Research Report"}</TableCell>
              <TableCell className="text-left">
                <StatusBadge status={lodash.get(report, ['status'])} />
              </TableCell>
              {/* <TableCell className="text-left">{lodash.get(report, ['created_at'])}</TableCell> */}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  )
}

function LoadingReports() {
  return (
    <>
      <Table>
        <TableBody>
          <Skeleton className="h-[50px] w-full m-2" />
          <Skeleton className="h-[50px] w-full m-2" />
          <Skeleton className="h-[50px] w-full m-2" />
          <Skeleton className="h-[50px] w-full m-2" />
          <Skeleton className="h-[50px] w-full m-2" />
          <Skeleton className="h-[50px] w-full m-2" />
        </TableBody>
      </Table>
    </>
  )
}

export default function AnalyticsView({ }) {

  const history = useHistory();
  const [analytics, setAnalytics] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (
      async () => {
        try {
          setLoading(true);
          const response = await getAnalytics();
          const analytics = lodash.get(response, ['data', 'data'], {});
          setAnalytics(analytics);
        } catch (error) {
          logError(error);
        } finally {
          setLoading(false);
        }
      }
    )()
  }, []);


  // If there are no reports generated
  if (lodash.get(analytics, ['reports_count']) === 0) {
    return (
      <>
        <div className="flex min-h-screen w-full flex-col bg-blue-100">
          <main className="flex flex-1 flex-col gap-4 p-4 md:gap-8 md:p-8 items-center jsutify-center h-full">
            <Lottie
              options={defaultOptions}
              height={320}
              width={320}
              style={{ margin: 0 }}
            />
            <div className='flex flex-col'>
              <WordFadeIn
                className="text-4xl font-bold  text-blue-900 "
                words="Let's get started!"
              />
              <WordFadeIn
                className="text-lg font-semibold"
                words="It's super easy. Please select any of the below option."
              />
              <div className='flex flex-col lg:flex-row'>
                <Button
                  variant="outline"
                  size="lg"
                  className="w-full h-full flex py-4 px-8 items-center justify-between text-4xl font-bold m-2 max-w-[420px] rounded-xl"
                  onClick={() => history.push('/dashboard/products')}
                >
                  <Package className="h-20 w-20 transition-all group-hover:scale-110 mr-8" />
                  <div className='flex flex-col justify-start items-start text-left'>
                    <span className='text-2xl'>Add Product</span>
                    <span className='text-sm font-normal text-wrap'>Products are product you want to develop, products can have many research reports related to them.</span>
                  </div>
                </Button>

                <Button
                  variant="outline"
                  size="lg"
                  className="w-full h-full flex py-4 px-8 items-center justify-between text-4xl font-bold m-2 max-w-[420px] rounded-xl"
                  onClick={() => history.push('/dashboard/reports')}
                >
                  <ClipboardCheck className="h-12 w-12 transition-all group-hover:scale-110 mr-8" />
                  <div className='flex flex-col justify-start items-start text-left text-wrap'>
                    <span className='text-2xl'>Add Report</span>
                    <span className='text-sm font-normal'>Reports are independent research reports for any topic you add.</span>
                  </div>
                </Button>

              </div>
            </div>
          </main>
        </div>
      </>
    )
  }

  return (
    <>
      <ErrorBoundary>
        <div className="flex min-h-screen w-full flex-col">
          <main className="flex flex-1 flex-col gap-4 p-4 md:gap-8 md:p-8">
            <div className="grid gap-4 md:grid-cols-2 md:gap-8 lg:grid-cols-4">
              <AnalyticsCard loading={loading} title='Products' description='products created' value={lodash.get(analytics, ['products_count'])} icon={<Package className="h-8 w-8 text-muted-foreground" />} />
              <AnalyticsCard loading={loading} title='Reports' description='reports generated' value={lodash.get(analytics, ['reports_count'])} icon={<ClipboardCheck className="h-8 w-8 text-muted-foreground" />} />
            </div>
            <div className="grid gap-4 md:gap-8 grid-cols-3">
              <Card className="col-span-3">
                <CardHeader className="flex flex-row items-center bg-accent">
                  <div className="grid gap-2 text-left ">
                    <CardTitle>Recent Reports</CardTitle>
                    <CardDescription>Recent reports in progress and queued.</CardDescription>
                  </div>
                </CardHeader>
                <CardContent>
                  {loading ? <LoadingReports /> : <ReportsTable analytics={analytics} />}
                </CardContent>
              </Card>
            </div>
          </main>
        </div>
      </ErrorBoundary >
    </>
  )
}