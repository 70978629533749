import React, { useState } from 'react';
import lodash from 'lodash';
import {
    Card,
    CardDescription,
    CardFooter,
    CardHeader,
    CardTitle,
} from "../../../../components/ui/card";
import { Eye } from 'lucide-react';
import { Badge } from "../../../../components/ui/badge";
import { Button } from "../../../../components/ui/button";
import { EditReportDialog } from "./EditReportDialog";
import { DeleteReportDialog } from './DeleteReportDialog';
import { StatusBadge } from '../../products/components/StatusBadge';

function ReportCard(props) {
    const { report, handleRedirectToReport, handleRefreshReportList } = props;

    const [editDialogStatus, setEditDialogStatus] = useState(null);
    const [deleteDialogStatus, setDeleteDialogStatus] = useState(null);

    return (
        <Card className="w-full text-left">
            <CardHeader className='w-full text-left'>
                <CardTitle>{lodash.get(report, ['title'])}</CardTitle>
                <CardDescription className='text-ellipsis'> {lodash.get(report, ['description'])}</CardDescription>
            </CardHeader>
            <CardFooter className="flex justify-between">
                <StatusBadge status={lodash.get(report, 'status')} />
                <div className='flex'>
                    <Button size="icon" variant="outline" className='m-1' onClick={(e) => { e.stopPropagation(); handleRedirectToReport(report) }}>
                        <Eye className='w-4 h-4 ' />
                    </Button>
                    <DeleteReportDialog report={report} setDialogStatus={setDeleteDialogStatus} isOpen={deleteDialogStatus} reportDeletedCb={handleRefreshReportList} />
                    <EditReportDialog report={report} setDialogStatus={setEditDialogStatus} isOpen={editDialogStatus} />
                </div>
            </CardFooter>
        </Card>
    )
}

export default ReportCard