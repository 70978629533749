import React from 'react';
import { Package } from 'lucide-react'; // Use appropriate icon
import { CardHeader, CardTitle, CardDescription } from "./ui/card"; // Adjust the import path as necessary

const CommonCardHeader = ({ icon: Icon, title, description }) => {
    return (
        <CardHeader className='text-left bg-muted flex flex-row items-center'>
            {Icon && <Icon className="h-8 w-8 transition-all group-hover:scale-110 mr-4" />}
            <div className='flex flex-col'>
                <CardTitle>{title}</CardTitle>
                <CardDescription>{description}</CardDescription>
            </div>
        </CardHeader>
    );
};

export default CommonCardHeader;