import { useState } from 'react';
import lodash from 'lodash';
import { Trash2 } from 'lucide-react';
import { Button } from "../../../components/ui/button";
import { Dialog, DialogHeader, DialogTitle, DialogContent, DialogFooter } from "../../../components/ui/dialog";
import {
    TooltipProvider,
    Tooltip,
    TooltipTrigger,
    TooltipContent,
} from "../../../components/ui/tooltip";
import { deleteKnowledgeBase } from '../../../services/api'; // Import the delete API

export const DeleteKnowledgeBaseDialog = ({ knowledgeBase, isOpen, setDialogStatus, onDeleteSuccess }) => {
    const [deleting, setDeleting] = useState(false);

    if (!isOpen) {
        return (

            <TooltipProvider>
                <Tooltip>
                    <TooltipTrigger asChild>
                        <Button
                            size="icon" variant="outline"
                            className="flex items-center justify-center text-4xl font-bold m-1"
                            onClick={(e) => { e.stopPropagation(); setDialogStatus(true); }}
                        >
                            <Trash2 className="h-4 w-4" />
                        </Button>
                    </TooltipTrigger>
                    <TooltipContent side="right">Delete</TooltipContent>
                </Tooltip>
            </TooltipProvider>
        )
    }

    const handleDelete = async () => {
        setDeleting(true);
        try {
            await deleteKnowledgeBase(lodash.get(knowledgeBase, ['id'])); // Call the delete API
            onDeleteSuccess(); // Callback to refresh the list or update state
        } catch (error) {
            console.error("Failed to delete knowledge base:", error);
        } finally {
            setDeleting(false);
            setDialogStatus(false);
        }
    };

    return (
        <Dialog open={isOpen} onOpenChange={() => setDialogStatus(false)} >
            <DialogHeader>
                <DialogTitle>
                    <Button
                        size="icon" variant="outline"
                        className="flex items-center justify-center text-4xl font-bold m-1"
                        onClick={(e) => { e.stopPropagation(); setDialogStatus(true); }}
                    >
                        <Trash2 className="h-4 w-4" />
                    </Button>
                </DialogTitle>
            </DialogHeader>
            <DialogContent className="max-w-[400px]">
                <div className="flex flex-col gap-2">
                    <p className='text-lg '>You will be deleting - <span className="font-bold">{lodash.get(knowledgeBase, ['title'])}</span></p>
                    <p className='text-sm'>Are you sure? This will delete all the research and entries created for this report.</p>
                </div>
                <DialogFooter>
                    <Button onClick={handleDelete} disabled={deleting}>
                        {deleting ? 'Deleting...' : 'Delete'}
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};