import React, { useEffect, useState } from 'react';
import { fetchUrlContent } from '../../../services/api';  // Import the fetchUrlContent function

const LinkPreview = ({ link }) => {
    const [content, setContent] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [useFallback, setUseFallback] = useState(false); // State to track if we should use fallback

    useEffect(() => {
        const getContent = async () => {
            setLoading(true); // Set loading to true at the start
            try {
                const fetchedContent = await fetchUrlContent(link);
                setContent(fetchedContent);
            } catch (err) {
                setError(err.message);
                setUseFallback(true); // Switch to fallback on error
            } finally {
                setLoading(false);
            }
        };

        if (link) { // Only call getContent if link is valid
            getContent();
        }
    }, [link]);

    const handleIframeError = () => {
        setUseFallback(true); // Switch to fallback if iframe fails
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error}</p>;

    return (
        <div>
            {useFallback ? (
                <div>
                    <p>Loading content from the server...</p>
                    <div dangerouslySetInnerHTML={{ __html: content }} />
                </div>
            ) : (
                <iframe
                    src={link}
                    style={{ width: '100%', height: '500px', border: 'none' }}
                    onError={handleIframeError} // Handle iframe load error
                    title="Link Preview"
                    sandbox="allow-same-origin allow-scripts" // Add sandbox for security
                />
            )}
        </div>
    );
};

export default LinkPreview;