import React from 'react';

const UploadPreview = () => {
    return (
        <div className='text-center flex flex-col items-center justify-center w-full h-full'>
            <h1 className="text-xl font-bold text-blue-500">🚀 Exciting Document Preview on the Way!</h1>
            <p className="text-sm text-gray-500">We are working on Document Preview feature! 🎉 Stay tuned! 📄✨</p>
        </div>
    );
};

export default UploadPreview;