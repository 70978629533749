import { useState, useEffect } from 'react';
import { Plus, LoaderCircle, ClipboardCheck } from 'lucide-react';
import { Input } from "../../../../components/ui/input";
import { Label } from "../../../../components/ui/label";
import { Separator } from '../../../../components/ui/separator';
import { Button } from '../../../../components/ui/button';
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from '../../../../components/ui/select'; // Import your Select component
import { Textarea } from '../../../../components/ui/textarea'; // Import the Textarea component
import {
    Dialog,
    DialogHeader, DialogTitle, DialogDescription, DialogFooter,
    DialogContent
} from "../../../../components/ui/dialog";
import PropTypes from 'prop-types';

function CommonCardHeader() {
    return (
        <DialogHeader className="flex flex-row items-center">
            <ClipboardCheck className="h-8 w-8 transition-all group-hover:scale-110 mr-2" />
            <div className='flex flex-col'>
                <DialogTitle>Add Report</DialogTitle>
                <DialogDescription>
                    Give a title and description for the report.
                </DialogDescription>
            </div>
        </DialogHeader>
    )
}

export default function AddReportDialog(props) {
    const { product, handleReportCreation, isOpen, setDialogStatus, creatingReport, renderAction } = props; // Accept product prop
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [selectedCategory, setSelectedCategory] = useState(""); // State for selected category
    const [categories, setCategories] = useState([]); // State for categories
    const [errors, setErrors] = useState({}); // State for form validation errors

    useEffect(() => {
        if (product && product.reports) {
            const groupedCategories = [...new Set(product.reports.map(report => report.category))]; // Extract unique categories
            setCategories(groupedCategories);
        }
    }, [product]);

    const validateForm = () => {
        const newErrors = {};
        if (!title.trim()) newErrors.title = "Title is required.";
        if (!description.trim()) newErrors.description = "Description is required.";
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0; // Return true if no errors
    };

    const handleSubmit = async () => {
        if (!validateForm()) return; // Validate form before submission
        const payload = {
            title,
            description,
            category: selectedCategory, // Include selected category in payload
        };
        handleReportCreation(payload);
    };

    if (!isOpen) {
        return (
            <>
                {renderAction ? renderAction() :
                    <Button
                        variant="outline"
                        size="lg"
                        className="flex items-center justify-center text-4xl font-bold"
                        onClick={() => setDialogStatus(true)}
                    >
                        <Plus className="h-8 w-8" />
                    </Button>
                }
            </>
        )
    }

    return (
        <Dialog open={isOpen} onOpenChange={() => setDialogStatus(false)}>
            <DialogContent className="sm:max-w-[425px]">
                <CommonCardHeader />
                <Separator />
                <div className="grid gap-4 py-2">
                    {product && (
                        <div className="flex flex-col">
                            <p className='bg-blue-100 p-4 rounded text-xs text-blue-900 font-semibold'>
                                This report will be created for product: <span className='font-bold'>{product.name}</span>
                            </p>
                        </div>
                    )}
                    <div className="flex flex-col items-start gap-2">
                        <Input
                            id="Title"
                            label="Title *"
                            value={title} disabled={creatingReport}
                            onChange={(e) => setTitle(e.target.value)}
                            className="col-span-3"
                        />
                    </div>
                    <div className="flex flex-col items-start gap-2">
                        <Textarea
                            label="Description *"
                            id="description"
                            value={description} disabled={creatingReport}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                    </div>
                    {product && product.reports && categories.length > 0 && ( // Only show dropdown if product is not null and has reports
                        <div className="flex flex-col items-start gap-2">
                            <Label htmlFor="category" className="text-right">
                                Category *
                            </Label>
                            <Select
                                id="category"
                                value={selectedCategory}
                                onValueChange={setSelectedCategory} // Use onValueChange for Select component
                            >
                                <SelectTrigger>
                                    <SelectValue placeholder="Select a category" />
                                </SelectTrigger>
                                <SelectContent>
                                    {categories.map((category, index) => (
                                        <SelectItem key={index} value={category}>
                                            {category}
                                        </SelectItem>
                                    ))}
                                </SelectContent>
                            </Select>
                        </div>
                    )}
                </div>
                <DialogFooter>
                    <Button disabled={creatingReport || !title.trim() || !description.trim()} type="button" onClick={handleSubmit}>
                        {creatingReport ? <LoaderCircle /> : 'Create Report'}
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}

AddReportDialog.propTypes = {
    product: PropTypes.object.isRequired,
    handleReportCreation: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    setDialogStatus: PropTypes.func.isRequired,
    creatingReport: PropTypes.bool.isRequired,
    renderAction: PropTypes.func,
};

AddReportDialog.defaultProps = {
    product: {},
    handleReportCreation: () => { },
    isOpen: false,
    setDialogStatus: () => { },
    creatingReport: false,
    renderAction: null,
};
