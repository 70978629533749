import React from 'react';
import { Button } from "../../../components/ui/button";
import { Alert } from "../../../components/ui/alert";

function AgentsList({ agents, onDelete, onUpdate }) {
    if (!agents || agents.length === 0) {
        return <p>No agents found.</p>;
    }

    return (
        <div className="overflow-x-auto">
            <table className="min-w-full bg-white">
                <thead>
                    <tr>
                        <th className="py-2 px-4 border-b">Name</th>
                        <th className="py-2 px-4 border-b">Email</th>
                        <th className="py-2 px-4 border-b">Description</th>
                        <th className="py-2 px-4 border-b">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {agents.map(agent => (
                        <tr key={agent.id}>
                            <td className="py-2 px-4 border-b">{agent.name}</td>
                            <td className="py-2 px-4 border-b">{agent.email}</td>
                            <td className="py-2 px-4 border-b">{agent.description}</td>
                            <td className="py-2 px-4 border-b">
                                {/* Implement Edit functionality as needed */}
                                <Button variant="destructive" size="sm" onClick={() => onDelete(agent.id)}>
                                    Delete
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default AgentsList;