import { MessageSquareMore } from 'lucide-react';
import { Button } from "./ui/button";
import {
    Dialog,
    DialogContent,
    DialogTrigger,
} from "./ui/dialog";
import { FeedbackForm } from "./feedback/FeedbackForm";

export function FeedbackDialog() {
    return (
        <Dialog className="w-screen max-w-4xl">
            <DialogTrigger asChild>
                <Button variant="ghost" size="icon" className="text-md flex h-8 w-8 items-center justify-center rounded-lg text-muted-foreground transition-colors hover:text-foreground md:h-8 md:w-8">
                    <MessageSquareMore className='h-4 w-4 transition-all group-hover:scale-110 ' />
                </Button>
            </DialogTrigger>
            <DialogContent className="">
                <FeedbackForm />
            </DialogContent>
        </Dialog>
    )
}
