import React from 'react';
import PropTypes from 'prop-types';
import lodash from 'lodash';

import {
    Tabs,
    TabsContent,
    TabsList,
    TabsTrigger,
} from "../../../components/ui/tabs";

import BlocknoteEditor from "../../../components/BlocknoteEditor";
import { Skeleton } from '../../../components/ui/skeleton';
import MindMapEditor from '../../../components/MindMapEditor';
import ChatInterface from '../../dashboard/products/components/ChatInterface';
import PreviewContent from './PreviewContent'; // Import the new PreviewContent component
import KnowledgeBaseDetails from './KnowledgeBaseDetails'; // Import the KnowledgeBaseDetails component
import SummaryEditor from '../../../components/SummaryEditor'; // Import the SummaryEditor component


export const KnowledgeBaseDisplay = ({ scope, knowledgeBase, loading, handleOnEditorUpdate }) => {

    if (loading) {
        return (
            <div className="flex h-full flex-col p-4 justify-start">
                <Skeleton className='w-[300px] h-[60px] my-1' />
                <Skeleton className='w-[400px] h-[40px] my-1' />
                <Skeleton className='w-full h-[40px] my-1' />
                <Skeleton className='w-full h-[20px] my-1' />
                <Skeleton className='w-full h-[20px] my-1' />
                <Skeleton className='w-full h-[20px] my-1 mb-8' />

                <Skeleton className='w-[300px] h-[60px] my-1' />
                <Skeleton className='w-[400px] h-[40px] my-1' />
                <Skeleton className='w-full h-[20px] my-1' />
                <Skeleton className='w-full h-[20px] my-1' />
                <Skeleton className='w-full h-[20px] my-1' />
            </div>
        )
    }

    return (
        <>
            <div className="flex h-full flex-col justify-start">
                {knowledgeBase ? (
                    <div
                        className="w-full h-full text-left"
                    // style={{ maxHeight: "calc(100vh - 264px)", overflowY: "auto" }}
                    >
                        <Tabs defaultValue="details" className='h-full w-full'>
                            <TabsList className="grid grid-cols-4 m-1"> {/* Updated to 4 columns */}
                                <TabsTrigger value="details">Details</TabsTrigger>
                                <TabsTrigger value="summary">Summary</TabsTrigger>
                                <TabsTrigger value="chat">Chat</TabsTrigger>
                                <TabsTrigger value="preview">Preview</TabsTrigger> {/* New tab for references */}
                            </TabsList>

                            <TabsContent value="details" className=' border rounded m-1 product-view_tab-content'>
                                <KnowledgeBaseDetails
                                    knowledgeBase={knowledgeBase}
                                    handleOnEditorUpdate={handleOnEditorUpdate}
                                /> {/* Use the new component */}
                            </TabsContent>

                            <TabsContent value="summary" className='pt-4 border rounded m-1 product-view_tab-content'>
                                <SummaryEditor
                                    knowledgeBase={knowledgeBase}
                                />
                            </TabsContent>

                            <TabsContent value="chat" className='h-full p-1 border rounded m-1 product-view_tab-content'>
                                <ChatInterface knowledgeBaseId={knowledgeBase.id} scope="knowledge_base_chat" />
                            </TabsContent>

                            <TabsContent value="preview" className='h-full p-1 border rounded m-1 product-view_tab-content'>
                                <PreviewContent knowledgeBase={knowledgeBase} />
                            </TabsContent>
                        </Tabs>
                    </div>
                ) : null}
            </div>
        </>
    )
}

KnowledgeBaseDisplay.propTypes = {
    knowledgeBase: PropTypes.object,
    loading: PropTypes.bool.isRequired,
};

KnowledgeBaseDisplay.defaultProps = {
    knowledgeBase: null,
};