
import React, { useState, useEffect } from 'react';
import lodash from 'lodash';
import { useRecoilState } from 'recoil';
import { Search, ClipboardCheck, Plus } from 'lucide-react';
import { useHistory } from 'react-router-dom';
// Custom components
import {
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    CardTitle,
} from "../../../../components/ui/card";
import { Separator } from "../../../../components/ui/separator";
import { Button } from "../../../../components/ui/button";
import { Input } from "../../../../components/ui/input";
import AddReportDialog from "./AddReportDialog";
import WordFadeIn from "../../../../components/WordFadeIn";
// Services
import { createReport, getReports } from '../../../../services/api';
import ReportCard from './ReportCard';
import { SkeletonGrid } from '../../../../components/SkeletonGrid';
// Store
import { reportsState } from '../../../../store/store';

function CommonCardHeader() {
    return (
        <CardHeader className='text-left bg-muted flex flex-row items-center'>
            <ClipboardCheck className="h-8 w-8 transition-all group-hover:scale-110 mr-4" />
            <div className='flex flex-col'>
                <CardTitle>Reports</CardTitle>
                <CardDescription>Manage your reports.</CardDescription>
            </div>
        </CardHeader>
    )
}

export default function ReportsListGrid(props) {
    const { setReportsView, setActiveReport } = props;

    const history = useHistory();

    const [reports, setReports] = useRecoilState(reportsState);

    const [searchTerm, setSearchTerm] = useState('');
    const [dialogStatus, setDialogStatus] = useState(false);

    const [loadingReports, setLoadingReports] = useState(false);
    const [creatingReport, setCreatingReport] = useState(false);

    const _getReports = async () => {
        try {
            setLoadingReports(true);
            const response = await getReports();
            // Filter reports with product_id
            const reportsWithProduct = lodash.get(response, ['data', 'data', 'reports'], []).filter(report => !report.product_id);
            setReports(reportsWithProduct);
        } catch (error) {
            // Handle the error here
        } finally {
            setLoadingReports(false);
        }
    }

    useEffect(() => {
        (async () => {
            await _getReports();
        })()
    }, []);


    const handleReportCreation = async (payload) => {
        try {
            setCreatingReport(true);
            const response = await createReport({ payload });
            const report = response.data.data;
            // Handle the response
            handleRedirectToReport(report);
        } catch (error) {
            // Handle the error
            console.error("An error occurred:", error);
        } finally {
            setDialogStatus(false);
        }
    }

    const handleRedirectToReport = (report) => {
        setReportsView('REPORT_VIEW');
        setActiveReport(report);
        history.push(`/dashboard/reports/${report.id}`);
    }


    if (loadingReports && reports.length === 0) {
        return (
            <Card className='h-full'>
                <CommonCardHeader />
                <CardContent className='p-4 w-full h-full'>
                    <div className="flex flex-col items-center gap-1 text-center ">
                        <h3 className="text-2xl font-bold tracking-tight py-4 text-accent">Loading Reports...</h3>
                    </div>
                    <SkeletonGrid />
                </CardContent>
            </Card>
        )
    }


    // Handling when there are no reports
    if (reports.length === 0) {
        return (
            <Card className='h-full bg-accent'>
                <CardContent className='p-4 w-full h-full flex-col items-center justify-center'>
                    <div className='flex items-center justify-center my-8'>
                        <ClipboardCheck className="h-20 w-20 transition-all group-hover:scale-110 mr-2 text-blue-900" />
                        <p className='text-6xl font-bold'>Reports</p>
                    </div>
                    <div className='mt-4 w-full flex justify-center'>
                        <div className='w-1/2'>
                            <WordFadeIn
                                className="text-4xl font-bold mb-4"
                                words="How does it work?"
                            />
                            <div className='flex flex-col items-center justify-center'>
                                <div className='rounded-xl p-4 my-2  bg-background text-left flex items-center justify-between cursor-pointer hover:shadow-2xl transition-all' onClick={() => setDialogStatus(true)}>
                                    <div className='flex flex-col w-3/4'>
                                        <p className='text-2xl font-bold'>Add Report</p>
                                        <p className='text-md'>First, You will have to add a product, you will be asked for the Product name and description. <span className='font-bold text-blue-900'>Click this card to add a report.</span></p>
                                    </div>
                                    <Button variant='ghost' >
                                        <Plus className='h-12 w-12 text-blue-900' />
                                    </Button>
                                </div>

                                <div className='rounded-xl p-4 my-2  bg-background text-left w-full'>
                                    <p className='text-2xl font-bold'>Generate Reports</p>
                                    <p className='text-md'>Based on the title, description you provide, we will generate report for the topic.</p>
                                </div>
                                {dialogStatus ? <AddReportDialog handleReportCreation={handleReportCreation} creatingReport={creatingReport} isOpen={dialogStatus} setDialogStatus={setDialogStatus} /> : null}

                            </div>
                        </div>
                    </div>
                </CardContent>
            </Card>
        )
    }

    const filteredReports = (reports || []).filter(item => {
        if (searchTerm) {
            return item.title.toLowerCase().includes(searchTerm.toLowerCase())
        }
        return item
    });


    return (
        <Card className='h-full'>
            <CommonCardHeader />
            <Separator />
            <CardContent className='p-4'>
                <div className='flex flex-col'>
                    {loadingReports ? <div className='p-2 rounded my-1 bg-accent text-left'><p className='text-md font-semibold'>Refreshing Reports...</p></div> : null}
                    <form className='w-full mr-2'>
                        <div className='relative'>
                            <Search className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
                            <Input
                                type="search"
                                placeholder="Search Reports..."
                                className="mb-2 pl-8"
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </div>
                    </form>
                    <section className=" grid gap-8 md:grid-cols-2 lg:grid-cols-3  lg:gap-2 cursor-pointer" style={{ maxHeight: 'calc(100vh - 284px)', overflowY: 'auto' }}>
                        <div className="bg-white rounded-xl border border-gray-200 dark:bg-gray-950 dark:border-gray-800 flex items-center justify-center">
                            <AddReportDialog handleReportCreation={handleReportCreation} creatingReport={creatingReport} isOpen={dialogStatus} setDialogStatus={setDialogStatus} />
                        </div>
                        {(filteredReports || []).map(report => (
                            <ReportCard key={lodash.get(report, ['id'])} report={report} handleRedirectToReport={handleRedirectToReport} handleRefreshReportList={async () => await _getReports()} />
                        ))}
                    </section>
                </div>
            </CardContent>
        </Card>

    )
}