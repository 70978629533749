import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

// Custom Components
import HeroArea from './components/HeroArea';
import PASSection from './components/PASSection';
import FeatureSection from './components/FeatureSection';
import HomeFooter from './components/HomeFooter';
import HomeNavbar from "./components/HomeNavbar";
import WaitlistBanner from "./components/WaitlistBanner";
// Services
import { useTheme } from "../../lib/theme-provider"


function RootPage() {
  const { setTheme } = useTheme()

  useEffect(() => {
    // This is a hack, we should always show the landing page website in light mode
    setTheme('light');
    window.location.href = process.env.REACT_WEBSITE_BASE_URL || "/dashboard/analytics";
  }, [])

  return (
    <>
      {/* <Helmet>
        <meta property="title" content="ProductAgent" />
        <meta property="og:title" content="ProductAgent" />

        <meta property="description" content="Simplify product research with automated AI reasearch in minutes." />
        <meta property="og:description" content="Simplify product research with automated AI reasearch in minutes." />
        <meta property="og:image" content={`https://www.productagent.io/og-image.png`} />
        <meta property="og:url" content="https://www.productagent.io" />
        <meta property="og:type" content="website" />
      </Helmet>
      <HomeNavbar />
      <WaitlistBanner />
      <HeroArea />
      <PASSection />
      <FeatureSection />
      <HomeFooter /> */}
    </>
  )
}

export default RootPage