import React from 'react';
import {
    Loader,
} from 'lucide-react';
import lodash from 'lodash';
import Lottie from 'react-lottie';
// Cusomt components
import { Button } from '../../../../components/ui/button';
import animationData from '../../../../lotties/search_lottie_animation.json';

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
    },
};

function NoReportGeneratedView({ selectedReport, sanitizedLogs, startResearching, handleGenerateReport, selectedReportVersion }) {
    if ((lodash.get(selectedReport, ['content'], [])).length === 0 && ['pending'].includes(lodash.get(selectedReport, ['status']))) {
        return (
            <div className="flex flex-col h-full overflow-y-auto items-center justify-start">
                <div className='flex flex-col items-center justify-center h-full'>
                    <h3 className="text-2xl font-bold">
                        No report generated yet.
                    </h3>
                    <p className="text-muted-foreground">Please click Generate Report to start the research. This may take few minutes.</p>
                    <Button onClick={() => handleGenerateReport(selectedReport)} className="mt-4" variant="default" disabled={lodash.get(startResearching, ['id']) === lodash.get(selectedReport, ['id'])}>
                        {lodash.get(startResearching, ['id']) === lodash.get(selectedReport, ['id']) ? <Loader className='h-4 w-4 mx-2 animate-spin' /> : null}
                        Generate Report
                    </Button>
                </div>
            </div>
        )
    }
    return null
}

function ResearchingReportView({ selectedReport, startResearching, selectedReportVersion }) {
    if (['in_progress', 'queued'].includes(lodash.get(selectedReport, ['content', selectedReportVersion, 'status'])) || ['queued'].includes(lodash.get(selectedReport, ['status']))) {
        return (
            <div className="flex flex-col h-full overflow-y-auto items-center justify-start">
                <div className='flex flex-col items-center justify-center h-full'>
                    <Lottie
                        options={defaultOptions}
                        height={240}
                        width={320}
                        style={{ margin: 0 }}
                    />
                    <h3 className="text-2xl font-bold">
                        Research in progress...
                    </h3>
                    <p className="text-muted-foreground">Please wait while we are working on this research... This may take a few minutes...</p>
                </div>
            </div>
        );
    }
    return null;
}

export const GenerateReportView = (props) => {
    return (
        <>
            <NoReportGeneratedView {...props} />
            <ResearchingReportView {...props} />
        </>
    )
}

export default GenerateReportView