import React, { useState, useEffect } from 'react';
import lodash from 'lodash';
import { useRecoilState } from 'recoil';
import { useHistory, useParams } from 'react-router-dom'; // Import useParams
import { Search, Package, Plus } from 'lucide-react';
import {
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    CardTitle,
} from "../../../../components/ui/card";
import { Separator } from "../../../../components/ui/separator";
import { Input } from "../../../../components/ui/input";
import { Button } from "../../../../components/ui/button";
import AddProductDialog from "./AddProductDialog";
import ProductCard from './ProductCard';
import { SkeletonGrid } from '../../../../components/SkeletonGrid';
import WordFadeIn from '../../../../components/WordFadeIn';
// Services
import { createProduct, getProducts } from '../../../../services/api';
import { logError } from '../../../../services/logging_utils';
// State
import { productsState } from '../../../../store/store';

function CommonCardHeader() {
    return (
        <CardHeader className='text-left bg-muted flex flex-row items-center'>
            <Package className="h-8 w-8 transition-all group-hover:scale-110 mr-4" />
            <div className='flex flex-col'>
                <CardTitle>Products</CardTitle>
                <CardDescription>Manage your products.</CardDescription>
            </div>
        </CardHeader>
    )
}

export default function ProductListGrid({ setProductsView, setActiveProduct }) {
    const history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    const product_id = queryParams.get('product_id');
    console.log(product_id);

    const [products, setProducts] = useRecoilState(productsState);

    const [searchTerm, setSearchTerm] = useState('');
    const [addDialogStatus, setAddDialogStatus] = useState(false);

    const [loadingProducts, setLoadingProducts] = useState(false);
    const [creatingProduct, setCreatingProduct] = useState(false);

    const handleRedirectToProductReportsPreview = (product) => {
        setProductsView('REPORT_VIEW');
        setActiveProduct(product);
        history.push(`/dashboard/products?product_id${product.id}`);
    }

    const _getProducts = async () => {
        try {
            setLoadingProducts(true);
            const response = await getProducts();
            setProducts(lodash.get(response, ['data', 'data'], []));
        } catch (error) {
            // Handle the error here
            logError(error);
        } finally {
            setLoadingProducts(false);
        }
    }

    useEffect(() => {
        (async () => {
            await _getProducts();
        })()
    }, []); // Add productId and products as dependencies

    useEffect(() => {
        if (product_id) { // Check if productId exists
            const foundProduct = products.find(product => product.id === product_id);
            if (foundProduct) {
                handleRedirectToProduct(foundProduct); // Redirect if product is found
            }
        }
    }, [product_id, products])

    if (loadingProducts && products.length === 0) {
        return (
            <Card className='h-full'>
                <CommonCardHeader />
                <CardContent className='p-4 w-full h-full'>
                    <div className="flex flex-col items-center gap-1 text-center ">
                        <h3 className="text-2xl font-bold tracking-tight py-4 text-accent">Loading Products...</h3>
                    </div>
                    <SkeletonGrid />
                </CardContent>
            </Card>
        )
    }



    const handleProductCreation = async (payload) => {
        try {
            setCreatingProduct(true);
            const response = await createProduct({ payload });
            const product = lodash.get(response, ['data', 'data', 'product']);

            handleRedirectToProductReportsPreview(product);
        } catch (error) {
            // Handle the error
            console.error("An error occurred:", error);
        } finally {
            setCreatingProduct(false);
        }
    }

    // Handling when there are no products
    if (products.length === 0) {
        return (
            <Card className='h-full bg-accent'>
                <CardContent className='p-4 w-full h-full flex-col items-center justify-center'>
                    <div className='flex items-center justify-center my-8'>
                        <Package className="h-20 w-20 transition-all group-hover:scale-110 mr-2 text-blue-900" />
                        <p className='text-6xl font-bold'>Products</p>
                    </div>
                    <div className='mt-4 w-full flex justify-center'>
                        <div className='w-1/2'>
                            <WordFadeIn
                                className="text-4xl font-bold mb-4"
                                words="How does it work?"
                            />
                            <div className='flex flex-col items-center justify-center'>
                                <div className='rounded-xl p-4 my-2  bg-background text-left flex items-center justify-between cursor-pointer hover:shadow-2xl transition-all' onClick={() => setAddDialogStatus(true)}>
                                    <div className='flex flex-col w-3/4'>
                                        <p className='text-2xl font-bold'>Add Product</p>
                                        <p className='text-md'>First, You will have to add a product, you will be asked for the Product name and description. <span className='font-bold text-blue-900'>Click this card to add a product.</span></p>
                                    </div>
                                    <Button variant='ghost' >
                                        <Plus className='h-12 w-12 text-blue-900' />
                                    </Button>
                                </div>

                                <div className='rounded-xl p-4 my-2  bg-background text-left'>
                                    <p className='text-2xl font-bold'>Answer Questionaires</p>
                                    <p className='text-md'>Based on the title and description you provided, there will be few questions that you will need to answer before we can begin our research.</p>
                                </div>

                                <div className='rounded-xl p-4 my-2  bg-background text-left'>
                                    <p className='text-2xl font-bold'>Generate Reports</p>
                                    <p className='text-md'>Based on the title, description and the answers you provide, we will generate reports that we will need to start the product research.</p>
                                </div>
                                {addDialogStatus ? <AddProductDialog creatingProduct={creatingProduct} handleProductCreation={handleProductCreation} setDialogStatus={setAddDialogStatus} isOpen={addDialogStatus} /> : null}

                            </div>
                        </div>
                    </div>
                </CardContent>
            </Card>
        )
    }

    const handleRedirectToProduct = (product) => {
        setActiveProduct(product);
        history.push(`/dashboard/products?product_id=${product.id}`);

        setProductsView('REPORT_VIEW');
    }

    const filteredProducts = (products || []).filter(item =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <Card className='h-full'>
            <CommonCardHeader />
            <Separator />
            <CardContent className='p-4'>
                <div className='flex flex-col'>
                    {loadingProducts ? <div className='p-2 rounded my-1 bg-accent text-left'><p className='text-md font-semibold'>Refreshing Products...</p></div> : null}
                    <form className='w-full mr-2'>
                        <div className='relative'>
                            <Search className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
                            <Input
                                type="search"
                                placeholder="Search Products..."
                                className="  mb-2 pl-8"
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </div>
                    </form>
                    <section className="grid gap-8  md:grid-cols-2 lg:grid-cols-3  lg:gap-2 cursor-pointer">
                        <div className="bg-white rounded-xl border border-gray-200 dark:bg-gray-950 dark:border-gray-800 flex items-center justify-center">
                            <AddProductDialog creatingProduct={creatingProduct} handleProductCreation={handleProductCreation} setDialogStatus={setAddDialogStatus} isOpen={addDialogStatus} />
                        </div>
                        {(filteredProducts || []).map((product, index) => (
                            <ProductCard
                                key={index}
                                product={product}
                                handleRedirectToProduct={handleRedirectToProduct}
                                handleRefreshProductList={async () => await _getProducts()}
                            />
                        ))}
                    </section>
                </div>
            </CardContent>
        </Card>
    )
}