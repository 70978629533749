import React, { useEffect, useState } from 'react';
import { streamFile } from '../../../services/api';
import LinkPreview from './LinkPreview';
import PDFPreview from './PDFPreview';
import UploadPreview from './UploadPreview';

const PreviewContent = ({ knowledgeBase }) => {
    const { type, link } = knowledgeBase;
    const [fileUrl, setFileUrl] = useState('');
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    useEffect(() => {
        if (type === 'upload') {
            const fileKey = link.split('/').pop();
            // streamFile(fileKey)
            //     .then(response => {
            //         const url = URL.createObjectURL(new Blob([response.data]));
            //         setFileUrl(url);
            //     })
            //     .catch(error => {
            //         console.error('Error streaming file:', error);
            //     });
        }
    }, [type, link]);

    if (type === 'link') {
        return <LinkPreview link={link} />;
    }

    if (type === 'upload') {
        // const fileExtension = link.split('.').pop();
        // if (fileExtension === 'pdf') {
        //     return (
        //         <PDFPreview
        //             fileUrl={fileUrl}
        //             pageNumber={pageNumber}
        //             setPageNumber={setPageNumber}
        //             numPages={numPages}
        //             setNumPages={setNumPages}
        //         />
        //     );
        // } else if (fileExtension === 'pptx' || fileExtension === 'docx') {
        //     return <UploadPreview fileUrl={fileUrl} />;
        // }
        return <UploadPreview fileUrl={fileUrl} />;
    }

    return <p>No preview available for this type.</p>;
};

export default PreviewContent;