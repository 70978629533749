import React from 'react';
import KnowledgeBaseSection from './KnowledgeBaseSection'; // Ensure the correct relative path
import { Card, CardContent } from "../../../components/ui/card"; // Adjust the import path as necessary
import { BookOpen } from 'lucide-react'; // Import the Knowledge Base icon
import { Separator } from "../../../components/ui/separator"; // Import the Separator component
import CommonCardHeader from '../../../components/CommonCardHeader';

export default function KnowledgeBaseView() {
    return (
        <Card className='h-full w-full m-2'>
            <CommonCardHeader
                icon={BookOpen} // Use the appropriate icon
                title="Knowledge Base"
                description="Manage your knowledge base entries."
            />
            <Separator />
            <CardContent className=''>
                <KnowledgeBaseSection />
            </CardContent>
        </Card>
    );
}