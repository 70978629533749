import { removeUser, removeToken } from '../../services/utils';
import {
    logoutUser
} from '../../services/api';

export const logout = async () => {
    try {
        await logoutUser();
        // Reload the user if the logout happens
        // Redirect the user to the Home page

        // Clear the local storage for User and token
        removeToken();
        removeUser();

        window.location.href = process.env.REACT_WEBSITE_BASE_URL || "/";
    } catch (error) {
        // Log the error here, e.g. send it to a logging service
    }
};