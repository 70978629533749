import React, { useState, useCallback } from 'react';
import { X, Loader } from 'lucide-react'; // Import the Upload and X icons from Lucide

export const UploadComponent = ({ onChange, disabled, loading, error }) => { // Added error prop
    const [file, setFile] = useState(null);
    const [message, setMessage] = useState('');

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        setFile(selectedFile);
        if (onChange) onChange(selectedFile); // Call onChange if provided
    };

    const handleDrop = useCallback((event) => {
        event.preventDefault();
        const droppedFile = event.dataTransfer.files[0];
        if (droppedFile) {
            setFile(droppedFile);
            setMessage('');
        }
    }, []);

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const handleDropAreaClick = () => {
        document.getElementById('fileInput').click(); // Trigger the file input click
    };

    const handleRemoveFile = () => {
        setFile(null); // Clear the selected file
    };

    return (
        <div className="upload-container">
            <div
                className="drop-area"
                onDrop={handleDrop}
                onDragOver={handleDragOver}
                onClick={handleDropAreaClick} // Add click handler to drop area
            >
                {file ? (
                    <div className="flex justify-between items-center w-full">
                        <p>{file.name}</p>
                        <button onClick={handleRemoveFile} className="text-red-500">
                            <X className="ml-2" />
                        </button>
                    </div>
                ) : (
                    <div className='flex flex-col'>
                        <p>Drag and drop your file here, or click the area to select</p>
                        <p className="file-format-message">
                            Accepted formats: .jpg, .png, .pdf, .docx, .txt
                        </p>
                    </div>
                )}
            </div>
            <input
                type="file"
                onChange={handleFileChange}
                style={{ display: 'none' }}
                id="fileInput"
                disabled={disabled || loading} // Disable input based on the prop and upload state
            />
            {loading && (
                <div className="loader text-left font-bold text-blue-900 flex items-center ">
                    <Loader className=' w-4 h-4 mr-2 animate-spin' />
                    <p className='text-sm'>Uploading...</p>
                </div>
            )} {/* Show uploading progress */}
            {error && <p className="text-red-500 text-sm italic">{error}</p>} {/* Show error message */}
        </div>
    );
};
