import React, { useState } from 'react';
import PropTypes from 'prop-types'; // Import PropTypes
import lodash from 'lodash';
// Custom components
import { Separator } from '../../../components/ui/separator';
import { Badge } from '../../../components/ui/badge';
import { Skeleton } from '../../../components/ui/skeleton';
import { KnowledgeBaseStatusBadge } from './KnowledgeBaseStatusBadge';
import { DetailsDialog } from '../../../components/DetailsDialog';
import { DeleteKnowledgeBaseDialog } from './DeleteKnowledgeBaseDialog'; // Import the delete dialog
import { EditKnowledgeBaseDialog } from './EditKnowledgeBaseDialog'; // Import the edit dialog

export const KnowledgeBaseHeader = ({ knowledgeBase, loading, handleOnEditorUpdate }) => {

    const [deleteDialogStatus, setDeleteDialogStatus] = useState(false); // State for delete dialog
    const [editDialogStatus, setEditDialogStatus] = useState(false); // State for edit dialog

    const handleDeleteSuccess = () => {
        setDeleteDialogStatus(false); // Close the delete dialog
        handleOnEditorUpdate();
    };

    const handleEditSuccess = () => {
        // Handle edit success (e.g., refresh the list or update state)
        setEditDialogStatus(false); // Close the edit dialog
    };

    if (loading) {
        return (
            <div className="flex flex-col text-left w-full">
                <div className="flex  w-full items-center px-2 py-1 justify-between">
                    <div className="flex flex-col items-start p-2 w-3/4">
                        <div className='flex w-full justify-start'>
                            <Skeleton className='w-[200px] my-1 h-[20px]' />
                        </div>
                        <Skeleton className='w-[300px] h-[15px]' />
                    </div>
                </div>
                <Separator />
            </div>
        )
    }

    const defaultDescription = 'No description was added for this knowledge base.';

    return (
        <div className="flex flex-col text-left w-full">
            <div className="flex  w-full items-center px-2 py-1 justify-between">
                <div className="flex flex-col items-start p-2 w-3/4">
                    <div className='flex w-full justify-start items-center'>
                        <h2 className="text-xl font-semibold text-ellipsis max-w-lg text-primary mr-2">{lodash.get(knowledgeBase, ['title'])}</h2>
                        <KnowledgeBaseStatusBadge status={lodash.get(knowledgeBase, ['status'])} />
                        <DetailsDialog heading={'Report Details'} title={lodash.get(knowledgeBase, ['title'])} description={lodash.get(knowledgeBase, ['description']) === '' ? defaultDescription : lodash.get(knowledgeBase, ['description'])} />
                    </div>
                    <p className="text-xs w-full text-ellipsis max-w-2xl">{lodash.get(knowledgeBase, ['description']) === '' ? defaultDescription : lodash.get(knowledgeBase, ['description'])}</p>
                </div>
                <div className='flex flex-row items-center'>
                    <DeleteKnowledgeBaseDialog
                        knowledgeBase={knowledgeBase}
                        isOpen={deleteDialogStatus}
                        setDialogStatus={setDeleteDialogStatus}
                        onDeleteSuccess={handleDeleteSuccess}
                    />
                    <EditKnowledgeBaseDialog
                        knowledgeBase={knowledgeBase}
                        isOpen={editDialogStatus}
                        setDialogStatus={setEditDialogStatus}
                        onUpdateSuccess={handleEditSuccess}
                    />
                </div>
            </div>
            <Separator />
        </div>
    )
}

KnowledgeBaseHeader.propTypes = {
    report: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    setSelectedReportVersion: PropTypes.func.isRequired,
    selectedReportVersion: PropTypes.number.isRequired,
    sanitizedLogs: PropTypes.array.isRequired,
    handleRefreshReport: PropTypes.func,
    startResearching: PropTypes.object,
};
