import React, { useState, useRef, useEffect } from 'react';
import { Markmap } from 'markmap-view';
import { transformer } from './markmap';
import { Toolbar } from 'markmap-toolbar';
import 'markmap-toolbar/dist/style.css';

const initValue = `# markmap

- beautiful
- useful
- easy
- interactive
`;

function renderToolbar(mm, wrapper) {
    while (wrapper?.firstChild) wrapper.firstChild.remove();
    if (mm && wrapper) {
        const toolbar = new Toolbar();
        toolbar.attach(mm);
        // Register custom buttons
        // toolbar.register({
        //     id: 'alert',
        //     title: 'Click to show an alert',
        //     content: 'Alert',
        //     onClick: () => alert('You made it!'),
        // });
        toolbar.setItems([...Toolbar.defaultItems, 'alert']);
        wrapper.append(toolbar.render());
    }
}

export default function MindMapEditor({ content }) {
    const [value, setValue] = useState(content);
    // Ref for SVG element
    const refSvg = useRef();
    // Ref for markmap object
    const refMm = useRef();
    // Ref for toolbar wrapper
    const refToolbar = useRef();

    useEffect(() => {
        // Create markmap and save to refMm
        if (refMm.current) return;
        const mm = Markmap.create(refSvg.current);
        console.log('create', refSvg.current);
        refMm.current = mm;
        renderToolbar(refMm.current, refToolbar.current);
    }, [refSvg.current]);

    useEffect(() => {
        // Update data for markmap once value is changed
        const mm = refMm.current;
        if (!mm) return;
        const { root } = transformer.transform(value);
        mm.setData(root);
        mm.fit();
    }, [refMm.current, value]);

    const handleChange = (e) => {
        setValue(e.target.value);
    };

    return (
        <React.Fragment>
            <div className='relative w-full h-full m-2 rounded-lg '>
                <svg className="flex-1 w-full h-full " ref={refSvg} />
                <div className="absolute top-1 left-1 bg-blue-100" ref={refToolbar}></div>
            </div>
        </React.Fragment>
    );
}
