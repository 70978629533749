import { useState } from 'react';
import lodash from 'lodash';
import { Trash2, LoaderCircle } from 'lucide-react';
import { Button } from "../../../../components/ui/button";
// Custom components
import {
    Dialog,
    DialogHeader, DialogTitle, DialogFooter,
    DialogContent,
} from "../../../../components/ui/dialog";
import {
    TooltipProvider,
    Tooltip,
    TooltipTrigger,
    TooltipContent,
} from "../../../../components/ui/tooltip";
// Services
import { deleteProduct } from '../.././../../services/api';


export const DeleteProductDialog = ({ product, isOpen, setDialogStatus, productDeletedCb }) => {
    const [deletingProduct, setDeletingProduct] = useState(false);

    const handleSubmit = async () => {

        try {
            setDeletingProduct(true);

            const response = await deleteProduct(lodash.get(product, ['id']));
            const code = lodash.get(response, ['data', 'code']);
            if (code === 200) {
                setDialogStatus(false);
                productDeletedCb(true);
            }

        } catch (error) {
            console.log(error);
        } finally {
            setDeletingProduct(false);
        }
    };

    if (!isOpen) {
        return (
            <TooltipProvider>
                <Tooltip>
                    <TooltipTrigger asChild>
                        <Button
                            variant="secondary"
                            size="sm"
                            className="flex items-center justify-center text-4xl font-bold m-1"
                            onClick={() => setDialogStatus(true)}
                        >
                            <Trash2 className="h-4 w-4" />
                        </Button>
                    </TooltipTrigger>
                    <TooltipContent side="right">Delete</TooltipContent>
                </Tooltip>
            </TooltipProvider>
        )
    }

    return (
        <Dialog open={isOpen}>
            <DialogHeader>
                <DialogTitle>Delete Product</DialogTitle>
            </DialogHeader>
            <DialogContent className="sm:max-w-[425px]">
                <div className="grid gap-4 py-4">
                    <div className="flex flex-col gap-2">
                        <p className='text-lg '>You will be deleting <span className="font-bold">{lodash.get(product, ['name'])}</span></p>
                        <p className='text-sm'><span className="font-bold">Are you sure? </span> This will delete all the report and research for this product.</p>
                    </div>
                </div>

                <DialogFooter>
                    <Button type="button" variant="secondary" onClick={() => setDialogStatus(false)}>
                        Close
                    </Button>
                    <Button type="submit" onClick={handleSubmit}>
                        {deletingProduct ? <LoaderCircle /> : 'Delete Product'}
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}
