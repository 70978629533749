import React, { useState } from 'react'; // Import useState
import lodash from 'lodash';
import { LoaderCircle, Plus } from 'lucide-react';
import { cn } from "../../../../lib/utils";
// Custom components
import { Separator } from "../../../../components/ui/separator";
import { Skeleton } from "../../../../components/ui/skeleton";
import { Button } from "../../../../components/ui/button"; // Import the Button
import AddReportDialog from '../../../dashboard/reports/components/AddReportDialog'; // Import the AddReportDialog
// Services
import { createReport, } from '../../../../services/api';


export function ProductChatViewReportsList(props) {
  const { links, product, isCollapsed, loadingReport, selectedReport, handleSelectReport, loadingProduct, handleReportAdded } = props;

  const [isDialogOpen, setDialogOpen] = useState(false); // State to manage dialog visibility
  const [creatingReport, setCreatingReport] = useState(false)

  const handleReportCreation = async (payload) => {
    try {
      setCreatingReport(true);
      const response = await createReport({ payload: { ...payload, product_id: lodash.get(product, ['id']) } });
      const report = lodash.get(response, ['data', 'data']);

      // Update product here, calling the Parent callback to update the product in parent component
      // This will update the product in the parent component and then pass it down the component chains
      await handleReportAdded(report, 'created');
    } catch (error) {
      // Handle the error
      console.error("An error occurred:", error);
    } finally {
      setDialogOpen(false);
      setCreatingReport(false);
    }
  }
  const groupedreports = lodash.groupBy(links, (item) => lodash.get(item, ['category']));

  const renderLoadingSkeleton = () => {
    return (
      <div className="flex flex-col gap-4 py-2">
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item) => (
          <div key={item} className="flex flex-col gap-2 p-2 rounded-lg border bg-background mx-2">
            <div className="flex items-center justify-between">
              <div className="font-bold flex flex-col items-start">
                <Skeleton className='w-[110px] h-[20px] my-1 rounded-md' />
                <Skeleton className='w-[130px] h-[20px] rounded-md' />
              </div>
            </div>
            <div className="line-clamp-2 text-xs text-muted-foreground">
              <Skeleton className='w-[200px] h-[15px] rounded-md' />
            </div>
          </div>
        ))}
      </div>
    )
  }

  const renderReports = Object.entries(groupedreports).map(([categoryId, reports]) => (
    <div key={categoryId} className="mx-2">
      <p className='text-xl text-left font-bold my-2 ml-2'>{categoryId}</p>
      <Separator className='my-1' />
      {(reports || []).map((report) => (
        <button
          key={report.id}
          className={cn(
            "flex flex-col items-start rounded-lg border p-2 my-2 text-left text-sm transition-all w-full",
            selectedReport && selectedReport.id === report.id ? "bg-primary text-white" : "bg-background text-foreground"
          )}
          variant='outline'
          onClick={() => handleSelectReport(report, 0)}
        >
          <div className="flex w-full flex-col p-2">
            <div className="flex items-center ">
              <div className="font-bold flex items-center">
                {lodash.get(report, ['title'])}
                {lodash.get(loadingReport, ['id']) === lodash.get(report, ['id']) ? <LoaderCircle className="h-4 w-4 ml-2 animate-spin" /> : null}
              </div>
            </div>
            {/* <StatusBadge status={lodash.get(report, ['status'])} /> */}
            <div className="text-xs font-medium text-ellipsis">{lodash.get(report, ['description'])}</div>
          </div>
          <div className="line-clamp-2 text-xs text-muted-foreground">
          </div>
        </button>
      ))
      }
    </div>
  ));

  return (
    <div className='relative'>
      <div
        data-collapsed={isCollapsed}
        className="relative group flex flex-col gap-4 py-2 data-[collapsed=true]:py-2 overflow-y-auto product-view_tab-content"
      >
        {loadingProduct ? renderLoadingSkeleton() : renderReports}
      </div>
      <AddReportDialog
        product={product}
        isOpen={isDialogOpen}
        setDialogStatus={setDialogOpen}
        handleReportCreation={handleReportCreation} // Pass the handler
        creatingReport={creatingReport} // Replace with actual loading state if needed
        renderAction={() => (
          <div className="absolute bottom-4 right-4"> {/* Positioning the button absolutely */}
            <Button size='md' onClick={() => setDialogOpen(true)} className="rounded-lg p-2" disabled={creatingReport}>
              {creatingReport ? <LoaderCircle className="h-6 w-6" /> : <Plus className="h-6 w-6" />} Add Report
            </Button>
          </div>
        )}
      />
    </div>
  );
}

