import React, { useState, useEffect, useRef } from 'react';
import lodash from 'lodash';
import { Send } from 'lucide-react';
import { Textarea } from '../../../../components/ui/textarea';
import { Button } from '../../../../components/ui/button';
import { sendChatMessage, connectProductChats, connectUserChats, connectKnowledgeBaseChats } from '../../../../services/api'; // Updated import
import { Avatar, AvatarImage, AvatarFallback } from '../../../../components/ui/avatar';
import ErrorBoundary from '../../../../components/ui/ErrorBoundary';
import { useRecoilState } from 'recoil';
import { userState } from '../../../../store/store';
import logo192 from '../../../../images/logo192.png';

const ChatInterface = ({ scope, userId, productId, knowledgeBaseId }) => {
    const [message, setMessage] = useState('');
    const [messages, setMessages] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [sending, setSending] = useState(false);
    const [chat, setChat] = useState(null);
    const chatWindowRef = useRef(null);
    const [user] = useRecoilState(userState);
    const [contentHeight, setContentHeight] = useState(600); // State to hold content height

    const fetchMessages = async () => {
        try {
            let response;
            if (scope === 'product_chat') {
                response = await connectProductChats(productId);
            } else if (scope === 'knowledge_base_chat') { // Added condition for knowledge_base_chat
                response = await connectKnowledgeBaseChats(knowledgeBaseId);
            } else {
                response = await connectUserChats();
            }
            const chatData = lodash.get(response, ['data'], null);
            if (chatData) {
                setChat(chatData);
                setMessages(chatData.messages || []);
            }
        } catch (error) {
            console.error("Error fetching messages:", error);
            setError('Failed to fetch messages. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchMessages();

        const updateHeight = () => {
            if (chatWindowRef.current) {
                const parent = chatWindowRef.current.parentElement;
                if (parent) {
                    setContentHeight(parent.clientHeight); // Set content height based on parent
                }
            }
        };

        // Create a ResizeObserver to observe changes in the parent element's size
        const resizeObserver = new ResizeObserver(updateHeight);
        if (chatWindowRef.current) {
            resizeObserver.observe(chatWindowRef.current.parentElement);
        }

        // Initial height update
        updateHeight();

        window.addEventListener('resize', updateHeight); // Update dimensions on window resize

        return () => {
            resizeObserver.disconnect(); // Cleanup observer
            window.removeEventListener('resize', updateHeight); // Cleanup event listener
        };
    }, [productId, scope, user]);

    useEffect(() => {
        if (chatWindowRef.current) {
            chatWindowRef.current.scrollTo({ top: chatWindowRef.current.scrollHeight, behavior: 'smooth' });
        }
    }, [messages]);

    const sendMessage = async () => {
        if (!message.trim()) {
            setError('Message cannot be empty.');
            return;
        }

        setSending(true);
        try {
            const payload = { message, chat_id: chat?.id };
            const response = await sendChatMessage(payload);
            if (response && response.data) {
                const chatObject = lodash.get(response, ['data', 'chat']);
                setChat(chatObject);
                setMessages(lodash.get(chatObject, 'messages', []));
                setMessage('');
                setError(null);
            } else {
                setError('Failed to send message.');
            }
        } catch (err) {
            console.error(err);
            setError('An error occurred while sending the message.');
        } finally {
            setSending(false);
        }
    };

    const formatTimestamp = (timestamp) => {
        const date = new Date(timestamp);
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const formattedDate = date.toLocaleDateString(undefined, options);
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        return `${formattedDate} - ${hours % 12 || 12}:${minutes < 10 ? '0' + minutes : minutes} ${ampm}`;
    };

    const groupedMessages = Array.isArray(messages) ? messages.reduce((acc, msg) => {
        const date = formatTimestamp(msg.timestamp);
        if (!acc[date]) {
            acc[date] = [];
        }
        acc[date].push(msg);
        return acc;
    }, {}) : {};

    const messageListHeight = contentHeight > 0 ? contentHeight - 200 : 'auto'; // Ensure contentHeight is greater than 0

    if (loading) {
        return (
            <p>Loading messages...</p>
        )
    }

    return (
        <ErrorBoundary>
            <div className="flex-1 overflow-y-auto bg-background rounded-lg mb-2 p-4" ref={chatWindowRef} style={{ height: `${messageListHeight}px` }}>
                <div className="flex flex-col space-y-2">

                    {messages.length === 0 && !loading ? (
                        <div className='flex flex-col items-center justify-center h-full'>
                            <p className="text-center text-xl text-gray-500">No chat messages found. Start the conversation!</p>
                            <p className="text-center text-md text-gray-500">Please enter your message below.</p>
                        </div>
                    ) : null}
                    {Object.keys(groupedMessages).map((date) => (
                        <div key={date} className="flex flex-col">
                            {groupedMessages[date].map((msg, msgIndex) => (
                                <div key={msgIndex} className={`flex items-start my-2 ${msg.role === 'user' ? 'justify-end' : 'justify-start'}`}>
                                    <Avatar className="mr-2">
                                        <AvatarImage src={msg.role === 'user' ? (user?.profile_image || "https://github.com/shadcn.png") : logo192} alt={msg.role} />
                                        <AvatarFallback>?</AvatarFallback>
                                    </Avatar>
                                    <div className={`message-bubble ${msg.role === 'user' ? 'bg-blue-500 text-white' : 'bg-accent'} p-3 rounded-lg w-4/5`}>
                                        <p className="text-left font-md font-semibold">{msg.content}</p>
                                        <p className="text-xs font-thin text-right">{formatTimestamp(msg.timestamp)}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            </div>
            <div className="flex items-center p-2 rounded-lg border bg-background gap-4">
                <Textarea
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    onKeyDown={(e) => { if (e.key === 'Enter') { e.preventDefault(); sendMessage(); } }}
                    placeholder="Type your message..."
                    className="chat-input flex-1 mx-2 border-none bg-background mr-2"
                    rows={3}
                    disabled={sending}
                />
                <Button onClick={sendMessage} className="h-full" disabled={sending}>
                    {sending ? <span className="loader"></span> : <Send className="h-6 w-6" />}
                </Button>
            </div>
        </ErrorBoundary>
    );
};

export default ChatInterface;