import React, { useEffect, useState, useMemo } from 'react';
import { LoaderCircle, PlusCircle } from 'lucide-react';
import { Button } from "../../../components/ui/button";
import { Alert } from "../../../components/ui/alert";
import { AddKnowledgeBaseDialog } from '../products/components/AddKnowledgeBaseDialog';
import { getKnowledgeBases } from '../../../services/api';
import { ResizableHandle, ResizablePanel, ResizablePanelGroup } from '../../../components/ui/resizable';
import { KnowledgeBaseDisplay } from './KnowledgeBaseDisplay';
import KnowledgeBaseList from './KnowledgeBaseList'; // Import the new component
import { logError, logDebug } from '../../../services/logging_utils'; // Import logging utilities

function KnowledgeBaseSection({ product, scope }) {
    const [knowledgeBases, setKnowledgeBases] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [showDialog, setShowDialog] = useState(false);
    const [showAddDialog, setShowAddDialog] = useState(false);
    const [selectedKnowledgeBase, setSelectedKnowledgeBase] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [filterType, setFilterType] = useState('All');

    const fetchKnowledgeBases = async () => {
        setLoading(true);
        setError('');
        try {
            const response = await getKnowledgeBases(product?.id);
            const knowledgeBases = response?.data?.data || [];
            setKnowledgeBases(knowledgeBases);
            setSelectedKnowledgeBase(knowledgeBases[0]);
            logDebug('Knowledge bases fetched successfully.'); // Log successful fetch
        } catch (err) {
            setError('Failed to load knowledge bases.');
            logError(err); // Log the error
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchKnowledgeBases();
    }, []); // Re-fetch if the product prop changes

    const handleUpdateKnowledgeBase = fetchKnowledgeBases;

    const filteredKnowledgeBases = useMemo(() => {
        return knowledgeBases.filter(kb => {
            const isMatch = kb.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
                kb.description.toLowerCase().includes(searchTerm.toLowerCase());
            return isMatch && (filterType === 'All' || kb.type === filterType);
        });
    }, [knowledgeBases, searchTerm, filterType]);

    if (loading) {
        return (
            <div className='h-full w-full flex flex-col justify-center items-center'>
                <LoaderCircle className='animate-spin' />
            </div>
        );
    }

    if (error) {
        return (
            <div className='h-full w-full flex flex-col justify-center items-center'>
                <Alert message={error} type="error" />
            </div>
        );
    }

    if (knowledgeBases.length === 0) {
        return (
            <div className='h-full w-full flex flex-col justify-center items-center'>
                <p className='text-blue-900 font-semibold text-xl'>No knowledge bases available.</p>
                <p className='text-gray-600 text-md'>Add your first knowledge base to get started.</p>
                <AddKnowledgeBaseDialog
                    renderActionTrigger={() => (
                        <Button size='lg' className='text-lg font-semibold mt-4' onClick={() => setShowDialog(true)}>
                            Add Knowledge Base
                        </Button>
                    )}
                    isOpen={showDialog}
                    onClose={() => setShowDialog(false)}
                    product={product}
                    onUpdate={handleUpdateKnowledgeBase}
                />
            </div>
        );
    }

    const handleSelectKnowledgeBase = (kb) => {
        setSelectedKnowledgeBase(kb);
    };

    const handleOnEditorUpdate = () => {
        logDebug('Refetching knowledge bases since knowledge bases were updated.'); // Log the update
        fetchKnowledgeBases();
    }

    return (
        <ResizablePanelGroup direction="horizontal" className="h-full max-h-[800px] items-stretch">
            <ResizablePanel defaultSize={655} maxSize={25} minSize={25} className="overflow-auto">
                <div className="mx-2 relative overflow-auto h-full">
                    <div className="overflow-y-auto">
                        <KnowledgeBaseList
                            knowledgeBases={filteredKnowledgeBases}
                            selectedKnowledgeBase={selectedKnowledgeBase}
                            onSelectKnowledgeBase={handleSelectKnowledgeBase}
                        />
                    </div>
                    <AddKnowledgeBaseDialog
                        product={product}
                        isOpen={showAddDialog}
                        onClose={() => setShowAddDialog(false)}
                        onUpdate={handleUpdateKnowledgeBase}
                        renderActionTrigger={() => (
                            <Button
                                onClick={() => setShowAddDialog(true)}
                                className="absolute bottom-2 right-2 rounded-full bg-blue-500 text-white shadow-lg "
                            >
                                <PlusCircle className="h-8 w-8" />
                            </Button>
                        )}
                    />
                </div>
            </ResizablePanel>
            <ResizableHandle withHandle />
            <ResizablePanel defaultSize={975}>
                <KnowledgeBaseDisplay
                    scope={scope}
                    knowledgeBase={selectedKnowledgeBase}
                    loading={loading}
                    handleOnEditorUpdate={handleOnEditorUpdate}
                />
            </ResizablePanel>
        </ResizablePanelGroup>
    );
}

export default KnowledgeBaseSection;