import React from 'react';
import { Info } from "lucide-react";

import { cn } from "../../lib/utils"
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from './tooltip'; // Adjust the import path as necessary

export const Textarea = ({ label, rows = 5, tooltip, tooltipTrigger, required = false, error, className, ...props }) => { // Added error prop
  return (
    <div className="mb-2 w-full flex flex-col items-start">
      <div className="mb-2 w-full flex items-center">

        {label && (
          <label className="flex items-center text-xs font-semibold"> {/* Fixed typo 'tiems-center' to 'items-center' */}
            {label}
            {required && <span className="text-red-500 ml-1">*</span>} {/* Show red * if required */}
          </label>
        )}
        {tooltip && (
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <Info className="h-3 w-3 ml-1 cursor-pointer" />

              </TooltipTrigger>
              <TooltipContent>
                <p>{tooltip}</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        )}
      </div>

      <textarea
        {...props}
        rows={rows} // Use the rows prop
        className={cn(
          "border rounded p-2 w-full h-32 resize-none bg-muted",
          error ? "border-red-500" : "",
          className
        )}
      />
      {error && <p className="text-red-500 text-sm italic">{error}</p>} {/* Show error message */}
    </div>
  );
};
