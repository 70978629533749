import { useState } from "react";
import lodash from "lodash";
// Custom components
import { Progress } from "../../../../components/ui/progress";
import { Button } from "../../../../components/ui/button";
import { Textarea } from "../../../../components/ui/textarea";

export default function TypeFormStyleQuestionsForm({ questions, onChange, creatingProduct }) {
    const [currentStep, setCurrentStep] = useState(1);

    const handleNext = () => {
        setCurrentStep(currentStep + 1);
    };
    const handlePrevious = () => {
        setCurrentStep(currentStep - 1);
    };

    const handleInputChange = (e) => {
        onChange(currentStep - 1, e.target.value);
    };

    const currentQuestion = questions[currentStep - 1];

    return (
        <div className="flex flex-col items-center justify-center h-full w-full bg-background text-foreground">
            <div className="w-full py-4 bg-card rounded-lg">
                <div className="flex items-center justify-center mb-8">
                    <div className="flex-1">
                        <Progress value={(currentStep / questions.length) * 100} />
                    </div>
                    <div className="text-sm font-medium text-muted-foreground mx-2">Step {currentStep} of {questions.length}</div>
                </div>
                <>
                    <h1 className="text-4xl font-bold mb-4 text-left">
                        {lodash.get(currentQuestion, ['question'])}
                    </h1>
                    <Textarea
                        id="message"
                        name="message"
                        variant="ghost"
                        className='text-md'
                        row={10} // Set the number of rows to 10
                        value={lodash.get(currentQuestion, ['answer'])}
                        onChange={handleInputChange}
                        required
                    />
                    <p className="text-sm text-muted-foreground text-left mt-2">
                        You can leave the input empty if you are not clear about the answer at the moment.
                    </p>
                </>
                <div className="flex justify-between mt-8">
                    <div className='flex justify-start'>
                        {currentStep > 1 && (
                            <Button variant="outline" disabled={currentStep === 1} onClick={handlePrevious}>
                                Previous
                            </Button>
                        )}
                        {currentStep < questions.length && <Button className='text-left mx-2' onClick={handleNext}>Next</Button>}
                    </div>
                </div>
            </div>
        </div>
    );
}