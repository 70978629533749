import lodash from 'lodash';

import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../../../components/ui/tabs";

import BlocknoteEditor from "../../../../components/BlocknoteEditor";
import { Skeleton } from '../../../../components/ui/skeleton';
import MindMapEditor from '../../../../components/MindMapEditor';
import { ResearchReportVersionReferences } from '../../../../../src/components/ResearchReportVersionReferences'; // Import the component

export const ProductReportDisplay = ({ report, selectedReportVersion, startResearching, inputValue, setInputValue, handleTextareaEnter, loading }) => {

  // This needs to be updated, for an existing report coming from server the content is a string. Whereas while the streaming is going on the report content comes as an array.
  const reportsContent = lodash.get(report, ['content', selectedReportVersion, 'content'], '');
  const selectedReport = lodash.get(report, ['content', selectedReportVersion], {});
  const sanitzedReport = typeof reportsContent === 'string' ? reportsContent : reportsContent.filter((r) => r !== "" && typeof r === "string").join("");


  if (loading) {
    return (
      <div className="flex h-full flex-col p-4 justify-start">
        <Skeleton className='w-[300px] h-[60px] my-1' />
        <Skeleton className='w-[400px] h-[40px] my-1' />
        <Skeleton className='w-full h-[40px] my-1' />
        <Skeleton className='w-full h-[20px] my-1' />
        <Skeleton className='w-full h-[20px] my-1' />
        <Skeleton className='w-full h-[20px] my-1 mb-8' />


        <Skeleton className='w-[300px] h-[60px] my-1' />
        <Skeleton className='w-[400px] h-[40px] my-1' />
        <Skeleton className='w-full h-[20px] my-1' />
        <Skeleton className='w-full h-[20px] my-1' />
        <Skeleton className='w-full h-[20px] my-1' />

      </div>
    )
  }

  const handleOnEditorUpdate = () => { }

  return (
    <>
      <div className="flex h-full flex-col justify-start">

        {sanitzedReport ? (
          <div
            className="w-full h-full text-left"
            style={{ maxHeight: "calc(100vh - 264px)", overflowY: "auto" }}
          >

            <Tabs defaultValue="report" className='h-full w-full'>
              <TabsList className="grid grid-cols-3 m-1"> {/* Updated to 3 columns */}
                <TabsTrigger value="report">Report</TabsTrigger>
                <TabsTrigger value="mindmap">Mindmap</TabsTrigger>
                <TabsTrigger value="references">References</TabsTrigger> {/* New tab for references */}
              </TabsList>

              <TabsContent value="report" className='p-1 border rounded m-1 product-view_tab-content'>
                <BlocknoteEditor autoSave={true} selectedReport={selectedReport} content={sanitzedReport} handleOnEditorUpdate={handleOnEditorUpdate} />
              </TabsContent>

              <TabsContent value="mindmap" className='h-full p-1 border rounded m-1 product-view_tab-content'>
                <MindMapEditor selectedReport={selectedReport} content={sanitzedReport} />
              </TabsContent>

              <TabsContent value="references" className='h-full p-1 border rounded m-1 product-view_tab-content'> {/* New content for references */}
                <ResearchReportVersionReferences report={report} selectedReportVersion={selectedReportVersion} />
              </TabsContent>
            </Tabs>

          </div>
        ) :
          null}
        {/* <div className="relative overflow-hidden rounded-lg border bg-background focus-within:ring-1 focus-within:ring-ring">
          <Textarea
            id="message"
            placeholder="Type your message here..."
            className="min-h-12 resize-none border-0 p-3 shadow-none focus-visible:ring-0"
            value={inputValue}
            disabled={!!startResearching}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyDown={handleTextareaEnter}
          />
          <div className="flex items-center p-3 pt-0">
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button variant="ghost" size="icon">
                    <Paperclip className="size-4" />
                    <span className="sr-only">Attach file</span>
                  </Button>
                </TooltipTrigger>
                <TooltipContent side="top">Attach File</TooltipContent>
              </Tooltip>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button variant="ghost" size="icon">
                    <Mic className="size-4" />
                    <span className="sr-only">Use Microphone</span>
                  </Button>
                </TooltipTrigger>
                <TooltipContent side="top">Use Microphone</TooltipContent>
              </Tooltip>
              <Button
                type="submit"
                size="sm"
                className="ml-auto gap-1.5"
                disabled={!!startResearching}
                onClick={() => { }}
              >
                {!!startResearching ? <Loader /> : null}
                {startResearching ? `${startResearching}` : "Send Message"}
                <CornerDownLeft className="size-3.5" />
              </Button>
            </TooltipProvider>
          </div>
        </div> */}
      </div>
    </>
  )
}
