import React, { useEffect, useState } from 'react'
import { loadStripe } from '@stripe/stripe-js';
// UI components
import { UpgradeCards } from '../components/subscriptions/UpgradeCards';
import { createCheckoutSession, getSubscriptions } from '../services/api';
import { logError } from '../services/logging_utils';


export const SubscriptionsPage = () => {
    const [subscriptions, setSubscriptions] = useState([]);

    useEffect(() => {
        (async () => {
            // Fetching the plans from the server
            try {
                const response = await getSubscriptions();
                const subscriptions = response.data.data;

                // Filtering out the free plans
                const paidSubscriptions = subscriptions.filter(subscription => subscription.price > 0);

                setSubscriptions(paidSubscriptions);
            } catch (error) {
                logError(error)
            }
        })()
    }, []);

    const handleSubscribe = async (plan, frequency) => {
        try {
            const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
            const body = {
                plan_id: plan.plan_id,
                plan_frequency: frequency,
            }

            const response = await createCheckoutSession({ body });
            const session = response.data;

            const result = await stripe.redirectToCheckout({
                sessionId: session.id,
            });
            if (result.error) {
                console.log(result.error.message)
            }
        } catch (error) {
            logError(error)
        }
    }
    return (
        <UpgradeCards />
    )
}