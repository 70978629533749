import lodash from 'lodash';
import { Button } from "../../../../components/ui/button";
import {
    Dialog,
    DialogContent,
    DialogTrigger,
    DialogClose,
} from "../../../../components/ui/dialog";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../../../components/ui/tabs";
import { Separator } from "../../../../components/ui/separator";
import { Alert } from "../../../../components/ui/alert";
import { Input } from "../../../../components/ui/input";
import { Textarea } from "../../../../components/ui/textarea";
import { useState } from 'react';
import { addKnowledgeBase, uploadFile } from '../../../../services/api'; // Import the new API functions
import { UploadComponent } from "../../../../components/ui/upload"; // Import the UploadComponent

export function AddKnowledgeBaseDialog(props) {
    const { renderActionTrigger, isOpen, onClose, product, onUpdate } = props; // Added onKnowledgeBaseAdded prop
    const [selectedTab, setSelectedTab] = useState('link');
    const [title, setTitle] = useState(''); // State for title
    const [description, setDescription] = useState(''); // State for description
    const [urlInput, setUrlInput] = useState('');
    const [uploadedFile, setUploadedFile] = useState(null);
    const [textInput, setTextInput] = useState('');
    const [loading, setLoading] = useState(false); // Loading state
    const [isUploading, setIsUploading] = useState(false); // Add isUploading state
    const [error, setError] = useState(''); // Error state
    const [titleError, setTitleError] = useState('');
    const [descriptionError, setDescriptionError] = useState('');
    const [urlInputError, setUrlInputError] = useState('');
    const [textInputError, setTextInputError] = useState('');
    const [uploadedFileError, setUploadedFileError] = useState('');

    // Updated handleChange functions to clear errors
    const handleTitleChange = (e) => {
        setTitle(e.target.value);
        if (e.target.value.trim()) {
            setTitleError('');
        }
    };

    const handleDescriptionChange = (e) => {
        setDescription(e.target.value);
        if (e.target.value.trim()) {
            setDescriptionError('');
        }
    };

    const handleUrlInputChange = (e) => {
        setUrlInput(e.target.value);
        if (e.target.value.trim()) {
            setUrlInputError('');
        }
    };

    const handleTextInputChange = (e) => {
        setTextInput(e.target.value);
        if (e.target.value.trim()) {
            setTextInputError('');
        }
    };

    const handleSubmit = async () => {
        setLoading(true);
        setError('');
        // Reset field errors
        setTitleError('');
        setDescriptionError('');
        setUrlInputError('');
        setTextInputError('');
        setUploadedFileError('');

        let hasError = false;

        // Validate Title
        if (!title.trim()) {
            setTitleError('Please provide a title.');
            hasError = true;
        }

        // Validate Description (not mandatory anymore)
        // if (!description.trim()) {
        //     setDescriptionError('Please provide a description.');
        //     hasError = true;
        // }

        // Validate based on selectedTab
        if (selectedTab === 'link') {
            if (!urlInput.trim()) {
                setUrlInputError('Please enter a valid URL.');
                hasError = true;
            }
        } else if (selectedTab === 'upload') {
            if (!uploadedFile) {
                setUploadedFileError('Please upload a file.');
                hasError = true;
            }
        } else if (selectedTab === 'text') {
            if (!textInput.trim()) {
                setTextInputError('Please enter your text.');
                hasError = true;
            }
        }

        if (hasError) {
            setLoading(false);
            return;
        }

        try {
            let fileUrl = '';

            if (selectedTab === 'upload' && uploadedFile) {
                setIsUploading(true);
                const uploadResponse = await uploadFile(uploadedFile);
                fileUrl = lodash.get(uploadResponse, ['data', 'data', 'url']);
                setIsUploading(false);
            }

            let data = {
                title: title,
                description: description,
                product_id: lodash.get(product, 'id'),
                link: selectedTab === 'link' ? urlInput : (selectedTab === 'upload' ? fileUrl : textInput),
                type: selectedTab,
                content: textInput
            };

            await addKnowledgeBase(data);
            console.log('Knowledge base document added successfully!');

            onUpdate();
            onClose();
        } catch (error) {
            setError(error.message);
            console.error('Error submitting knowledge base document:', error);
        } finally {
            setLoading(false);
        }
    };

    if (!isOpen) {
        return renderActionTrigger();
    }

    return (
        <Dialog open={isOpen} onOpenChange={onClose} className="w-screen max-w-4xl">
            <DialogTrigger asChild>
                {renderActionTrigger()}
            </DialogTrigger>
            <DialogContent className="gap-0 flex flex-col justify-between" style={{ minHeight: '80vh', maxHeight: '80%', overflowY: 'auto' }}>
                <div className='flex flex-col gap-0' style={{ maxHeight: '80%', overflowY: 'auto' }}>
                    <div className='flex flex-col gap-0'>
                        <h2 className="text-xl font-bold mb-0">Add New Knowledge Base Entry</h2>
                        <p className="text-sm">Fill in the details below to create a new entry in your knowledge base by providing a link, uploading a file, or entering text information.</p>
                    </div>
                    <Separator className="my-2" />
                    <div className='flex flex-col mt-4'>

                        <p className='text-xl font-semibold mb-2 text-blue-900'>Choose Entry Type</p>

                        <Tabs className='w-full' defaultValue="link" value={selectedTab} onValueChange={setSelectedTab}>
                            <TabsList>
                                <TabsTrigger value="link">Link</TabsTrigger>
                                <TabsTrigger value="upload">Upload File</TabsTrigger>
                                <TabsTrigger value="text">Text Entry</TabsTrigger>
                            </TabsList>
                            <TabsContent value="link">
                                <div className="flex flex-col gap-1 my-4">
                                    <Input
                                        label="Knowledge Base URL"
                                        tooltip="Provide a valid URL related to the knowledge base entry."
                                        value={urlInput}
                                        onChange={handleUrlInputChange}
                                        disabled={loading || isUploading}
                                        required={selectedTab === 'link'}
                                        error={urlInputError}
                                    />
                                </div>
                            </TabsContent>
                            <TabsContent value="upload">
                                <div className="flex flex-col gap-2">
                                    <p className="block text-xs font-semibold text-gray-500">Please upload a file that contains information for the knowledge base entry.</p>
                                    <UploadComponent
                                        productId={lodash.get(product, 'id')}
                                        onChange={(file) => {
                                            setUploadedFile(file);
                                            if (file) setUploadedFileError('');
                                        }}
                                        disabled={loading || isUploading}
                                        loading={isUploading}
                                        error={uploadedFileError}
                                        tooltip="Upload files in .jpg, .png, .pdf, .docx, or .txt formats."
                                    />
                                </div>
                            </TabsContent>
                            <TabsContent value="text">
                                <div className="flex flex-col gap-2">
                                    <Textarea
                                        label="Knowledge Base Text"
                                        tooltip="Enter detailed text information for the knowledge base entry."
                                        value={textInput}
                                        onChange={handleTextInputChange}
                                        disabled={loading || isUploading}
                                        required={selectedTab === 'text'}
                                        error={textInputError}
                                    />
                                </div>
                            </TabsContent>
                        </Tabs>

                        <Separator className='my-4' />

                        <p className='text-xl font-semibold mb-2 text-blue-900'>Entry Details</p>

                        <Input
                            label="Entry Title"
                            tooltip="Provide a clear and descriptive title for your knowledge base entry."
                            value={title}
                            onChange={handleTitleChange}
                            disabled={loading || isUploading}
                            className="mb-2"
                            required={true}
                            error={titleError}
                        />

                        <Textarea
                            label="Entry Description"
                            tooltip="Provide a comprehensive description for your knowledge base entry."
                            value={description}
                            onChange={handleDescriptionChange}
                            disabled={loading || isUploading}
                            className="mb-2"
                            // Removed required attribute
                            error={descriptionError}
                        />
                    </div>
                </div>

                {error && <div className='text-red-500 text-md bg-red-100 p-2 rounded-md'>{error}</div>}

                <div className='flex justify-end mt-2'>
                    <Button
                        className='mr-2'
                        onClick={handleSubmit}
                        disabled={loading || isUploading}
                    >
                        {loading ? 'Submitting...' : 'Add Entry Knowledge Base'}
                    </Button>
                </div>
            </DialogContent>
        </Dialog>
    )
}
