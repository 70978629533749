import { useEffect, useState } from 'react';
import lodash from 'lodash';
import { Edit } from 'lucide-react';

import { Button } from "../../../../components/ui/button";
import { Input } from "../../../../components/ui/input"
import { Label } from "../../../../components/ui/label"


// Custom components
import {
    Dialog,
    DialogHeader, DialogTitle, DialogDescription, DialogFooter,
    DialogContent,
    DialogClose
} from "../../../../components/ui/dialog";
import {
    TooltipProvider,
    Tooltip,
    TooltipTrigger,
    TooltipContent,
} from "../../../../components/ui/tooltip";


export const EditProductDialog = ({ handleReportCreation, product, isOpen, setDialogStatus }) => {
    const [title, setTitle] = useState(lodash.get(product, ['title'], ''));
    const [description, setDescription] = useState(lodash.get(product, ['description'], ''));

    useEffect(() => {
        setTitle(lodash.get(product, ['title'], ''));
        setDescription(lodash.get(product, ['description'], ''));
    }, [product]);

    if (!isOpen) {
        return (


            <TooltipProvider>
                <Tooltip>
                    <TooltipTrigger asChild>
                        <Button
                            variant="secondary"
                            size="sm"
                            className="flex items-center justify-center text-4xl font-bold m-1"
                            onClick={() => setDialogStatus(true)}
                        >
                            <Edit className="h-4 w-4" />
                        </Button>
                    </TooltipTrigger>
                    <TooltipContent side="right">Edit</TooltipContent>
                </Tooltip>
            </TooltipProvider>
        )
    }

    const handleSubmit = async () => {
        const payload = {
            title,
            description
        };
        handleReportCreation(payload);
    };



    return (
        <Dialog open={isOpen}>
            <DialogContent className="sm:max-w-[425px]">
                <DialogHeader>
                    <DialogTitle>Edit Report</DialogTitle>
                    <DialogDescription>
                        Give a title and description for the product.
                    </DialogDescription>
                </DialogHeader>
                <div className="grid gap-4 py-4">
                    <div className="flex items-start gap-2 flex-col">
                        <Label htmlFor="title" className="text-right">
                            Title
                        </Label>
                        <Input
                            id="title"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            className="col-span-3"
                        />
                    </div>
                    <div className="flex items-start gap-2 flex-col">
                        <Label htmlFor="description" className="text-right">
                            Description
                        </Label>
                        <Input
                            id="description"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            className="col-span-3"
                        />
                    </div>
                </div>
                <DialogFooter>
                    <DialogClose>
                        <Button type="button" variant="secondary" onClick={() => setDialogStatus(null)}>
                            Close
                        </Button>
                    </DialogClose>

                    <Button type="submit" onClick={handleSubmit}>Save changes</Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}
