import React, { useState, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { Search, ClipboardCheck, Plus } from 'lucide-react';
import lodash from 'lodash';
// Custom components
import {
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    CardTitle,
} from "../../components/ui/card";
import {
    Tabs,
    TabsContent,
    TabsList,
    TabsTrigger,
} from "../../components/ui/tabs"
import { Avatar, AvatarImage } from "../../components/ui/avatar";

import {
    Table,
    TableBody,
    TableCaption,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "../../components/ui/table"
import { Separator } from "../../components/ui/separator";
import { Button } from "../../components/ui/button";
import { Input } from "../../components/ui/input";
// Services
import { logError } from '../../services/logging_utils';
import { getAdminReports } from '../../services/api';
import { startReportGeneration } from '../dashboard/reports_utils';
import { systemConstantsState } from '../../store/store';

function CommonCardHeader() {
    return (
        <CardHeader className='text-left bg-muted flex flex-row items-center'>
            <ClipboardCheck className="h-8 w-8 transition-all group-hover:scale-110 mr-4" />
            <div className='flex flex-col'>
                <CardTitle>Tasks</CardTitle>
                <CardDescription>List of tasks that are active, scheduled or researved.</CardDescription>
            </div>
        </CardHeader>
    )
}


function TasksTable(props) {
    const { reports, taskStatusTab } = props;
    const [startResearching, setStartResearching] = useState(null);

    const [systemConstants, setSystemConnstants] = useRecoilState(systemConstantsState);


    const filterReportsByStatus = (status) => {
        return reports.filter(r1 => r1.status === status);
    }

    // const handleGenerateReport = async (report) => {
    //     setStartResearching(report);

    //     const configSettings = lodash.get(systemConstants, ['CONFIG_SETTINGS', 'value'], {});

    //     const requestData = {
    //         // This query construction will change over time
    //         task: `For a product, ${product.name}, with a description of ${product.description}, generate a report with ${report.title} and description of ${report.description}`,
    //         report_type: 'product_report',
    //         report_source: 'web',
    //         agent: 'Auto Agent',
    //         product_id: product.id,
    //         report_id: report.id,
    //         ...configSettings
    //     };

    //     try {
    //         await startReportGeneration({ setters: { setStartResearching, setProductReports: null, setSelectedReport: null }, data: requestData });
    //     } catch (error) {
    //         logError('Error generating report:', error);
    //     }
    // };

    return (
        <>
            <Tabs defaultValue="in_progress" className="">
                <div className='flex w-full'>
                    <form className='w-full mr-2'>
                        <div className='relative'>
                            <Search className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
                            <Input
                                type="search"
                                placeholder="Search Reports..."
                                className="md:w-[100px] lg:w-[300px] mb-2 pl-8"
                            // onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </div>
                    </form>
                    <TabsList className="grid w-full grid-cols-3">
                        <TabsTrigger value="in_progress">In Progress</TabsTrigger>
                        <TabsTrigger disabled={false} value="queued">Queued</TabsTrigger>
                        <TabsTrigger disabled={false} value="completed">Completed</TabsTrigger>
                    </TabsList>
                </div>
                <div className="bg-white rounded-xl border border-gray-200 dark:bg-gray-950 dark:border-gray-800 flex items-center justify-center w-full h-full">
                    <TabsContent value="in_progress">

                        <>
                            {reports.length === 0 ? (
                                <div className='w-full h-full flex justify-center items-center'>
                                    <p>Currently, no tasks are active.</p>
                                </div>
                            ) : <Table>
                                <TableHeader>
                                    <TableRow>
                                        <TableHead>Report Id</TableHead>
                                        <TableHead>Title/ Description</TableHead>
                                        <TableHead className="text-right">Status</TableHead>
                                    </TableRow>
                                </TableHeader>
                                <TableBody>
                                    {filterReportsByStatus('in_progress').map(report => {
                                        return (
                                            <TableRow key={lodash.get(report, ['id'])}>
                                                <TableCell className='text-left'>
                                                    <div className='flex flex-col ml-2'>
                                                        <p className='font-bold'>{lodash.get(report, ['id'])}</p>
                                                    </div>
                                                </TableCell>
                                                <TableCell className='text-left capitalize'>
                                                    <div className='flex flex-col ml-2'>
                                                        <p className=''>{lodash.get(report, ['title'])}</p>
                                                        <p className=''>{lodash.get(report, ['description'])}</p>
                                                    </div>
                                                </TableCell>
                                                <TableCell className="text-right capitalize">
                                                    <p className=''>{lodash.get(report, ['status'])}</p>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>}
                        </>

                    </TabsContent>

                    <TabsContent value="queued">
                        {reports.length === 0 ? (
                            <div className='w-full h-full flex justify-center items-center'>
                                <p>Currently, no tasks are active.</p>
                            </div>
                        ) : <Table>
                            <TableHeader>
                                <TableRow>
                                    <TableHead>Report Id</TableHead>
                                    <TableHead>Title/ Description</TableHead>
                                    <TableHead className="text-right">Status</TableHead>
                                </TableRow>
                            </TableHeader>
                            <TableBody>
                                {filterReportsByStatus('pending').map(report => {
                                    return (
                                        <TableRow key={lodash.get(report, ['id'])}>
                                            <TableCell className='text-left'>
                                                <div className='flex flex-col ml-2'>
                                                    <p className='font-bold'>{lodash.get(report, ['id'])}</p>
                                                </div>
                                            </TableCell>
                                            <TableCell className='text-left capitalize'>
                                                <div className='flex flex-col ml-2'>
                                                    <p className=''>{lodash.get(report, ['title'])}</p>
                                                    <p className=''>{lodash.get(report, ['description'])}</p>
                                                </div>
                                            </TableCell>
                                            <TableCell className="text-right capitalize">
                                                <p className=''>{lodash.get(report, ['status'])}</p>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>}
                    </TabsContent>

                    <TabsContent value="completed">
                        {reports.length === 0 ? (
                            <div className='w-full h-full flex justify-center items-center'>
                                <p>Currently, no tasks are active.</p>
                            </div>
                        ) : <Table>
                            <TableHeader>
                                <TableRow>
                                    <TableHead>Report Id</TableHead>
                                    <TableHead>Title/ Description</TableHead>
                                    <TableHead className="text-right">Status</TableHead>
                                </TableRow>
                            </TableHeader>
                            <TableBody>
                                {filterReportsByStatus('completed').map(report => {
                                    return (
                                        <TableRow key={lodash.get(report, ['id'])}>
                                            <TableCell className='text-left'>
                                                <div className='flex flex-col ml-2'>
                                                    <p className='font-bold'>{lodash.get(report, ['id'])}</p>
                                                </div>
                                            </TableCell>
                                            <TableCell className='text-left capitalize'>
                                                <div className='flex flex-col ml-2'>
                                                    <p className=''>{lodash.get(report, ['title'])}</p>
                                                    <p className=''>{lodash.get(report, ['description'])}</p>
                                                </div>
                                            </TableCell>
                                            <TableCell className="text-right capitalize">
                                                <p className=''>{lodash.get(report, ['status'])}</p>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>}
                    </TabsContent>
                </div>
            </Tabs>
        </>
    )
}


function AdminReportsList() {

    const [reports, setReports] = useState([]);
    const [loadingReports, setLoadingReports] = useState(false);

    const [taskStatusTab, setTaskStatusTab] = useState('active');

    useEffect(() => {
        (async () => {
            setLoadingReports(true);
            try {
                const response = await getAdminReports();
                const reports = lodash.get(response, ['data', 'reports'], []);
                setReports(reports);
            } catch (error) {
                logError(error);
            }
            setLoadingReports(false);
        })()
    }, []);

    return (
        <>
            <Card className='h-full'>
                <CommonCardHeader />
                <Separator />
                <CardContent className='p-4 w-full h-full'>
                    <div className='flex flex-col h-full'>
                        {loadingReports ? <div className='p-2 rounded my-1 bg-accent text-left'><p className='text-md font-semibold'>Loading Reports...</p></div> : null}

                        <section className=" grid gap-8 grid-cols-1  lg:gap-2 cursor-pointer h-full" style={{ maxHeight: 'calc(100vh - 284px)', overflowY: 'auto' }}>
                            <TasksTable reports={reports} taskStatusTab={taskStatusTab} loadingTasks={loadingReports} />
                        </section>
                    </div>
                </CardContent >
            </Card >
        </>
    )
}

export default AdminReportsList;