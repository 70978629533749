import lodash from "lodash";
import { cn } from "../../../../lib/utils";
import { Badge } from "../../../../components/ui/badge";
import { AnimatedList } from "../../../../components/magicui/animated-list";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../../../components/ui/tooltip"

export const ProductLogList = ({ items }) => {

  return (
    <div className="flex flex-col gap-2 pt-0 " style={{ maxHeight: 'calc(100vh - 96px)', overflowY: 'auto' }}>
      {/* <AnimatedList> */}
      {(items || []).map((item) => (
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <button
                key={item.id}
                className={cn("flex flex-col items-start gap-2 rounded-lg border p-3 text-left text-sm transition-all hover:bg-accent animate-slide-down")}
                onClick={() => { }}
              >
                <div className="flex items-center gap-2 w-full">
                  <div className="font-semibold  max-w-full">{lodash.get(item, ['output'])}</div>
                </div>
                <div className="flex items-center gap-2">
                  <Badge variant='default'>
                    {lodash.get(item, ['type'])}
                  </Badge>
                </div>
              </button>
            </TooltipTrigger>
            <TooltipContent>
              <p>{lodash.get(item, ['output'])}</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>

      ))}
      {/* </AnimatedList> */}
    </div>
  )
}

function getBadgeVariantFromLabel(
  label
) {
  if (["work"].includes(label.toLowerCase())) {
    return "default"
  }

  if (["personal"].includes(label.toLowerCase())) {
    return "outline"
  }

  return "secondary"
}
