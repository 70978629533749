import { useState } from "react";
import { Plus, LoaderCircle, Package, Info } from "lucide-react";
// Custom components
import { Button } from "../../../../components/ui/button";
import { Input } from "../../../../components/ui/input";
import { Label } from "../../../../components/ui/label";
import {
  Dialog,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
  DialogContent,
  DialogClose,
} from "../../../../components/ui/dialog";
import { Separator } from "../../../../components/ui/separator";
import { Textarea } from "../../../../components/ui/textarea";

function CommonCardHeader() {
  return (
    <DialogHeader className="flex flex-row items-center">
      <Package className="h-8 w-8 transition-all group-hover:scale-110 mr-4" />
      <div className="flex flex-col">
        <DialogTitle>Add Product</DialogTitle>
        <DialogDescription>
          Give a title and description for the Product.
        </DialogDescription>
      </div>
    </DialogHeader>
  );
}

export default function AddProductDialog(props) {
  const { handleProductCreation, isOpen, setDialogStatus, creatingProduct } =
    props;

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const isFormValid = title.trim() !== "" && description.trim() !== ""; // Validation for input and description

  if (!isOpen) {
    return (
      <div className="flex flex-col ">
        <Button
          variant="outline"
          size="lg"
          className="w-full h-full flex items-center justify-center text-4xl font-bold my-2"
          onClick={() => setDialogStatus(true)}
        >
          <Plus className="h-8 w-8" />
        </Button>
        <p className="text-lg font-bold">Add Product</p>
      </div>
    );
  }

  const handleSubmit = () => {
    if (isFormValid) {
      handleProductCreation({ name: title, description });
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={() => setDialogStatus(false)}>
      <DialogContent className="sm:max-w-[600px]"> {/* Increase the max width */}
        <CommonCardHeader />
        <Separator />
        <div className="grid gap-4 py-2">
          <div className="flex flex-col items-start gap-2">
            <Input
              id="Title"
              label="Title"
              value={title}
              disabled={creatingProduct}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Eg. Smart Water Bottle with Hydration Tracking"
              tooltip="Enter the product title here." // Add tooltip text
            />
          </div>
          <div className="flex flex-col items-start gap-2">
            <Textarea
              id="description"
              label="Description"
              value={description}
              disabled={creatingProduct}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Eg. A reusable water bottle that tracks your daily water intake and syncs with a mobile app to provide hydration reminders and health insights."
              tooltip="Provide a detailed description of the product." // Add tooltip text
            />
          </div>
        </div>
        <DialogFooter>
          <Button
            disabled={creatingProduct || !isFormValid} // Disable button if creating or form is invalid
            type="submit"
            onClick={handleSubmit}
          >
            {creatingProduct ? <LoaderCircle className="animate-spin w-4 h-4 mr-2" /> : "Create Product"}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
