import mixpanel from 'mixpanel-browser';
import * as Sentry from "@sentry/react";

export const setToken = (token) => {
    // Encrypt the token using btoa before saving it to local storage
    const encryptedToken = btoa(token);
    return localStorage.setItem('accessToken', encryptedToken);
}

export const getToken = () => {
    // Retrieve the encrypted token from local storage
    const encryptedToken = localStorage.getItem('accessToken');
    if (encryptedToken === null) return null;
    // Decrypt the token using atob before returning it
    return atob(encryptedToken);
}

export const removeToken = () => {
    return localStorage.removeItem('accessToken');
}

export const setUser = (user) => {
    // Encrypt the user object using btoa before saving it to local storage
    const encryptedUser = btoa(JSON.stringify(user));
    if (encryptedUser === null) return null;

    return localStorage.setItem('user', encryptedUser);
};

export const getUser = () => {
    // Retrieve the encrypted user object from local storage
    const encryptedUser = localStorage.getItem('user');
    if (!encryptedUser) return null;

    // Decrypt the user object using atob before returning it
    return JSON.parse(atob(encryptedUser));
}

export const removeUser = () => {
    return localStorage.removeItem('user');
}
