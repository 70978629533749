import { useEffect, useState } from 'react'; // Added useRef and useCallback
import lodash from 'lodash';
import { useRecoilValue } from 'recoil'; // Import useRecoilValue
import { userState } from '../../../../store/store'; // Import userState
import { updateProductNote, getProductNotes } from '../../../../services/api'; // Import the update and get functions
import ErrorBoundary from '../../../../components/ui/ErrorBoundary'; // Import the ErrorBoundary
import BlockNoteEditor from '../../../../components/BlocknoteEditor'; // Correctly import BlockNoteEditor
import { Skeleton } from '../../../../components/ui/skeleton'; // Import the Skeleton component
import { Button } from '../../../../components/ui/button'; // Import Button component

export default function ProductNotesView({ product }) {
    const user = useRecoilValue(userState); // Get user from userState
    const [selectedReport, setSelectedReport] = useState(null);
    const [notes, setNotes] = useState({}); // State for product notes as an object
    const [loadingNotes, setLoadingNotes] = useState(false); // State for loading notes
    const [updatingContent, setUpdatingContent] = useState(false);
    const [isEditing, setIsEditing] = useState(false); // State for editing mode

    // Fetch product notes
    const fetchProductNotes = async () => {
        setLoadingNotes(true); // Start loading
        try {
            const response = await getProductNotes(product.id);
            setNotes(response.data); // Set notes as an object
        } catch (error) {
            console.error("Error fetching product notes:", error);
        } finally {
            setLoadingNotes(false); // End loading
        }
    };

    useEffect(() => {
        if (product) {
            // Set the first report as the selected report when the product changes
            setSelectedReport(lodash.get(product, 'reports[0]', null));
            fetchProductNotes();
        }
    }, [product]);

    if (loadingNotes) {
        return (
            <div className="flex h-full flex-col p-2 justify-start">
                <Skeleton className='w-[300px] h-[60px] my-1' />
                <Skeleton className='w-[400px] h-[40px] my-1' />
                <Skeleton className='w-full h-[40px] my-1' />
                <Skeleton className='w-full h-[20px] my-1' />
                <Skeleton className='w-full h-[20px] my-1' />
                <Skeleton className='w-full h-[20px] my-1 mb-8' />
                <Skeleton className='w-[300px] h-[60px] my-1' />
                <Skeleton className='w-[400px] h-[40px] my-1' />
                <Skeleton className='w-full h-[20px] my-1' />
                <Skeleton className='w-full h-[20px] my-1' />
                <Skeleton className='w-full h-[20px] my-1' />
            </div>
        );
    }

    const handleOnEditorUpdate = async (markdown) => {
        // Call your API here with the markdown content
        try {
            setUpdatingContent(true);
            const payload = {
                product_id: product.id,
                content: markdown,
                creator_id: lodash.get(user, ['id']) // Use user from userState
            };
            await updateProductNote(payload);
        } catch (error) {
            console.error('Error updating report', error);
        } finally {
            setUpdatingContent(false);
        }
    };

    return (
        <ErrorBoundary>
            <div className=' px-2 h-full w-full'>
                {updatingContent ? <div className='px-2 py-1 bg-accent transition-all'><p className='text-sm'>Updating...</p></div> : null}
                <BlockNoteEditor
                    autoSave={false}
                    content={notes.content} // Pass content from notes object
                    handleOnEditorUpdate={handleOnEditorUpdate} // Only allow updates if editing
                />
            </div>
        </ErrorBoundary>
    );
}
