import React from 'react';
import { Badge } from '../../../components/ui/badge';

export const KnowledgeBaseStatusBadge = ({ status, className }) => {
    if (!status) return null;

    return (
        <Badge className={`text-xs capitalize ${getBadgeVariantFromLabel(status)} ${className}`} variant="secondary">
            {(status || '').replace(/_/g, ' ')}
        </Badge>
    )
}

function getBadgeVariantFromLabel(
    label
) {
    if (["in_progress"].includes(label)) {
        return "bg-blue-500 text-white hover:bg-blue-500 text-white"
    }

    if (["queued"].includes(label)) {
        return "bg-yellow-400 text-white hover:bg-yellow-400 text-white"
    }

    if (["completed"].includes(label)) {
        return "bg-green-500 text-white hover:bg-green-500 text-white"
    }

    return "secondary"
}