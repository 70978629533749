
import React from 'react';
import lodash from 'lodash';
import { PanelLeft } from 'lucide-react';
import { useRecoilState } from 'recoil';

// Components
import { Sheet, SheetTrigger, SheetContent } from "./ui/sheet";
import { Button } from "./ui/button";
import { ProductLogList } from '../views/dashboard/products/components/ProductLogList';
import { userState } from '../store/store';


export const ResearchLogsListSheet = ({ items }) => {

    const [user] = useRecoilState(userState);

    if (lodash.get(user, ['role']) !== 'admin') {
        return null;
    }

    return (
        <>
            <div className="flex-col md:flex">
                <div className="">
                    <div className="flex items-center">
                        <Sheet>
                            <SheetTrigger asChild>
                                <Button size="icon" variant="outline" >
                                    <PanelLeft className="h-4 w-4" />
                                </Button>
                            </SheetTrigger>
                            <SheetContent side="right" className="p-4 ">
                                <div className='rounded p-2 border h-full'>
                                    <h1 className='text-lg font-bold mb-2'>Research logs so far...</h1>
                                    <p className='text-sm font-semibold'>Steps count: {items.length - 1}</p>
                                    <div className='pt-2 h-full'>
                                        <ProductLogList items={items} />
                                    </div>
                                </div>
                            </SheetContent>
                        </Sheet>
                    </div>
                </div>
            </div>
        </>
    );
}
