import { useEffect, useState } from 'react';

const useParentDimensions = (ref) => {
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

    useEffect(() => {
        const updateDimensions = () => {
            if (ref.current) {
                const parent = ref.current.parentElement;
                if (parent) {
                    setDimensions({
                        width: parent.clientWidth,
                        height: parent.clientHeight,
                    });
                }
            }
        };

        updateDimensions(); // Set initial dimensions
        window.addEventListener('resize', updateDimensions); // Update dimensions on window resize

        return () => {
            window.removeEventListener('resize', updateDimensions); // Cleanup event listener
        };
    }, [ref]);

    return dimensions;
};

export default useParentDimensions;