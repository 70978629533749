import { useEffect, useState } from 'react';
import lodash from 'lodash';
import { ChevronLeft, Search, Menu, Loader, RefreshCw } from "lucide-react";
import { useHistory } from 'react-router-dom';

// Custom components
import { TooltipProvider } from "../../../../components/ui/tooltip";
import { Input } from "../../../../components/ui/input"
import { Button } from "../../../../components/ui/button"
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from "../../../../components/ui/resizable"
import {
  Card,
  CardContent,
  CardHeader,
} from "../../../../components/ui/card"
import { Separator } from "../../../../components/ui/separator";
import { Skeleton } from "../../../../components/ui/skeleton";

import { ReportDisplay } from "./ReportDisplay";
import { EditReportDialog } from "./EditReportDialog";
import { DeleteReportDialog } from "./DeleteReportDialog";
import { ResearchLogsListSheet } from '../../../../components/ResearchLogsListSheet';
import { ResearchReportVersionList } from '../../../../components/ResearchReportVersionList';
import DetailsDialog from '../../../../components/DetailsDialog';
// Services
import { startReportGeneration } from '../../reports_utils';
import { getReport } from '../../../../services/api';
import { logError } from '../../../../services/logging_utils';
import { StatusBadge } from '../../products/components/StatusBadge';


function ReportDetails({ report, title, status, description, loadingReport }) {
  if (loadingReport && report === null) {
    return (
      <div className='flex flex-col w-full items-start'>
        <div className='flex flex-col'>
          <Skeleton className="h-[15px] w-[200px] my-1 rounded-md bg-background" />
          <Skeleton className="h-[12px] w-[250px]  rounded-md bg-background" />
        </div>
      </div>
    )
  }

  return (
    <div className='flex flex-col w-full items-start'>
      <div className='flex justify-start items-center'>
        <h2 className="text-xl font-semibold mr-2 text-ellipsis">{title}</h2>
        <StatusBadge status={status} />
        <DetailsDialog heading="Report Details" title={title} description={description} />
        {loadingReport && report !== null ? <Loader className='w-4 h-4 ml-4' /> : null}
      </div>

      <p className='text-xs text-ellipsis'>{description}</p>
    </div>
  )
}

const ReportHeader = ({ report, sanitizedLogs, handleRedirectToAllReport, handleRefreshReport, startResearching, loadingReport, selectedReportVersion, setSelectedReportVersion }) => {
  const [editDialogStatus, setEditDialogStatus] = useState(null);
  const [deleteDialogStatus, setDeleteDialogStatus] = useState(null);

  const reportDeletedCb = () => {
    handleRedirectToAllReport();
  }

  return (
    <div className=" flex items-start justify-between flex-row w-full">
      <div className='flex'>
        <Button variant='outline' className='mr-4' onClick={handleRedirectToAllReport}><ChevronLeft className="h-4 w-4" /></Button>
        <ReportDetails report={report} status={lodash.get(report, ['status'])} title={lodash.get(report, ['title'])} description={lodash.get(report, ['description'])} loadingReport={loadingReport} />
      </div>
      <div className=" flex space-x-2 sm:justify-end">
        <div className="space-x-2 flex items-center">
          {handleRefreshReport ? <Button size="icon" variant="outline" className='mr-1' onClick={handleRefreshReport} >
            {lodash.get(startResearching, ['id']) === lodash.get(report, ['id']) ? <Loader /> : <RefreshCw className="h-5 w-5" />}
          </Button> : null}
          <DeleteReportDialog report={report} setDialogStatus={setDeleteDialogStatus} isOpen={deleteDialogStatus} reportDeletedCb={reportDeletedCb} />
          <EditReportDialog report={report} setDialogStatus={setEditDialogStatus} isOpen={editDialogStatus} />
          <ResearchLogsListSheet items={sanitizedLogs} />
          <ResearchReportVersionList report={report} selectedReportVersion={selectedReportVersion} setSelectedReportVersion={setSelectedReportVersion} />
        </div>
      </div>
    </div>
  )
}

export default function ReportChatView({
  activeReport,
  handleRedirectToAllReport
}) {

  const defaultLayout = [320, 320, 655, 975, 1295, 0];

  const [reportContent, setreportContent] = useState('');
  const [selectedReportVersion, setSelectedReportVersion] = useState(0);
  const [loadingReport, setLoadingReport] = useState(false);
  const [report, setReport] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [startResearching, setStartResearching] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  const onStartSetupClick = async (task) => {
    setreportContent([]);
    setReport(prevState => ({ ...prevState, content: '' }));

    setStartResearching(true);

    const requestData = {
      task: task,
      report_type: "detailed_report",
      report_source: "web",
      agent: "Auto Agent",
      report_id: report.id
    };

    await startReportGeneration({ setters: { setStartResearching, setSelectedReport: setReport }, data: requestData });
  };

  const handleTextareaEnter = (e) => {
    if (e.key === "Enter") {
      onStartSetupClick(inputValue);
    }
  };

  const _getReport = async () => {
    try {
      setLoadingReport(true);
      const reportId = lodash.get(activeReport, ['id']);
      const response = await getReport(reportId);
      const report = lodash.get(response, ['data', 'data'], {});
      setReport(report);
      setInputValue(lodash.get(report, ['description']));
    } catch (error) {
      logError(error);
    } finally {
      setLoadingReport(false);
    }
  }

  useEffect(() => {
    // Function to fetch the report
    const fetchReport = async () => {
      if (activeReport !== null && !loadingReport) {
        await _getReport();
      }
    };

    // Call fetchReport immediately if activeReport is not null
    fetchReport();

    // Set up polling every 10 seconds
    const intervalId = setInterval(fetchReport, 10000);

    // Clear interval on component unmount
    return () => clearInterval(intervalId);
  }, [activeReport]);

  const filteredLogs = lodash.get(report, ['logs'], []).filter(item => item && item.output && item.output.toLowerCase().includes(searchTerm.toLowerCase()));

  const sanitizedLogs = (filteredLogs || []).filter(log => typeof log.output === 'string');

  const haveLogs = sanitizedLogs.length > 0;

  return (
    <>
      <Card className='h-full'>
        <CardHeader className='text-left flex flex-row justify-between w-full p-4 bg-muted'>
          <ReportHeader report={report} handleRedirectToAllReport={handleRedirectToAllReport} sanitizedLogs={sanitizedLogs} loadingReport={loadingReport} selectedReportVersion={selectedReportVersion} setSelectedReportVersion={setSelectedReportVersion} />
        </CardHeader>
        <Separator />
        <CardContent style={{ height: 'calc(100% - 84px)' }}>
          <TooltipProvider delayDuration={0}>
            <ResizablePanelGroup
              direction="horizontal"
              onLayout={(sizes) => {
                document.cookie = `react-resizable-panels:layout=${JSON.stringify(
                  sizes
                )}`
              }}
              className="h-full max-h-[800px] items-stretch"
            >
              <ResizablePanel defaultSize={defaultLayout[haveLogs ? 1 : 4]}>
                <ReportDisplay
                  report={report}
                  selectedReportVersion={selectedReportVersion}
                  reportContent={reportContent}
                  setInputValue={setInputValue}
                  onStartSetupClick={onStartSetupClick}
                  handleTextareaEnter={handleTextareaEnter}
                  startResearching={startResearching}
                  inputValue={inputValue}
                  loadingReport={loadingReport}
                />
              </ResizablePanel>

            </ResizablePanelGroup>
          </TooltipProvider>
        </CardContent>
      </Card>
    </>
  );
}
