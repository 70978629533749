import React, { useState, useEffect } from 'react';
import { Search, ClipboardCheck, Plus } from 'lucide-react';
import lodash from 'lodash';
// Custom components
import {
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    CardTitle,
} from "../../components/ui/card";
import { Avatar, AvatarImage } from "../../components/ui/avatar";

import {
    Table,
    TableBody,
    TableCaption,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "../../components/ui/table"
import { Separator } from "../../components/ui/separator";
import { Button } from "../../components/ui/button";
import { Input } from "../../components/ui/input";
import { Link } from 'react-router-dom'; // Add this import
// Services
import { logError } from '../../services/logging_utils';
import { getUsers } from '../../services/api';

function CommonCardHeader() {
    return (
        <CardHeader className='text-left bg-muted flex flex-row items-center'>
            <ClipboardCheck className="h-8 w-8 transition-all group-hover:scale-110 mr-4" />
            <div className='flex flex-col'>
                <CardTitle>Users</CardTitle>
                <CardDescription>Manage your users.</CardDescription>
            </div>
        </CardHeader>
    )
}


function AdminUsersView() {

    const [users, setUsers] = useState([]);
    const [loadingUsers, setLoadingUsers] = useState(false);

    useEffect(() => {
        (async () => {
            setLoadingUsers(true);
            try {
                const response = await getUsers();
                const users = response.data.data;
                setUsers(users);
            } catch (error) {
                logError(error);
            }
            setLoadingUsers(false);
        })()
    }, []);

    return (
        <>
            <Card className='h-full'>
                <CommonCardHeader />
                <Separator />
                <CardContent className='p-4'>
                    <div className='flex flex-col'>
                        {loadingUsers ? <div className='p-2 rounded my-1 bg-accent text-left'><p className='text-md font-semibold'>Loading Users...</p></div> : null}
                        <form className='w-full mr-2'>
                            <div className='relative'>
                                <Search className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
                                <Input
                                    type="search"
                                    placeholder="Search Reports..."
                                    className="md:w-[100px] lg:w-[300px] mb-2 pl-8"
                                // onChange={(e) => setSearchTerm(e.target.value)}
                                />
                            </div>
                        </form>
                        <section className=" grid gap-8 grid-cols-1  lg:gap-2 cursor-pointer" style={{ maxHeight: 'calc(100vh - 284px)', overflowY: 'auto' }}>
                            <div className="bg-white rounded-xl border border-gray-200 dark:bg-gray-950 dark:border-gray-800 flex items-center justify-center">
                                <Table>
                                    {/* <TableCaption>A list of your recent invoices.</TableCaption> */}
                                    <TableHeader>
                                        <TableRow>
                                            <TableHead>Name (Id)</TableHead>
                                            <TableHead>Role</TableHead>
                                            <TableHead className="text-right">Email</TableHead>
                                        </TableRow>
                                    </TableHeader>
                                    <TableBody>
                                        {users.map(user => {
                                            return (
                                                <TableRow key={user.id}>
                                                    <TableCell className='text-left'>
                                                        <div className='flex items-center'>
                                                            <Avatar className="h-8 w-8">
                                                                <AvatarImage src={lodash.get(user, ['profile_image']) ? lodash.get(user, ['profile_image']) : "https://github.com/shadcn.png"} alt="@shadcn" />
                                                            </Avatar>
                                                            <div className='flex flex-col ml-2'>
                                                                <p className=''>{user.fullname}</p>
                                                                <p className=''>{user.id}</p>
                                                            </div>
                                                        </div>
                                                    </TableCell>
                                                    <TableCell className='text-left capitalize'>{user.role}</TableCell>
                                                    <TableCell className="text-right">{user.email}</TableCell>
                                                    <TableCell className="text-right">
                                                        <Link to={`/admin/users/${user.id}`} className="text-blue-500">View Details</Link> {/* Add this link */}
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </div>
                        </section>
                    </div>
                </CardContent >
            </Card >
        </>
    )
}

export default AdminUsersView