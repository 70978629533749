import { useEffect, useState } from 'react';
import lodash from 'lodash';
import Lottie from 'react-lottie';
import { ChevronLeft } from "lucide-react";
import { useHistory } from 'react-router-dom';
import { Grid, Type } from 'lucide-react'; // Import the icons

// Custom components
import { Button } from "../../../../components/ui/button";
import {
    Card,
    CardHeader,
    CardContent,
} from "../../../../components/ui/card";
import { Separator } from "../../../../components/ui/separator";
import TypeFormStyleQuestionsForm from './TypeFormStyleQuestionsForm';
import GridStyleQuestionsForm from './GridStyleQuestionsForm'; // Import the new grid style component
import researchingReportLottie from '../../../../lotties/researching_report.json';
import { Tabs, TabsList, TabsTrigger, TabsContent } from "../../../../components/ui/tabs"; // Import Tabs components

const ProductHeader = ({ product, handleRedirectToAllProduct }) => {
    return (
        <div className="flex items-start justify-between flex-row w-full">
            <div className='flex items-center'>
                <Button variant='outline' className='mr-4' onClick={handleRedirectToAllProduct}><ChevronLeft className="h-4 w-4" /></Button>
                <div className='flex flex-col w-full items-start'>
                    <h2 className="text-xl font-semibold">{lodash.get(product, ['name'])}</h2>
                    <p className='text-xs'>{lodash.get(product, ['description'])}</p>
                </div>
            </div>
        </div>
    )
}

function CreatingReportLottie() {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: researchingReportLottie,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    };

    return (
        <div className="flex flex-col justify-center items-center h-full py-8">
            <Lottie
                options={defaultOptions}
                height={320}
                width={400}
            />
            <p className="text-center my-2 font-bold text-blue-900">Please wait while we create the reports, it may take few minutes...</p>
        </div>
    );
}

export default function ProductQuestionsPreview({
    activeProduct,
    creatingProduct,
    setProductsView,
    setActiveProduct,
    handleGenerateReportsFromQuestions
}) {
    const history = useHistory();
    const [productQuestions, setProductQuestions] = useState([]);

    useEffect(() => {
        if (activeProduct && lodash.get(activeProduct, ['questions'])) {
            setProductQuestions(lodash.get(activeProduct, ['questions'], []).map(question => ({ ...question, answer: question.answer || '' })));
        }
    }, [activeProduct]);

    const handleRedirectToAllProduct = () => { setProductsView('REPORTS_LIST'); history.push(`/dashboard/products`); setActiveProduct(null); }

    const handleInputChange = (index, value) => {
        const updatedQuestions = [...productQuestions];
        const updatedQuestion = { ...updatedQuestions[index], answer: value };
        updatedQuestions[index] = updatedQuestion;
        setProductQuestions(updatedQuestions);
    }

    const handleGenerateReports = () => {
        handleGenerateReportsFromQuestions(productQuestions, activeProduct);
    }

    return (
        <>
            <Card className='h-full'>
                <CardHeader className='text-left flex flex-row justify-between w-full p-4'>
                    <ProductHeader product={activeProduct} handleRedirectToAllProduct={handleRedirectToAllProduct} />
                </CardHeader>
                <Separator />
                <CardContent className="main-area-section-content overflow-y-auto">
                    <section className="flex flex-col overflow-y-auto pb-24">
                        <div className="bg-muted p-4 rounded-lg flex m-2"> {/* Added div with muted background */}
                            <div className='flex flex-col w-full items-start'>
                                <h2 className="text-xl font-semibold">Click on Generate Report once you are done answering all the questions.</h2>
                                <p className='text-xs'>You can leave the input empty if you are not clear about the answer at the moment.</p>
                            </div>
                            <Button
                                onClick={handleGenerateReports}
                                disabled={creatingProduct}
                                className="flex items-center" // Added flex for alignment
                            >
                                {creatingProduct ? 'Generating Reports...' : 'Generate Reports'}
                            </Button>
                        </div>
                        <Tabs defaultValue="typeform" className="w-full flex flex-col items-start p-4">
                            {creatingProduct ? <CreatingReportLottie /> : <>
                                <TabsList>
                                    <TabsTrigger value="typeform">
                                        <Type className="h-5 w-5" /> {/* Typeform icon */}
                                    </TabsTrigger>
                                    <TabsTrigger value="grid">
                                        <Grid className="h-5 w-5" /> {/* Grid icon */}
                                    </TabsTrigger>
                                </TabsList>
                                <TabsContent value="typeform" className="w-full">
                                    <TypeFormStyleQuestionsForm
                                        questions={productQuestions}
                                        creatingProduct={creatingProduct}
                                        productQuestions={productQuestions}
                                        activeProduct={activeProduct}
                                        handleGenerateReportsFromQuestions={handleGenerateReportsFromQuestions}
                                        onChange={(index, value) => handleInputChange(index, value)}
                                    />

                                </TabsContent>
                                <TabsContent value="grid" className="w-full">
                                    <GridStyleQuestionsForm
                                        questions={productQuestions}
                                        onChange={handleInputChange}
                                        creatingProduct={creatingProduct}
                                    />
                                </TabsContent>
                            </>}
                        </Tabs>
                    </section>
                </CardContent>
            </Card>
        </>
    );
}
