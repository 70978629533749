import { useState } from "react";
import lodash from "lodash";
// Custom components
import { Button } from "../../../../components/ui/button";
import { Textarea } from "../../../../components/ui/textarea";

export default function GridStyleQuestionsForm({ questions, onChange, creatingProduct }) {
    return (
        <div className="grid grid-cols-1 md:grid-cols-1 w-full gap-4 ">
            {questions.map((question, index) => (
                <div key={index} className="p-4  bg-muted rounded-lg">
                    <h2 className="text-lg font-bold text-left">
                        {index + 1}. {lodash.get(question, ['question'])}
                    </h2>
                    <Textarea
                        disabled={creatingProduct}
                        value={lodash.get(question, ['answer'])}
                        onChange={(e) => onChange(index, e.target.value)}
                        required
                    />
                </div>
            ))}
        </div>
    );
}