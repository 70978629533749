import React, { useEffect, useState } from 'react';
import { LoaderCircle, PlusCircle } from 'lucide-react';
import { Button } from "../../../components/ui/button";
import { Alert } from "../../../components/ui/alert";
import { Skeleton } from "../../../components/ui/skeleton";
import { getAgents, addAgent, updateAgent, deleteAgent } from '../../../services/api';
import { AddAgentDialog } from './AddAgentDialog';
import AgentsList from './AgentsList';

function AgentsView() {
    const [agents, setAgents] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [showDialog, setShowDialog] = useState(false);

    const fetchAgents = async () => {
        setLoading(true);
        setError('');
        try {
            const response = await getAgents();
            setAgents(response.data.data);
        } catch (err) {
            setError('Failed to load agents.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchAgents();
    }, []);

    const handleUpdateAgents = async () => {
        await fetchAgents();
    };

    const handleDeleteAgent = async (agentId) => {
        try {
            await deleteAgent(agentId);
            await fetchAgents();
        } catch (err) {
            setError('Failed to delete agent.');
        }
    };

    if (loading) {
        return (
            <div className='h-full w-full flex flex-col justify-center items-center'>
                <LoaderCircle className='animate-spin' />
            </div>
        );
    }

    if (error) {
        return (
            <div className='h-full w-full flex flex-col justify-center items-center'>
                <Alert message={error} type="error" />
            </div>
        );
    }

    return (
        <div className='p-4'>
            <div className='flex justify-between items-center mb-4'>
                <h2 className="text-2xl font-bold">Agents Management</h2>
                {/* <AddAgentDialog
                    isOpen={showDialog}
                    onClose={() => setShowDialog(false)}
                    onUpdate={handleUpdateAgents}
                    renderActionTrigger={() => (
                        <Button onClick={() => setShowDialog(true)}>
                            <PlusCircle className="mr-2" /> Add Agent
                        </Button>
                    )}
                /> */}
            </div>
            {agents.length === 0 ? (
                <p className='text-blue-900 text-lg'>No agents available. Click "Add Agent" to create one.</p>
            ) : (
                <AgentsList agents={agents} onDelete={handleDeleteAgent} onUpdate={handleUpdateAgents} />
            )}
        </div>
    );
}

export default AgentsView;