

import { useState } from 'react';
import { useHistory } from 'react-router-dom';
// Custom components
import ReportChatView from "./components/ReportChatView";
import ReportsListGrid from "./components/ReportsListGrid";

export default function ReportsView() {

    const history = useHistory();


    const [reportsView, setReportsView] = useState('REPORTS_LIST');
    const [activeReport, setActiveReport] = useState(null);

    const handleRedirectToAllReport = () => { setReportsView('REPORTS_LIST'); history.push(`/dashboard/reports`); setActiveReport(null); }


    return (
        <div className="flex flex-col px-2 sm:py-2 main-area-section-h main-area-section-w">
            {reportsView === 'REPORTS_LIST' ? <ReportsListGrid setReportsView={setReportsView} setActiveReport={setActiveReport} /> : null}
            {reportsView === 'REPORT_VIEW' ? <ReportChatView activeReport={activeReport} setReportsView={setReportsView} setActiveReport={setActiveReport} handleRedirectToAllReport={handleRedirectToAllReport} /> : null}
        </div>
    );
}