import { useEffect, useState } from 'react';
import lodash from 'lodash';
import { CircleHelp, Edit, X } from 'lucide-react';
import { Textarea } from "./ui/textarea"; // Import Textarea component
import { updateProductAnswers } from '../services/api'; // Import the new API function

// Components
import { Sheet, SheetTrigger, SheetContent } from "./ui/sheet";
import { Button } from "./ui/button";
import { Badge } from "./ui/badge";
import { Separator } from "./ui/separator";
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "./ui/tooltip";

export const AnsweredProductQuestionsSheet = ({ product }) => {
    const items = lodash.get(product, ['questions'], []);

    const [isEditing, setIsEditing] = useState(false); // State to manage editing mode
    const [editedQuestions, setEditedQuestions] = useState(items); // State for edited questions

    const [loading, setLoading] = useState(false); // State for loading
    const [error, setError] = useState(null); // State for error handling

    useEffect(() => {
        setEditedQuestions(items);
    }, [items]);

    if (items.length === 0) {
        return null;
    }

    const handleAnswerChange = (index, value) => {
        setEditedQuestions(prevQuestions =>
            prevQuestions.map((question, i) =>
                i === index ? { ...question, answer: value } : question
            )
        );
    };

    const handleSubmit = async () => {
        setLoading(true); // Set loading state
        setError(null); // Reset error state
        try {
            const payload = {
                ...product,
                questions: editedQuestions
            }
            // Call the API to update answers
            await updateProductAnswers({ productId: product.id, payload });
            setIsEditing(false); // Disable editing mode after submission
        } catch (err) {
            setError("Failed to update answers. Please try again."); // Set error message
        } finally {
            setLoading(false); // Reset loading state
        }
    };

    return (
        <>
            <div className="flex-col md:flex">
                <div className="">
                    <div className="flex items-center">
                        <Sheet>
                            <TooltipProvider>
                                <Tooltip>
                                    <TooltipTrigger asChild>
                                        <SheetTrigger asChild>
                                            <Button size="sm" variant="secondary"
                                                className="flex items-center justify-center text-4xl font-bold m-1" >
                                                <CircleHelp className="h-4 w-4" />
                                            </Button>
                                        </SheetTrigger>
                                    </TooltipTrigger>
                                    <TooltipContent>
                                        <p>Answered Product Questions</p>
                                    </TooltipContent>
                                </Tooltip>
                            </TooltipProvider>
                            <SheetContent side="right" className="p-4 ">
                                <div className='rounded p-2 h-full'>
                                    <div className='flex justify-start items-center mb-2'>
                                        <h1 className='text-lg font-bold  mr-4'>Questions</h1>
                                        <Badge>{items.length}</Badge>
                                        {isEditing ? null : (
                                            <Button
                                                variant="icon"
                                                size="sm"
                                                className="flex items-center justify-center font-bold m-1"
                                                onClick={() => setIsEditing(true)}
                                            >
                                                <Edit className="h-4 w-4" />
                                            </Button>
                                        )}
                                    </div>

                                    <Separator />

                                    {isEditing ? (
                                        <div className='bg-blue-100 my-2 p-2 rounded w-100 transition-all duration-200 ease-in-out' style={{ height: 'auto', opacity: 1 }}>
                                            <div className='flex flex-col w-100'>
                                                <div className='flex w-100 items-start mb-2'>
                                                    <p className='text-sm'>Click <span className='text-blue-900 font-bold'>Update</span> once you are done updating the answers.</p>
                                                    <Button
                                                        variant="icon"
                                                        size="sm"
                                                        className="flex items-start justify-center p-0 font-bold"
                                                        onClick={() => { setIsEditing(false); setEditedQuestions(items) }}
                                                    >
                                                        <X className="h-4 w-4" />
                                                    </Button>
                                                </div>

                                                <Button onClick={handleSubmit} disabled={loading} className='w-100'>
                                                    {loading ? "Updating answers..." : "Update"} {/* Loader text */}
                                                </Button>
                                            </div>
                                        </div>
                                    ) : null}


                                    <div className='pt-2 h-full'>
                                        <div className="flex flex-col gap-2 pt-0 " style={{ maxHeight: 'calc(100vh - 96px)', overflowY: 'auto' }}>
                                            {(editedQuestions || []).map((item, index) => (
                                                <div>
                                                    <Textarea
                                                        label={`${index + 1}: ${lodash.get(item, ['question'])}`} // Set question as label
                                                        value={lodash.get(item, ['answer'])} // Display the answer
                                                        onChange={(e) => handleAnswerChange(index, e.target.value)}
                                                        disabled={loading || !isEditing}
                                                        className={`${isEditing ? 'bg-background' : 'bg=muted'}`}
                                                    />
                                                </div>
                                            ))}

                                            {error && <p className="text-red-500">{error}</p>} {/* Error message */}
                                        </div>
                                    </div>
                                </div>
                            </SheetContent>
                        </Sheet>
                    </div>
                </div>
            </div >
        </>
    );
}
