import * as React from "react"

import { cn } from "../../lib/utils"

const Link = React.forwardRef(({ className, src, alt, type, ...props }, ref) => {
  return (
    <a
      href={src}
      target="_blank"
      rel="noopener noreferrer"
      alt={alt}
      className={cn(
        className
      )}
      ref={ref}
      {...props}
    />
  );
})
Link.displayName = "Link"

export { Link }
