import React, { useState } from 'react';
import { MoveRight } from 'lucide-react';
import { Link } from "../components/ui/link";
import { UserAuthForm } from "../components/auth/user-auth-form";
import { trackAnalyticsEvent } from "../services/logging_utils";


import { OAUTH_PRODUCER_LOGIN_REDIRECT_ENDPOINT } from '../services/api'

function LoginForm() {
  const [isSignup, setIsSignup] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');

  const handleToggle = () => {
    setIsSignup(!isSignup);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Perform login or signup logic here
    if (isSignup) {
      // Perform signup logic
    } else {
      // Perform login logic
    }
  };

  const googleLogin = () => {
    trackAnalyticsEvent('GOOGLE_LOGIN_CLICKED');
    var auth_provider = 'google-oidc';
    var login_url = OAUTH_PRODUCER_LOGIN_REDIRECT_ENDPOINT + '?auth_provider=' + auth_provider;
    window.location.href = login_url;
  };

  return (
    <>
      <div className="mx-auto flex w-full flex-col justify-center space-y-6 sm:w-[350px]">
        <div className="flex flex-col space-y-2 text-center">
          <h1 className="text-2xl font-semibold text-blue-900 tracking-tight flex items-center justify-center">
            Please Sign in to continue <MoveRight className='w-4 h-4 ml-4 font-bold' />
          </h1>
          {/* <p className='text-sm'>So that we can remember you next time you login.</p> */}
        </div>
        <UserAuthForm googleLogin={googleLogin} />
        <p className="px-8 text-center text-sm text-muted-foreground">
          By clicking continue, you agree to our{" "}
          <Link
            href="/terms"
            className="underline underline-offset-4 hover:text-primary"
          >
            Terms of Service
          </Link>{" "}
          and{" "}
          <Link
            href="/privacy"
            className="underline underline-offset-4 hover:text-primary"
          >
            Privacy Policy
          </Link>
          .
        </p>
      </div>
    </>
  );
}

export default LoginForm