import React, { useEffect, useState } from 'react';
import lodash from 'lodash';
import { Badge } from "./ui/badge";
import {
    Tooltip,
    TooltipProvider,
    TooltipTrigger,
} from "./ui/tooltip";
import { getReferences } from '../services/api'; // Import the API function
import Lottie from 'react-lottie';
import EmptyAnalytics from '../lotties/empty_analytics.json'; // Adjust the path as necessary
import ErrorBoundary from './ui/ErrorBoundary'; // Import the ErrorBoundary component
import { logError, logDebug } from '../services/logging_utils'; // Import logging utilities

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: EmptyAnalytics,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
    },
};

export const ResearchReportVersionReferences = ({ report, selectedReportVersion }) => {
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchReferences = async () => {
            try {
                if (report) {
                    const reportInstanceId = lodash.get(report, ['content', selectedReportVersion, 'id'], '');
                    if (reportInstanceId) {
                        setLoading(true);
                        const response = await getReferences(reportInstanceId);
                        setItems(lodash.get(response, ['data', 'references', 'references'], []));
                    }
                }
            } catch (error) {
                logError(error); // Log the error
            } finally {
                setLoading(false); // Ensure loading state is updated
            }
        };

        fetchReferences();
    }, [report, selectedReportVersion]);

    if (!report) {
        return null;
    }

    if (loading) {
        return (
            <div className="flex min-h-screen w-full flex-col items-center justify-center">
                <div>Loading...</div> {/* Loader while fetching */}
            </div>
        );
    }

    if (!items || items.length === 0) {
        return (
            <div className="flex overflow-hidden w-full h-full flex-col bg-blue-100">
                <main className="flex flex-1 flex-col p-4 items-center justify-center">
                    <Lottie
                        options={defaultOptions}
                        height={320}
                        width={320}
                        style={{ margin: 0 }}
                    />
                    <div className='flex flex-col text-center'>
                        <h1 className="text-4xl font-bold text-blue-900">No References Found</h1>
                        <p className="text-lg font-semibold">It seems there are no references available for this report version.</p>
                        <p className="text-sm text-muted-foreground">Please check back later or try a different report version.</p>
                    </div>
                </main>
            </div>
        ); // Updated empty view
    }

    return (
        <ErrorBoundary>
            <div className="flex flex-col gap-2 pt-0" style={{ maxHeight: 'calc(100vh - 96px)', overflowY: 'auto' }}>
                {(items || []).map((item, index) => (
                    <TooltipProvider key={item.id}>
                        <Tooltip>
                            <TooltipTrigger asChild>
                                <button
                                    className={`flex flex-col items-start gap-2 rounded-lg border p-3 text-left text-sm transition-all hover:bg-accent animate-slide-down`}
                                    onClick={() => window.open(item.link, '_blank')}
                                >
                                    <div className="flex items-center gap-2 w-full">
                                        <div className="font-semibold max-w-full">
                                            <p className={`${lodash.get(item, ['title']) ? '' : 'text-ellipsis'} font-bold`}>{index + 1}. {lodash.get(item, ['title']) || lodash.get(item, ['source'], '')}</p>
                                            <Badge>{lodash.get(item, ['source'], '')}</Badge>
                                            <p className={`${lodash.get(item, ['link']) ? '' : 'text-ellipsis'} tex-sm`}>{lodash.get(item, ['link']) || lodash.get(item, ['source'], '')}</p>
                                        </div>
                                    </div>
                                </button>
                            </TooltipTrigger>
                        </Tooltip>
                    </TooltipProvider>
                ))}
            </div>
        </ErrorBoundary>
    );
}
