import { useEffect, useState } from 'react';
import { Bell, X, Link as LinkIcon } from 'lucide-react'; // Import the Link icon
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from './dropdown-menu';
import { Button } from './button';
import { Separator } from './separator';
import { fetchNotifications, markNotificationsSeen } from '../../services/api'; // Adjust the import path as necessary
import { useHistory } from 'react-router-dom'; // Use useHistory for redirection
import get from 'lodash/get'; // Import lodash.get
import groupBy from 'lodash/groupBy'; // Import lodash's groupBy

export const NotificationDropdown = () => {
    const [notifications, setNotifications] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false); // State to control dropdown visibility
    const history = useHistory(); // Initialize useHistory

    const loadNotifications = async () => {
        try {
            setLoading(true);
            const response = await fetchNotifications();
            setNotifications(response.data.notifications);
        } catch (error) {
            console.error('Error fetching notifications:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        loadNotifications();
        const intervalId = setInterval(loadNotifications, 60000); // Poll every 1 minute

        return () => clearInterval(intervalId); // Cleanup on unmount
    }, []);

    const handleMarkSeen = async () => {
        await markNotificationsSeen();
        setNotifications([]);
    };

    const handleClose = async () => {
        await handleMarkSeen(); // Mark notifications as seen before closing
        setIsOpen(false); // Close the dropdown
    };

    const handleNotificationClick = (notification) => {
        handleClose(); // Close the dropdown
        history.push(`/dashboard/products?product_id=${get(notification, 'metadata.product.product_id')}`); // Redirect to the specific route
    };

    // Group notifications by status
    const groupedNotifications = groupBy(notifications, 'status');

    return (
        <DropdownMenu open={isOpen} onOpenChange={setIsOpen}>
            <DropdownMenuTrigger asChild>
                <Button variant="outline" size="icon" className='relative mx-2 text-accent-foreground ' title="Notifications" onClick={() => setIsOpen(true)}>
                    <Bell className="h-4 w-4 " />
                    {notifications.length > 0 && (
                        <span className="absolute top-0 right-0 h-2.5 w-2.5 rounded-full bg-green-500" />
                    )}
                </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end" className='p-4 max-h-[300px] overflow-y-auto min-w-[300px]'> {/* Set max height, enable scrolling, and set min width */}
                <div className="flex justify-between items-center">
                    <h3 className="font-bold text-lg">Notifications</h3>
                    <Button variant="icon" onClick={handleClose} className="p-1">
                        <X className="h-4 w-4" />
                    </Button>
                </div>

                <Separator className='mb-4' />
                {loading ? (
                    <DropdownMenuItem
                        className='flex justify-between w-min-[300px] items-center cursor-pointer hover:bg-secondary'
                    >Loading...</DropdownMenuItem>
                ) : Object.keys(groupedNotifications).length === 0 ? (
                    <DropdownMenuItem
                        className='flex justify-between w-min-[300px] items-center cursor-pointer hover:bg-secondary'
                    >No notifications</DropdownMenuItem>
                ) : (
                    <>
                        <h4 className="font-semibold mt-2 mb-2">New notifications</h4>
                        {groupedNotifications['unseen'] && groupedNotifications['unseen'].length === 0 ? (
                            <DropdownMenuItem
                                className='flex justify-between w-min-[300px] items-center cursor-pointer hover:bg-secondary'
                            >No new notifications</DropdownMenuItem>
                        ) : (
                            groupedNotifications['unseen'] && groupedNotifications['unseen'].map((notification) => (
                                <DropdownMenuItem
                                    key={get(notification, 'id')}
                                    className='flex justify-between w-min-[300px] items-center cursor-pointer bg-muted mb-2 rounded'
                                    onClick={() => handleNotificationClick(notification)} // Handle click
                                >
                                    <span>{get(notification, 'message')}</span>
                                    <Button variant="icon" onClick={() => handleNotificationClick(notification)} className="">
                                        <LinkIcon className="h-4 w-4" />
                                    </Button>
                                </DropdownMenuItem>
                            ))
                        )}
                        <Separator className='my-4' />

                        <h4 className="font-semibold mt-2 mb-2">Previous notifications</h4>
                        {groupedNotifications['seen'] && groupedNotifications['seen'].map((notification) => (
                            <DropdownMenuItem
                                key={get(notification, 'id')}
                                className='flex justify-between w-min-[300px] items-center cursor-pointer bg-muted mb-2 rounded'
                                onClick={() => handleNotificationClick(notification)} // Handle click
                            >
                                <span>{get(notification, 'message')}</span>
                                <Button variant="icon" onClick={() => handleNotificationClick(notification)} className="">
                                    <LinkIcon className="h-4 w-4" />
                                </Button>
                            </DropdownMenuItem>
                        ))}
                    </>
                )}
            </DropdownMenuContent>
        </DropdownMenu>
    );
};